<template>
  <div class="mb-2">
    <div class="flex flex-col items-center mt-8 intro-y sm:flex-row">
      <div class="flex items-center mr-auto text-lg font-medium">
        {{ isEdit ? "แก้ไข Sponsor" : "เพิ่ม Sponsor" }}
      </div>
    </div>
    <div class="flex flex-col items-center mt-8 intro-y sm:flex-row">
      <div class="flex items-center mr-auto text-lg font-medium">
        รายละเอียด Sponsor
      </div>
    </div>
    <div class="flex flex-col w-full mt-2 intro-y">
      <div class="form-group">
        <label class="text-sm required">ชื่อ Sponsor</label>
        <input
          id="topic"
          v-model="topic"
          type="text"
          class="w-full form-control"
        />
      </div>
      <div class="form-group">
        <label class="text-base required">รูปแบนเนอร์</label>
        <div class="flex flex-col items-center space-x-4">
          <DropAnImage
            v-model="sponsorImages"
            :data-image="sponsorImages"
            :folder-name-image="folderNameImage"
            :is-edit="isEdit"
          ></DropAnImage>
        </div>
        <label class="mt-2 text-base font-bold">แสดงแบนเนอร์</label>
        <div class="w-3/5 p-4 bg-white border rounded-md">
          <div class="flex items-center">
            <label class="mt-2 text-sm font-semibold">แสดงบนเมนูหลัก</label>
            <div class="flex justify-center ml-4 text-center">
              <label class="toggle-control">
                <input
                  type="checkbox"
                  :checked="isPublistMenu[0].is_publish"
                  @click="
                    isPublistMenu[0].is_publish = !isPublistMenu[0].is_publish
                  "
                />
                <span class="control"></span>
              </label>
              <div class="flex items-center justify-center mt-1 ml-1 ">
                {{ isPublistMenu[0].is_publish ? "เปิด" : "ปิด" }}
              </div>
            </div>
          </div>
          <div class="mt-1 form-group">
            <label class="text-sm">เลือกเมนูหลัก</label>
            <div
              v-for="(selected, i) in selectedMenu"
              :key="i"
              class="dropdown"
            >
              <div class="flex items-center">
                <button
                  class="flex justify-between w-full bg-white dropdown-toggle btn btn-outline-secondary"
                  aria-expanded="false"
                >
                  <div>
                    {{ selected.title }}
                  </div>
                  <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                </button>
                <XIcon
                  class="w-4 h-4 ml-auto cursor-pointer sm:ml-2"
                  @click="deleteArray(1, i, selected)"
                />
              </div>
              <div class="w-full dropdown-menu">
                <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                  <a
                    v-for="menu in mainMenuOption"
                    :key="menu.value"
                    href="javascript:;"
                    data-dismiss="dropdown"
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                    @click="selectMenuMain(menu, i)"
                  >
                    {{ menu.title ? menu.title : "ไม่มีชื่อ" }}
                  </a>
                </div>
              </div>
            </div>
            <div
              class="flex items-center cursor-pointer text-primary-1"
              @click="addArray(1)"
            >
              <PlusIcon class="w-4 h-4" />
              <span>เพิ่มรายการ</span>
            </div>
          </div>
        </div>
        <div class="w-3/5 p-4 bg-white border rounded-md">
          <div class="flex items-center">
            <label class="mt-2 text-sm font-semibold">แสดงบนคลังความรู้</label>
            <div class="flex justify-center ml-4 text-center">
              <label class="toggle-control">
                <input
                  type="checkbox"
                  :checked="isPublistMenu[1].is_publish"
                  @click="
                    isPublistMenu[1].is_publish = !isPublistMenu[1].is_publish
                  "
                />
                <span class="control"></span>
              </label>
              <div class="flex items-center justify-center mt-1 ml-1 ">
                {{ isPublistMenu[1].is_publish ? "เปิด" : "ปิด" }}
              </div>
            </div>
          </div>
          <div class="mt-1 form-group">
            <label class="text-sm">เลือกคลังความรู้</label>
            <div
              v-for="(selected, i) in selectedPostMenu"
              :key="i"
              class="dropdown"
            >
              <div class="flex items-center">
                <Multiselect
                  v-model="selected.id"
                  v-bind="selected"
                  :options="postMenuOption"
                  :searchable="true"
                  :resolve-on-load="false"
                  label="title"
                  valueProp="id"
                  :classes="multiSelectStyle"
                  @input="onSelectedPost"
                >
                </Multiselect>
                <XIcon
                  class="w-4 h-4 ml-auto cursor-pointer sm:ml-2"
                  @click="deleteArray(2, i, selected)"
                />
              </div>
            </div>
            <div
              class="flex items-center cursor-pointer text-primary-1"
              @click="addArray(2)"
            >
              <PlusIcon class="w-4 h-4" />
              <span>เพิ่มรายการ</span>
            </div>
          </div>
        </div>
        <div class="w-3/5 p-4 bg-white border rounded-md">
          <div class="flex items-center">
            <label class="mt-2 text-sm font-semibold">แสดงบนกิจกรรม</label>
            <div class="flex justify-center ml-4 text-center">
              <label class="toggle-control">
                <input
                  type="checkbox"
                  :checked="isPublistMenu[2].is_publish"
                  @click="
                    isPublistMenu[2].is_publish = !isPublistMenu[2].is_publish
                  "
                />
                <span class="control"></span>
              </label>
              <div class="flex items-center justify-center mt-1 ml-1 ">
                {{ isPublishedGeteringMenu ? "เปิด" : "ปิด" }}
              </div>
            </div>
          </div>
          <div class="mt-1 form-group">
            <label class="text-sm">เลือกกิจกรรม</label>
            <div
              v-for="(selected, i) in selectedGeteringMenuOptionMenu"
              :key="i"
              class="dropdown"
            >
              <div class="flex items-center">
                <Multiselect
                  v-model="selected.id"
                  v-bind="selected"
                  :options="geteringMenuOption"
                  :searchable="true"
                  :resolve-on-load="false"
                  label="title"
                  valueProp="id"
                >
                </Multiselect>
                <XIcon
                  class="w-4 h-4 ml-auto cursor-pointer sm:ml-2"
                  @click="deleteArray(3, i, selected)"
                />
              </div>
              <div class="w-full dropdown-menu">
                <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                  <a
                    v-for="menu in geteringMenuOption"
                    :key="menu.id"
                    href="javascript:;"
                    data-dismiss="dropdown"
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                    @click="selectMenuGetering(menu, i)"
                  >
                    {{ menu.title ? menu.title : "ไม่มีชื่อ" }}
                  </a>
                </div>
              </div>
            </div>
            <div
              class="flex items-center cursor-pointer text-primary-1"
              @click="addArray(3)"
            >
              <PlusIcon class="w-4 h-4" />
              <span>เพิ่มรายการ</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex py-8 space-x-3">
        <button
          :disabled="isDisableSubmit()"
          :class="{ 'opacity-50': isDisableSubmit() }"
          class="btn btn-primary"
          @click="onSave()"
        >
          บันทึก
        </button>
        <router-link :to="{ name: 'banner-main' }">
          <button class="bg-white btn btn-outline-secondary">ยกเลิก</button>
        </router-link>
      </div>
    </div>
    <!-- BEGIN: Notification Content -->
    <div id="success-notification-content" class="flex hidden toastify-content">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">สำเร็จ !!</div>
        <div class="mt-1 text-gray-600">
          {{ successTxt }}
        </div>
      </div>
    </div>
    <div id="error-notification-content" class="flex hidden toastify-content">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          {{ errorTxt }}
        </div>
      </div>
    </div>
    <!-- END: Notification Content -->
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import Toastify from "toastify-js";
import * as articleService from "/src/services/article";
import * as getheringService from "/src/services/gethering";
import * as bannerService from "/src/services/banner";
import DropAnImage from "@/global-components/drop-image/Main.vue";

export default defineComponent({
  components: {
    DropAnImage
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const topic = ref(null);
    const sponsorImages = ref(null);
    const sponsorId = ref(null);
    const isEdit = ref(false);
    const errorTxt = ref("");
    const successTxt = ref("");
    const mainMenuOption = ref([]);
    const isPublistMenu = ref([
      {
        id: 1,
        is_publish: true
      },
      {
        id: 2,
        is_publish: true
      },
      {
        id: 3,
        is_publish: true
      }
    ]);
    const multiSelectStyle = ref({
      containerActive: "ring ring-primary-6 ring-opacity-30",
      optionSelected: "text-white bg-primary-1",
      optionSelectedPointed: "text-white bg-primary-1 opacity-90"
    });
    const isPublishedPageMenu = ref(true);
    const isPublishedPostMenu = ref(true);
    const isPublishedGeteringMenu = ref(true);
    const postMenuOption = ref([]);
    const geteringMenuOption = ref([]);
    const selectedMenu = ref([]);
    const selectedPostMenu = ref([]);
    const folderNameImage = ref("sponsor");
    const selectedGeteringMenuOptionMenu = ref([]);

    onMounted(() => {
      sponsorId.value = parseInt(route.params.id)
        ? parseInt(route.params.id)
        : null;
      isEdit.value = sponsorId.value != null ? true : false;
      getArticles();
      getActivity();
      getPageList();
      if (sponsorId.value) {
        getDetailSponsor();
      }
    });

    // eslint-disable-next-line no-unused-vars
    const onDrop = e => {};
    const onSetArray = value => {
      let setDataToArray = [];
      value.map(val => {
        setDataToArray.push({
          id: val.id
        });
      });
      return setDataToArray;
    };
    const onSave = () => {
      let recievePage = JSON.parse(JSON.stringify(selectedMenu._value));
      let recievePost = JSON.parse(JSON.stringify(selectedPostMenu._value));
      let recieveActivity = JSON.parse(
        JSON.stringify(selectedGeteringMenuOptionMenu._value)
      );
      let form = {
        title: topic.value,
        image_url: sponsorImages.value.image_url
          ? sponsorImages.value.image_url
          : sponsorImages.value,
        publishes: isPublistMenu.value,
        pages: onSetArray(recievePage),
        posts: onSetArray(recievePost),
        activities: onSetArray(recieveActivity)
      };
      if (isEdit.value) {
        onEdit(form);
      } else {
        onCreate(form);
      }
    };
    const onCreate = form => {
      bannerService
        .createSponsor(form)
        .then(() => {
          successTxt.value = "เพิ่มสปอนเซอร์เรียบร้อยแล้ว";
          cash("#header-footer-modal-preview").modal("hide");
          successNotificationToggle();
          router.push("/banner");
        })
        .catch(() => {
          cash("#header-footer-modal-preview").modal("hide");
          errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
          errorNotificationToggle();
        });
    };
    const onEdit = form => {
      bannerService
        .editSponsor(form, sponsorId.value)
        .then(() => {
          successTxt.value = "แก้ไข Sponsor เรียบร้อยแล้ว";
          cash("#header-footer-modal-preview").modal("hide");
          successNotificationToggle();
          router.push("/banner");
        })
        .catch(() => {
          cash("#header-footer-modal-preview").modal("hide");
          errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
          errorNotificationToggle();
        });
    };
    const getDetailSponsor = () => {
      bannerService.detailSponsor(sponsorId.value).then(res => {
        let result = res.data.data;
        topic.value = result.title;
        sponsorImages.value = result.image_url;
        selectedPostMenu.value = result.posts;
        selectedMenu.value = result.pages;
        selectedGeteringMenuOptionMenu.value = result.activities;
        result.publishes = isPublistMenu.value;
      });
    };
    const isDisableSubmit = () => {
      return isNullOrEmpty(topic) || isNullOrEmpty(sponsorImages);
    };

    const isNullOrEmpty = val => {
      return val.value === null || val.value === "" || val.value.length == 0;
    };
    const selectMenuMain = (menu, index) => {
      selectedMenu.value[index] = menu;
    };
    const selectMenuPost = (menu, index) => {
      selectedPostMenu.value[index] = menu;
    };
    const selectMenuGetering = (menu, index) => {
      selectedGeteringMenuOptionMenu.value[index] = menu;
    };
    const getPageList = () => {
      bannerService.getPageList().then(res => {
        mainMenuOption.value = res.data.data;
      });
    };
    const getArticles = () => {
      articleService.getList().then(res => {
        postMenuOption.value = res.data.data.posts;
      });
    };
    const getActivity = () => {
      getheringService.getList().then(res => {
        geteringMenuOption.value = res.data.data.activities;
      });
    };
    const setDropdownDataWhenAddArray = (selectedMenu, mainMenu) => {
      selectedMenu.value.filter((value, i, arraySelected) => {
        value = JSON.parse(JSON.stringify(value));
        arraySelected.findIndex(value2 => {
          value2 = JSON.parse(JSON.stringify(value2));
          if (value2.id === value.id) {
            let menu = JSON.parse(JSON.stringify(mainMenu));
            menu._value.map((element, idx) => {
              if (element.id === value2.id) {
                mainMenu.value.splice(idx, 1);
              }
            });
          }
        });
      });
      selectedMenu.value.push({});
      return { selected: selectedMenu.value, mainMenu: mainMenu.value };
    };
    const addArray = type => {
      let resultSelect = [];
      if (type === 1) {
        resultSelect = selectedMenu.value;
      } else if (type === 2) {
        resultSelect = selectedPostMenu.value;
      } else {
        resultSelect = selectedGeteringMenuOptionMenu.value;
      }
      console.log(`🚨 ~ addArray ~ resultSelect`, resultSelect);
      let checkLastObj = resultSelect[resultSelect.length - 1];
      if (
        (checkLastObj && Object.keys(checkLastObj).length === 0) ||
        resultSelect[0] == {}
      ) {
        cash("#header-footer-modal-preview").modal("hide");
        errorTxt.value = "กรุณาเลือกตัวเลือกก่อนหน้า ก่อนเพิ่มรายการใหม่";
        errorNotificationToggle();
      } else {
        if (type === 1) {
          let result = setDropdownDataWhenAddArray(
            selectedMenu,
            mainMenuOption
          );
          selectedMenu.value = JSON.parse(JSON.stringify(result.selected));
          mainMenuOption.value = JSON.parse(JSON.stringify(result.mainMenu));
        } else if (type === 2) {
          let result = setDropdownDataWhenAddArray(
            selectedPostMenu,
            postMenuOption
          );
          selectedPostMenu.value = JSON.parse(JSON.stringify(result.selected));
          postMenuOption.value = JSON.parse(JSON.stringify(result.mainMenu));
        } else if (type === 3) {
          let result = setDropdownDataWhenAddArray(
            selectedGeteringMenuOptionMenu,
            geteringMenuOption
          );
          selectedGeteringMenuOptionMenu.value = JSON.parse(
            JSON.stringify(result.selected)
          );
          geteringMenuOption.value = JSON.parse(
            JSON.stringify(result.mainMenu)
          );
        }
      }
    };
    const onlyUnique = menu => {
      var dups = [];
      var arr = menu.filter(el => {
        if (dups.indexOf(el.id) == -1 && el.name != "") {
          dups.push(el.id);
          return true;
        }
        return false;
      });
      return arr;
    };
    const setDropdownDataWhenDeleteArray = (
      mainMenu,
      selectMenu,
      isEmpry,
      element
    ) => {
      let menu = JSON.parse(JSON.stringify(mainMenu));
      let selected = JSON.parse(JSON.stringify(selectMenu.value));
      selected.map((val, index) => {
        if (val.id === element.id) {
          selected.splice(index, 1);
        } else {
          if (!isEmpry) {
            menu._rawValue.push(element);
            mainMenu.value = menu._rawValue;
          }
        }
      });
      let setNewMenu = JSON.parse(JSON.stringify(mainMenu._value));
      selectMenu.value = selected;
      mainMenu.value = onlyUnique(setNewMenu);
      return { selected: selectMenu.value, mainMenu: mainMenu.value };
    };
    const deleteArray = (type, i, element) => {
      element = JSON.parse(JSON.stringify(element));
      let emptry = Object.keys(element).length === 0;
      if (type === 1) {
        let result = setDropdownDataWhenDeleteArray(
          mainMenuOption,
          selectedMenu,
          emptry,
          element
        );
        selectedMenu.value = JSON.parse(JSON.stringify(result.selected));
        mainMenuOption.value = JSON.parse(JSON.stringify(result.mainMenu));
      } else if (type === 2) {
        let result = setDropdownDataWhenDeleteArray(
          postMenuOption,
          selectedPostMenu,
          emptry,
          element
        );
        selectedPostMenu.value = JSON.parse(JSON.stringify(result.selected));
        postMenuOption.value = JSON.parse(JSON.stringify(result.mainMenu));
      } else if (type === 3) {
        let result = setDropdownDataWhenDeleteArray(
          geteringMenuOption,
          selectedGeteringMenuOptionMenu,
          emptry,
          element
        );
        selectedGeteringMenuOptionMenu.value = JSON.parse(
          JSON.stringify(result.selected)
        );
        geteringMenuOption.value = JSON.parse(JSON.stringify(result.mainMenu));
      }
    };

    // Notification
    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const onSelectedPost = value => {
      // selected;
      console.log(`🚨 ~ onSelectedPost ~ value`, value);
    };

    return {
      topic,
      sponsorImages,
      onDrop,
      onSave,
      isDisableSubmit,
      isNullOrEmpty,
      getDetailSponsor,
      onEdit,
      onCreate,
      mainMenuOption,
      selectedMenu,
      selectMenuMain,
      addArray,
      errorNotificationToggle,
      successNotificationToggle,
      successTxt,
      errorTxt,
      deleteArray,
      postMenuOption,
      getArticles,
      selectedPostMenu,
      selectMenuPost,
      selectedGeteringMenuOptionMenu,
      geteringMenuOption,
      onSetArray,
      isPublishedPostMenu,
      isPublishedGeteringMenu,
      isPublishedPageMenu,
      selectMenuGetering,
      getPageList,
      onlyUnique,
      setDropdownDataWhenDeleteArray,
      setDropdownDataWhenAddArray,
      isPublistMenu,
      folderNameImage,
      isEdit,
      multiSelectStyle,
      onSelectedPost
    };
  }
});
</script>
<style scoped lang="scss">
.form-group {
  @apply flex flex-col space-y-1 mb-4;
}
label.required::after {
  content: " *";
  color: red;
}
.custom-file-upload {
  input {
    display: none;
  }
  border: 1px solid #ccc;
  cursor: pointer;
}
</style>
