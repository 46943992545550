<template>
  <div class="col-span-12 mt-8">
    <div class="intro-y flex items-center h-10">
      <h2 class="text-lg font-medium truncate mr-5">จำนวนผู้ใช้ Living Will</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-6 md:col-span-3 intro-y">
        <div class="report-box zoom-in">
          <div class="box p-5">
            <div class="flex">
              <UserIcon class="report-box__icon text-theme-10 flex-shrink-0" />
              <div class="text-base text-gray-600 mt-1 ml-5">
                จำนวนผู้ใช้งาน Living Will
              </div>
            </div>
            <div class="text-3xl font-bold leading-8 mt-6">
              {{ $h.numberWithCommas(all) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-3 intro-y">
        <div class="report-box zoom-in">
          <div class="box p-5">
            <div class="flex">
              <UserIcon class="report-box__icon text-theme-10" />
              <div class="text-base text-gray-600 mt-1 ml-5">เพศชาย</div>
            </div>
            <div class="text-3xl font-bold leading-8 mt-6">
              {{ $h.numberWithCommas(male) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-3 intro-y">
        <div class="report-box zoom-in">
          <div class="box p-5">
            <div class="flex">
              <UserIcon class="report-box__icon text-theme-10" />
              <div class="text-base text-gray-600 mt-1 ml-5">เพศหญิง</div>
            </div>
            <div class="text-3xl font-bold leading-8 mt-6">
              {{ $h.numberWithCommas(female) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-3 intro-y">
        <div class="report-box zoom-in">
          <div class="box p-5">
            <div class="flex">
              <UserIcon class="report-box__icon text-theme-10" />
              <div class="text-base text-gray-600 mt-1 ml-5">ไม่ระบุ</div>
            </div>
            <div class="text-3xl font-bold leading-8 mt-6">
              {{ $h.numberWithCommas(none) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { helper as $h } from "@/utils/helper";

export default defineComponent({
  name: "LivingWillUserStatistic",
  props: {
    all: {
      type: Number,
      default: 0
    },
    male: {
      type: Number,
      default: 0
    },
    female: {
      type: Number,
      default: 0
    },
    none: {
      type: Number,
      default: 0
    }
  },
  data: function() {
    return {
      $h
    };
  }
});
</script>

<style></style>
