<template>
  <div class="col-span-6 mt-10">
    <div class="intro-y flex items-center h-10">
      <h2 class="text-lg font-medium truncate mr-5">อายุผู้ใช้ Living Will</h2>
    </div>
    <div class="report-box-2 intro-y mt-5">
      <div class="box p-5">
        <div class="tab-content mt-6">
          <div class="relative">
            <ReportDonutChart
              ref="donutChartRef"
              class="mt-3"
              :data="dataSets"
            />
          </div>
          <div class="mt-5">
            <div class="flex items-center">
              <div
                class="w-2 h-2 rounded-full mr-3"
                style="background-color: #ff8b26"
              ></div>
              <span class="truncate">18-29 ปี</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto"
                >{{ dataSets.datasets[0].data[0] }}%</span
              >
            </div>
            <div class="flex items-center mt-4">
              <div
                class="w-2 h-2 bg-theme-1 rounded-full mr-3"
                style="background-color: #285fd3"
              ></div>
              <span class="truncate">30-39 ปี</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto"
                >{{ dataSets.datasets[0].data[1] }}%</span
              >
            </div>
            <div class="flex items-center mt-4">
              <div
                class="w-2 h-2 bg-theme-12 rounded-full mr-3"
                style="background-color: #ffc533"
              ></div>
              <span class="truncate">40-49 ปี</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto"
                >{{ dataSets.datasets[0].data[2] }}%</span
              >
            </div>
            <div class="flex items-center mt-4">
              <div
                class="w-2 h-2 bg-theme-12 rounded-full mr-3"
                style="background-color: #ed9ded"
              ></div>
              <span class="truncate">50-59 ปี</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto"
                >{{ dataSets.datasets[0].data[3] }}%</span
              >
            </div>
            <div class="flex items-center mt-4">
              <div
                class="w-2 h-2 bg-theme-12 rounded-full mr-3"
                style="background-color: #9deda5"
              ></div>
              <span class="truncate">60 ปีขึ้นไป</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto"
                >{{ dataSets.datasets[0].data[4] }}%</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ReportDonutChart from "@/components/report-donut-chart/Main.vue";

export default defineComponent({
  name: "LivingWillAgeChart",
  components: { ReportDonutChart },
  props: {
    dataSets: {
      type: Object,
      required: true
    }
  }
});
</script>

<style></style>
