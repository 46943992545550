import { apiApp } from "/src/config/api";
const endpoint = "public/signed-url/public";
const endpointUpload = "public/storage/upload";

const uploadImage = data => {
  return apiApp.post(endpoint, data);
};

const uploadFiles = data => {
  return apiApp.post(endpointUpload, data);
};

export { uploadImage, uploadFiles };
