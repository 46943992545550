<template>
  <div>
    <FormEmailSetting />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import FormEmailSetting from "../../components/views/gethering/FormEmailSetting.vue";
export default defineComponent({
  components: {
    FormEmailSetting
  },
  setup() {}
});
</script>
