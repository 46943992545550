<template>
  <div class="mx-2">
    <div v-if="isEdit">
      <div class="pt-6 pb-5 mt-0 text-2xl font-normal intro-y">
        รายละเอียดกิจกรรม
      </div>
    </div>
    <div v-else>
      <div class="pt-6 pb-5 mt-0 text-2xl font-normal intro-y">
        เพิ่มกิจกรรม
      </div>
    </div>

    <div v-if="isEdit">
      <div
        class="flex flex-col items-center mb-4 mt-0 space-x-4 intro-y lg:flex-row"
      >
        <div v-for="(item, index) in filterItems" :key="index">
          <button
            class="w-[100px] h-8"
            :class="{
              'btn btn-primary': selectedFillter.id === item.id,
              'opacity-30': isActiveValue(index)
            }"
            :disabled="isActiveValue(index) ? true : false"
            @click="selectFilter(index)"
          >
            <span>{{ item.text }}</span>
          </button>
        </div>
      </div>
    </div>

    <div
      v-show="[1].includes(selectedFillter.id)"
      class="pb-3.5 pt-0 text-lg font-medium intro-y"
    >
      รายละเอียดกิจกรรม
    </div>

    <div v-if="selectedFillter.id == 1">
      <div class="flex flex-col w-full intro-y">
        <div class="form-group">
          <label class="text-sm required">ชื่อกิจกรรม</label>
          <input
            id="topic"
            v-model="eventTopic"
            type="text"
            class="w-full form-control"
          />
        </div>
        <div class="flex">
          <div class="w-1/2 pr-2 form-group">
            <label class="text-sm required">ประเภทกิจกรรม</label>
            <div class="dropdown">
              <button
                class="flex justify-between w-full bg-white dropdown-toggle btn btn-outline-secondary"
                aria-expanded="false"
              >
                {{ selectedEventType.text }}
                <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
              </button>
              <div class="w-full dropdown-menu">
                <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                  <a
                    v-for="event in eventTypeOptions"
                    :key="event.value"
                    href="javascript:;"
                    data-dismiss="dropdown"
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                    @click="selectEventType(event)"
                  >
                    {{ event.text }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/2 form-group">
            <label class="text-sm required">รหัสลงทะเบียน</label>
            <input
              id="register_code"
              v-model="register_code"
              type="text"
              class="w-full form-control"
            />
          </div>
        </div>
        <div class="flex">
          <div class="w-full form-group">
            <label class="text-sm">คำอธิบายแบบสั้น</label>
            <input
              id="description"
              v-model="description"
              type="text"
              class="w-full form-control"
            />
          </div>
        </div>
        <div class="flex flex-row">
          <div class="w-1/2 pr-2 form-group">
            <label class="text-sm required">วันที่เริ่มต้น</label>
            <Datepicker
              v-model="eventStartAt"
              :is-edit="isEdit"
              :set-selected="eventStartAt"
            ></Datepicker>
          </div>
          <div class="w-1/2 form-group">
            <label class="text-sm required">วันที่สิ้นสุด</label>
            <Datepicker
              v-model="eventEndAt"
              :is-edit="isEdit"
              :set-selected="eventEndAt"
            ></Datepicker>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="w-1/2 pr-2 form-group">
            <label class="text-sm required">เวลา</label>
            <TimeRangePicker
              v-model="eventTimeAt"
              :is-edit="isEdit"
              :select-time-from="selectTimeFrom"
              :select-time-to="selectTimeTo"
            ></TimeRangePicker>
          </div>
          <div class="w-1/2 form-group">
            <label class="text-sm required">สถานที่</label>
            <input
              id="eventplace"
              v-model="eventPlace"
              type="text"
              class="w-full form-control"
            />
          </div>
        </div>

        <div class="flex flex-row">
          <div class="w-1/2 pr-2 form-group">
            <label class="text-sm required">ค่าลงทะเบียน</label>
            <input
              id="registercost"
              v-model="registerCost"
              type="text"
              class="w-full form-control"
            />
          </div>
          <div class="w-1/2 form-group">
            <label class="text-sm required">วันที่เผยแพร่</label>
            <Datepicker
              v-model="publishAt"
              :is-edit="isEdit"
              :set-selected="publishAt"
            ></Datepicker>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="w-1/2 pr-2 form-group">
            <label class="text-sm required">วันสุดท้ายของการรับสมัคร</label>
            <Datepicker
              v-model="registerCloseAt"
              :is-edit="isEdit"
              :set-selected="registerCloseAt"
            ></Datepicker>
          </div>
          <div class="w-1/2 form-group">
            <label class="text-sm required">
              URL บทความ (ตัวเลขหรือภาษาอังกฤษเท่านั้น)
            </label>
            <input
              id="urlSlug"
              v-model="urlSlug"
              :invalid="!isUrlFriendlySEO(urlSlug)"
              placeholder="กรอก URL เช่น cheevemitr-new"
              type="text"
              class="w-full form-control"
            />
            <p v-if="!isUrlFriendlySEO(urlSlug)" class="field-error-noti">
              <InfoIcon class="w-4 h-4 ml-auto sm:ml-2" />
              <span>
                รูปแบบ URL ไม่ถูกต้อง (กรุณาใช้ ภาษาอังกฤษพิมพ์เล็ก ตัวเลข
                และคั่นคำด้วยเครื่องหมาย - )
              </span>
            </p>
          </div>
        </div>
        <div class="form-group">
          <label class="text-lg">
            <span class="text-lg font-medium">รูปปก</span>
          </label>
          <ImageCropper
            v-model="coverImageFile"
            :folder-name-image="folderNameImage"
            :data-image="coverImageFile"
          />
        </div>
        <div class="form-group">
          <label class="text-lg font-medium required">เนื้อหาของบทความ</label>
          <Editor
            v-if="!loadingContent"
            v-model="contentData"
            :data-content="contentData"
            class="editor"
          ></Editor>
        </div>
        <div class="form-group">
          <label class="text-sm"
            ><span class="text-lg font-medium">สนับสนุนกิจกรรมโดย </span></label
          >
          <DropMultipleImage
            v-model="sponsorImages"
            :multiple="true"
            desc-label="(อัพโหลดรูปสำหรับใช้เป็นรูปภาพสนับสนุนกิจกรรม, ไฟล์รูปแนะนำ JPG , PNG ขนาด 1:1)"
            :folder-name-image="folderNameImage"
            :data-image="sponsorImages"
            :is-edit="isEdit"
          ></DropMultipleImage>
        </div>
        <div class="form-group">
          <label class="text-lg font-medium">อีเมลตอบรับการสมัคร</label>
          <Editor
            v-if="!loadingContent"
            v-model="responseEmailData"
            :data-content="responseEmailData"
            class="editor"
          >
          </Editor>
        </div>
        <div class="text-lg font-medium font-prompt pt-1">
          <span>ไฟล์แนบอีเมลตอบกลับ</span
          ><span class="text-xs font-normal text-theme-36 pl-1"
            >*อัพโหลดไฟล์ได้ไม่เกิน 10MB</span
          >
        </div>
        <!--Upload files-->
        <div class="mt-3.5">
          <label class="text-xs font-prompt text-theme-36">อัพโหลดไฟล์</label>
          <div v-if="isEdit">
            <div v-for="(file, index) in getEditFiles" :key="index">
              <FileUploader
                :item="file"
                :is-edit="isEdit"
                :length-file="files.length"
                :is-last-file="file?.isLastFile"
                @file-edit-uploaded="handleEditFileUploaded(index, $event)"
                @remove-last-edit-uploaded="removeGetFileEdit(index)"
              ></FileUploader>
            </div>
            <div v-for="(newFile, index) in newFiles" :key="index">
              <FileUploader
                :new-file="newFile"
                :is-edit="isEdit"
                :length-file="newFiles.length"
                :length-get-edit-file="getEditFiles.length"
                @file-edit-uploaded="handleEditFileUploaded(index, $event)"
                @remove-edit-uploaded="removeNewFileEdit(index)"
              ></FileUploader>
            </div>
          </div>
          <div v-else>
            <div v-for="(file, index) in files" :key="index">
              <div>
                <FileUploader
                  :item="file"
                  :length-file="files.length"
                  @file-uploaded="handleFileUploaded(index, $event)"
                  @remove-uploaded="removeFile(index)"
                ></FileUploader>
              </div>
            </div>
          </div>
          <button
            v-if="isEdit"
            for="upload"
            class="custom-file-upload inline-block px-1 py-1 cursor-pointer text-theme-1"
            @click="addNewFileEdit"
          >
            + เพิ่มรายการ
          </button>
          <button
            v-else
            for="upload"
            class="custom-file-upload inline-block px-1 py-1 cursor-pointer text-theme-1"
            @click="addNewFile"
          >
            + เพิ่มรายการ
          </button>
        </div>
        <div v-if="isEdit">
          <div class="flex py-8 space-x-3">
            <button
              :disabled="isDisableSubmit()"
              :class="{ 'opacity-50': isDisableSubmit() }"
              class="btn btn-primary"
              @click="onSave"
            >
              บันทึกการเปลี่ยนแปลง
              <LoadingIcon
                v-if="loadPage"
                icon="oval"
                color="white"
                class="w-4 h-4 ml-2"
              />
            </button>
            <router-link :to="{ name: 'gathering-main' }">
              <button class="bg-white btn btn-outline-secondary">ยกเลิก</button>
            </router-link>
          </div>
        </div>
        <div v-else>
          <div class="flex py-8 space-x-3">
            <button
              :disabled="isDisableSubmit()"
              :class="{ 'opacity-50': isDisableSubmit() }"
              class="btn btn-primary"
              @click="onSave"
            >
              บันทึกกิจกรรม
              <LoadingIcon
                v-if="loadPage"
                icon="oval"
                color="white"
                class="w-4 h-4 ml-2"
              />
            </button>
            <router-link :to="{ name: 'gathering-main' }">
              <button class="bg-white btn btn-outline-secondary">ยกเลิก</button>
            </router-link>
          </div>
        </div>
      </div>

      <!--Modal Send Email Success-->
      <div
        id="error-notification-upload-file"
        class="modal font-prompt"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content h-72 rounded-lg mt-40">
            <!-- BEGIN: Modal Body -->
            <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
              <div class="col-span-12 sm:col-span-12 m-0 pt-16">
                <div
                  class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
                >
                  <div>
                    <AlertCircleIcon class="text-theme-31 w-16 h-16" />
                  </div>
                </div>
                <div class="mt-6 leading-5 text-xl font-medium text-theme-32">
                  ไฟล์แนบเกินกว่าไซซ์ที่กำหนด
                </div>
                <div class="mt-6 leading-5 text-sm font-medium text-theme-32">
                  ขนาดไฟล์ต้องไม่เกิน 10MB
                </div>
              </div>
            </div>
            <!-- END: Modal Body -->
          </div>
        </div>
      </div>
      <!--End Modal Send Example Email-->

      <!-- BEGIN: Notification Content -->
      <div
        id="success-notification-content"
        class="flex hidden toastify-content font-prompt"
      >
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
          <div class="font-medium">สำเร็จ !!</div>
          <div class="mt-1 text-gray-600">
            {{ successText }}
          </div>
        </div>
      </div>
      <div
        id="error-notification-content"
        class="flex hidden toastify-content font-prompt"
      >
        <XCircleIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
          <div class="font-medium">ผิดพลาด !!</div>
          <div class="mt-1 text-gray-600">
            {{ errorTxt }}
          </div>
        </div>
      </div>
      <div class="modal" tabindex="-1" aria-hidden="true">
        <div id="error-file-notification-content" class="modal-dialog w-460">
          <div class="modal-content h-72 mt-40 rounded-lg">
            <div class="text-center">
              <div class="m-0 pt-14 font-prompt">
                <div class="flex justify-center items-center">
                  <AlertCircleIcon class="text-theme-31 w-16 h-16" />
                </div>
                <div class="font-medium text-xl text-theme-32 mt-4">
                  ไฟล์แนบเกินกว่าไซซ์ที่กำหนด
                </div>
                <div
                  class="mt-0 text-sm font-normal leading-8 text-theme-33 px-12"
                >
                  {{ errorTxt }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedFillter.id == 2">
      <RegisterTable />
    </div>
    <div v-if="selectedFillter.id == 3">
      <QualifiedRegisterTable @no-certificate="changeTabToUploadCertificate" />
    </div>
    <div v-if="selectedFillter.id == 4">
      <Certificate />
    </div>
    <div v-if="selectedFillter.id == 5">
      <FailEmailSending :child-amount="handleAmount" />
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  nextTick,
  watch,
  computed
} from "vue";
import Datepicker from "@/global-components/date-picker/Main.vue";
import TimeRangePicker from "@/global-components/time-range-picker/Main.vue";
import DropMultipleImage from "./drop-multiple-image/Main.vue";
import Editor from "@/global-components/quill-editor/Main.vue";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import * as getheringService from "/src/services/gethering";
import * as uploadService from "/src/services/upload";
import RegisterTable from "@/components/views/gethering/TableWebsiteRegistered.vue";
import QualifiedRegisterTable from "@/components/views/gethering/TableQualifiedRegistered.vue";
import Toastify from "toastify-js";
import isUrlFriendlySEO from "@/composables/useCheckUrlSEO.js";
import Certificate from "./Certificate.vue";
import { helper as $h } from "@/utils/helper";
import FileUploader from "./FileUploader/FileUploader.vue";
import FailEmailSending from "./FailEmailSending.vue";
import * as emailService from "/src/services/email.js";
import ImageCropper from "@/global-components/image-cropper/Main.vue";

dayjs.extend(timezone);
dayjs.extend(utc);

export default defineComponent({
  components: {
    Datepicker,
    TimeRangePicker,
    Editor,
    RegisterTable,
    QualifiedRegisterTable,
    Certificate,
    FileUploader,
    DropMultipleImage,
    FailEmailSending,
    ImageCropper
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const eventTopic = ref(null);
    const description = ref(null);
    const eventTimeAt = ref(null);
    const eventStartAt = ref(null);
    const eventEndAt = ref(null);
    const publishAt = ref(null);
    const registerCloseAt = ref(null);
    const coverImageFile = ref(null);
    const contentData = ref("");
    const responseEmailData = ref("");
    const sponsorImages = ref([]);
    const eventPlace = ref(null);
    const register_code = ref("");
    const file = ref(null);
    const registerCost = ref(null);
    const activityId = ref(undefined);
    const isEdit = ref(false);
    const errorTxt = ref("");
    const successText = ref("");
    const selectTimeFrom = ref("");
    const selectTimeTo = ref("");
    const folderNameImage = ref("gethering");
    const loadPage = ref(false);
    const urlSlug = ref("");
    const loadingContent = ref(false);
    const getEditFiles = ref([]);
    const newFiles = ref([]);
    const files = ref([]);
    const isActiveOnProd = ref(true);
    const totalSize = ref(0);
    const emailAmount = ref(0);
    const emailBounceInterval = ref();

    const FOLDER_NAME = "gethering";
    const eventTypeOptions = ref([
      {
        value: 1,
        text: "อบรมความรู้"
      },
      {
        value: 2,
        text: "กิจกรรม"
      }
    ]);

    const emailErrorMessage = computed(
      () => `ข้อผิดพลาดการส่งอีเมล (${emailAmount.value})`
    );

    const handleAmount = amount => {
      emailAmount.value = amount;
    };

    const selectedEventType = ref(eventTypeOptions.value[0]);
    const filterItems = ref([
      { text: "รายละเอียด", id: 1 },
      { text: "รายชื่อผู้สมัครกิจกรรม", id: 2 },
      { text: "ผู้ผ่านการคัดเลือก", id: 3 },
      { text: "ใบประกาศนียบัตร", id: 4 },
      {
        text: "ข้อผิดพลาดการส่งอีเมล (0)",
        id: 5
      }
    ]);
    const selectedFillter = ref(filterItems.value[0]);

    const selectFilter = index => {
      filterItems.value.forEach(item => (item.active = false));
      filterItems.value[index].active = true;
      selectedFillter.value = filterItems.value[index];
      window.localStorage.setItem(
        "selectedTab",
        JSON.stringify(selectedFillter.value)
      );
    };

    // eslint-disable-next-line no-unused-vars
    const isActiveValue = computed(_ => index => {
      return !isActiveOnProd.value && [2, 3].includes(index);
    });

    const selectEventType = event => {
      selectedEventType.value = event;
    };

    const isDisableSubmit = () => {
      return (
        isNullOrEmpty(eventTopic) ||
        isNullOrEmpty(selectedEventType) ||
        isNullOrEmpty(eventStartAt) ||
        isNullOrEmpty(eventEndAt) ||
        isNullOrEmpty(eventTimeAt) ||
        isNullOrEmpty(eventPlace) ||
        isNullOrEmpty(registerCost) ||
        isNullOrEmpty(contentData) ||
        isNullOrEmpty(responseEmailData) ||
        isNullOrEmpty(register_code) ||
        loadPage.value ||
        isNullOrEmpty(urlSlug) ||
        !isUrlFriendlySEO(urlSlug.value)
      );
    };

    const isNullOrEmpty = val => {
      if (!val.value) return true;
      return val.value === null || val.value === "" || val.value.length == 0;
    };
    const parstDate = date => {
      return dayjs(date).format("YYYY-MM-DD");
    };

    const onCreate = async form => {
      try {
        const res = await getheringService.createGathering(form);
        if (res?.data?.data?.errors) {
          cash("#header-footer-modal-preview").modal("hide");
          errorTxt.value = res.data.data.errors.message;
          errorNotificationToggle();
          return;
        }
        successText.value = "เพิ่มกิจกรรมเรียบร้อยแล้ว";
        cash("#header-footer-modal-preview").modal("hide");
        successNotificationToggle();
        router.push("/gathering");
      } catch (error) {
        cash("#header-footer-modal-preview").modal("hide");
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const onEdit = async form => {
      try {
        const res = await getheringService.editGathering(
          form,
          activityId.value
        );
        if (res?.data?.data?.errors) {
          cash("#header-footer-modal-preview").modal("hide");
          errorTxt.value = res.data.data.errors.message;
          errorNotificationToggle();
          return;
        }
        successText.value = "แก้ไขกิจกรรมเรียบร้อยแล้ว";
        cash("#header-footer-modal-preview").modal("hide");
        successNotificationToggle();
        router.push("/gathering");
      } catch (error) {
        cash("#header-footer-modal-preview").modal("hide");
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        loadPage.value = false;
      }
    };

    const getDetailActivity = async () => {
      try {
        loadingContent.value = true;
        const res = await getheringService.getDetailGathering(activityId.value);
        const result = await res.data.data.item;
        selectTimeFrom.value = JSON.parse(
          JSON.stringify(result.start_date_activity)
        );
        selectTimeTo.value = JSON.parse(
          JSON.stringify(result.end_date_activity)
        );

        eventTopic.value = result.title;
        register_code.value = result.register_code;
        selectedEventType.value = eventTypeOptions.value.find(
          val => val.value === result.type_id
        );

        description.value = result.description;
        eventStartAt.value = $h.formatDate(
          result.start_date_activity,
          "YYYY-MM-DD"
        );
        eventEndAt.value = $h.formatDate(
          result.end_date_activity,
          "YYYY-MM-DD"
        );

        eventPlace.value = result.location_name;
        registerCost.value = result.register_fee;
        registerCloseAt.value = $h.formatDate(
          result.end_date_register,
          "YYYY-MM-DD"
        );
        publishAt.value = $h.formatDate(result.publish_date, "YYYY-MM-DD");
        contentData.value = result.content;
        responseEmailData.value = result.email_register;
        getEditFiles.value = result.files.map(item => ({
          ...item,
          isLastFile: true
        }));

        coverImageFile.value = result.image_url;
        sponsorImages.value = result.activity_sponsors;
        urlSlug.value = result.url_slug;

        if (getEditFiles.value.length === 0) {
          addNewFileEdit();
        }
      } catch (error) {
        cash("#header-footer-modal-preview").modal("hide");
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        loadingContent.value = false;
      }
    };

    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const getFreshFileForm = () => {
      return {
        folder: FOLDER_NAME,
        original_name: "",
        file: undefined,
        fileSize: null
      };
    };

    //Add File in Create
    const addNewFile = () => {
      files.value.push(getFreshFileForm());
    };

    //Add File in Edit
    const addNewFileEdit = () => {
      newFiles.value.push(getFreshFileForm());
    };

    //Remove File in Create
    const removeFile = index => {
      totalSize.value = totalSize.value - files.value[index].fileSize;
      files.value.splice(index, 1);
    };

    //Remove Get File in Edit
    const removeGetFileEdit = index => {
      getEditFiles.value.splice(index, 1);
      if (getEditFiles.value.length === 0 && newFiles.value.length === 0) {
        addNewFileEdit();
      }
    };

    //Remove New File in Edit
    const removeNewFileEdit = index => {
      newFiles.value.splice(index, 1);
      if (getEditFiles.value.length === 0 && newFiles.value.length === 0) {
        addNewFileEdit();
      }
    };

    //Browse File in Create
    const handleFileUploaded = async (index, file) => {
      totalSize.value = totalSize.value + file.size;
      if (file) {
        if (totalSize.value > 10000000) {
          errorTxt.value = "ขนาดไฟล์ต้องไม่เกิน 10MB";
          totalSize.value = totalSize.value - file.size;
          errorFileNotificationToggle();
          return;
        }
        files.value[index].original_name = file.name;
        files.value[index].file = file;
        files.value[index].fileSize = file.size;
      }
    };

    //Browse File in Edit
    const handleEditFileUploaded = (index, file) => {
      totalSize.value = totalSize.value + file.size;
      if (file) {
        if (totalSize.value > 10000000) {
          errorTxt.value = "ขนาดไฟล์ต้องไม่เกิน 10MB";
          totalSize.value = totalSize.value - file.size;
          errorFileNotificationToggle();
          return;
        }
        newFiles.value[index].original_name = file.name;
        newFiles.value[index].file = file;
      }
    };

    const getPresignData = async file => {
      try {
        const res = await uploadService.uploadFiles(file);
        return res?.data?.data?.item;
      } catch (err) {
        return undefined;
      }
    };

    //Create Upload Files
    const handleUploadFiles = async () => {
      try {
        const uploadedFileIds = [];
        for (const file of files.value) {
          const presignData = await getPresignData(file);
          if (presignData.id) {
            const result = await $h.uploadImageToStorage(
              presignData.presign_url,
              file.file
            );
            if (result.status === 200) {
              uploadedFileIds.push({
                file_id: presignData.id
              });
            }
          }
        }
        return uploadedFileIds;
      } catch (err) {
        return [];
      }
    };

    //Edit Upload Files
    const handleEditUploadFiles = async () => {
      try {
        const uploadedFileIds = []; // New Files
        let uploadedFileIdsEdit = [];

        //Last FIle (from GET Files)
        const getFiles = getEditFiles.value.map(file => ({
          file_id: file.file_id
        }));

        for (const file of newFiles.value) {
          const presignData = await getPresignData(file);
          if (presignData.id) {
            const result = await $h.uploadImageToStorage(
              presignData.presign_url,
              file.file
            );
            if (result.status === 200) {
              uploadedFileIds.push({
                file_id: presignData.id
              });
            }
          }
        }
        uploadedFileIdsEdit = getFiles.concat(uploadedFileIds);
        return uploadedFileIdsEdit;
      } catch (err) {
        return [];
      }
    };

    const getStartEvent = () => {
      return dayjs(eventStartAt.value)
        .set("hour", eventTimeAt.value.from.hh)
        .set("minute", eventTimeAt.value.from.mm)
        .tz("Asia/Bangkok", true);
    };
    const getEndEvent = () => {
      return dayjs(eventEndAt.value)
        .set("hour", eventTimeAt.value.to.hh)
        .set("minute", eventTimeAt.value.to.mm)
        .tz("Asia/Bangkok", true);
    };

    const onSave = async () => {
      try {
        loadPage.value = true;

        const sponsorImagesFiles = sponsorImages.value.map(file => ({
          image_url: file.image_url
        }));

        const uploadedFileIds = isEdit.value
          ? await handleEditUploadFiles()
          : await handleUploadFiles();

        const startEvent = getStartEvent();
        const endEvent = getEndEvent();

        let form = {
          end_date_register: dayjs(registerCloseAt.value).endOf("day"),
          publish_date: dayjs(publishAt.value),
          start_date_activity: startEvent,
          end_date_activity: endEvent,
          title: eventTopic.value,
          description: description.value,
          location_name: eventPlace.value,
          register_fee: registerCost.value,
          image_url: coverImageFile?.value?.image_url || coverImageFile.value,
          content: contentData.value,
          email_register: responseEmailData.value,
          files: uploadedFileIds,
          is_published: true,
          type_id: parseInt(selectedEventType.value.value),

          activity_sponsors: sponsorImagesFiles,
          register_code: register_code.value,
          url_slug: urlSlug.value
        };

        if (isEdit.value) {
          onEdit(form);
        } else {
          onCreate(form);
        }
      } catch (err) {
        cash("#header-footer-modal-preview").modal("hide");
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        loadPage.value = false;
      }
    };

    const getIsActiveOnProduction = async () => {
      try {
        const res = await getheringService.getIsActive(route.params.id);
        isActiveOnProd.value = res?.data?.data?.item?.is_active;
      } catch (error) {
        () => {};
      }
    };

    const changeTabToUploadCertificate = () => {
      selectedFillter.value = filterItems.value[3];
    };

    const errorFileNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-file-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 1200,
          newWindow: true,
          gravity: "center",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    const fetchInitialEmailBounceAmount = async () => {
      const res = await emailService.getEmailBounceAmount(route.params.id);
      emailAmount.value = res.data.data.item.bounce_amount;
    };

    watch(files.value, val => {
      if (val.length === 0) {
        files.value.push(getFreshFileForm());
      }
    });

    watch(emailAmount, () => {
      filterItems.value[4].text = `ข้อผิดพลาดการส่งอีเมล (${emailAmount?.value ||
        0})`;
    });

    onMounted(() => {
      activityId.value = parseInt(route.params.id)
        ? parseInt(route.params.id)
        : null;
      isEdit.value = activityId.value !== null;
      if (activityId.value) {
        getDetailActivity();
      }
      if (route.query.activeTab) {
        selectFilter(+route.query.activeTab);
      }
      if (!isEdit.value) {
        addNewFile();
      }
      selectedFillter.value = localStorage.getItem("selectedTab")
        ? JSON.parse(localStorage.getItem("selectedTab"))
        : filterItems.value[0];
      getIsActiveOnProduction();
    });

    onMounted(() => {
      fetchInitialEmailBounceAmount();
      emailBounceInterval.value = setInterval(() => {
        fetchInitialEmailBounceAmount();
      }, 20000);
    });

    onUnmounted(() => {
      clearInterval(emailBounceInterval.value);
    });

    onUnmounted(() => {
      window.localStorage.setItem(
        "selectedTab",
        JSON.stringify(filterItems.value[0])
      );
      window.localStorage.setItem("isDetailActivity", JSON.stringify(true));
    });

    return {
      eventTopic,
      selectedEventType,
      eventTypeOptions,
      description,
      eventTimeAt,
      eventStartAt,
      eventEndAt,
      eventPlace,
      registerCost,
      publishAt,
      registerCloseAt,
      coverImageFile,
      contentData,
      responseEmailData,
      sponsorImages,
      selectEventType,
      isDisableSubmit,
      isNullOrEmpty,
      onSave,
      onCreate,
      getDetailActivity,
      onEdit,
      parstDate,
      selectTimeFrom,
      selectTimeTo,
      register_code,
      selectedFillter,
      filterItems,
      selectFilter,
      folderNameImage,
      isEdit,
      loadPage,
      successText,
      errorTxt,
      isUrlFriendlySEO,
      urlSlug,
      loadingContent,
      file,
      files,
      getEditFiles,
      handleEditFileUploaded,
      addNewFileEdit,
      handleUploadFiles,
      handleEditUploadFiles,
      newFiles,
      removeNewFileEdit,
      removeGetFileEdit,
      addNewFile,
      removeFile,
      handleFileUploaded,
      getIsActiveOnProduction,
      isActiveOnProd,
      isActiveValue,
      changeTabToUploadCertificate,
      errorFileNotificationToggle,
      totalSize,
      emailAmount,
      fetchInitialEmailBounceAmount,
      handleAmount,
      emailErrorMessage,
      emailBounceInterval
    };
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/sass/components/_file-upload-activity.scss";

.form-group {
  @apply flex flex-col space-y-1 mb-4;
}

label.required::after {
  content: " *";
  color: red;
}

.w-460 {
  width: 460px;
}
</style>
