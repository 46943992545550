import { apiApp } from "/src/config/api";
const endpoint = "admin/tag-count-post";
const endpoint2 = "admin/tag";

const getTagList = params => {
  return apiApp.get(endpoint, { params });
};
const createTag = value => {
  return apiApp.post(endpoint2, value);
};
const editTag = (id, value) => {
  return apiApp.put(endpoint2 + `/${id}`, value);
};
const deleteTag = id => {
  return apiApp.delete(endpoint2 + `/${id}`);
};

export { getTagList, createTag, deleteTag, editTag };
