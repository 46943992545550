<template>
  <div>
    <div class="flex flex-col items-center mt-8 intro-y sm:flex-row">
      <h2 class="mr-auto text-lg font-medium">จัดการแบนเนอร์</h2>
    </div>
    <div class="flex flex-col items-center mt-4 space-x-4 intro-y lg:flex-row">
      <div v-for="(item, index) in filterItems" :key="index">
        <button
          class="w-[100px] h-8"
          :class="{ 'btn btn-primary': selectedFillter == item.key }"
          @click="selectFilter(item.key)"
        >
          <span>{{ item.text }}</span>
        </button>
      </div>
    </div>
    <div class="flex flex-col items-center mt-4 intro-y lg:flex-row">
      <div class="flex w-full font-medium">
        <input
          id="tabulator-html-filter-value"
          v-model="searchContent"
          type="text"
          class="w-full form-control"
          placeholder="ค้นหา"
          @keyup.enter="onSearch()"
        />
        <button class="ml-4 shadow-md btn btn-primary" @click="onSearch()">
          ค้นหา
        </button>
      </div>
      <div class="flex justify-end w-full mt-2 space-x-2 lg:mt-0">
        <div v-if="isBanner" class="dropdown">
          <button
            class="dropdown-toggle btn btn-outline-secondary"
            aria-expanded="false"
          >
            {{ selectedStatus.name }}
            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
          </button>
          <div class="w-40 dropdown-menu">
            <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
              <a
                v-for="status in eventStatus"
                :key="status.key"
                href="javascript:;"
                data-dismiss="dropdown"
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                @click="selectFilterByStaus(status)"
              >
                {{ status.name }}
              </a>
            </div>
          </div>
        </div>
        <router-link
          :to="{
            name: isBanner ? 'banner-create' : 'banner-sponsor-create'
          }"
        >
          <button class="shadow-md btn btn-primary">
            <PlusIcon class="w-4 h-4" />
            {{ isBanner ? "เพิ่มแบนเนอร์" : "เพิ่ม Sponsor" }}
          </button>
        </router-link>
      </div>
    </div>
    <div v-if="isBanner" class="mt-6">
      <BannerTable
        :array-data="arrayData"
        @onMoveCallback="onMoveCallback"
        @rewriteDragImg="rewriteDragImg"
        @setDelete="setDelete"
        @updateStatusIsPublished="updateStatusIsPublished"
      />
    </div>
    <div v-else class="mt-6">
      <SponsorTable :array-data="arrayData" @setDelete="setDelete" />
    </div>
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <Trash2Icon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
              <div class="mt-5 text-2xl">
                ลบ{{ `${isBanner ? "แบนเนอร์" : " Sponsor "}` }} ?
              </div>
              <div class="text-base">
                คุณต้องการลบ{{ `${isBanner ? "แบนเนอร์" : " Sponsor "}` }},
                ใช่หรือไม่ ?
              </div>
            </div>
            <div class="flex justify-center w-full py-4">
              <button
                type="button"
                class="w-24 mr-4 btn btn-danger whitespace-nowrap"
                @click="
                  isBanner ? confirmDeleteBanner() : confirmDeleteSponsor()
                "
              >
                <div>ใช่, ลบรายการ</div>
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 btn btn-outline-secondary"
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Notification Content -->
    <div id="success-notification-content" class="flex hidden toastify-content">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">สำเร็จ !!</div>
        <div class="mt-1 text-gray-600">
          {{ successTxt }}
        </div>
      </div>
    </div>
    <div id="error-notification-content" class="flex hidden toastify-content">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          {{ errorTxt }}
        </div>
      </div>
    </div>
    <!-- END: Notification Content -->
  </div>
</template>
<script>
import { defineComponent, ref, nextTick } from "@vue/runtime-core";
import { onMounted } from "vue";
import Toastify from "toastify-js";
import * as bannerService from "/src/services/banner";
import BannerTable from "../../components/views/banner/BannerTable.vue";
import SponsorTable from "../../components/views/banner/SponsorTable.vue";
export default defineComponent({
  components: {
    BannerTable,
    SponsorTable
  },
  setup() {
    const params = {};
    const filterItems = ref([
      { text: "แบนเนอร์", key: 1 },
      { text: "Sponsor", key: 2 }
    ]);
    const arrayData = ref([]);
    const pageSize = ref([10, 20, 30, 40]);
    const pageSizeSelect = ref(10);
    const searchContent = ref("");
    const isBanner = ref(true);
    const selectedFillter = ref(1);
    const pageSelect = ref(1);
    const totalPages = ref(0);
    const deleteEvent = ref({});
    const errorTxt = ref("");
    const successTxt = ref("");
    const selectFilter = value => {
      pageSelect.value = 1;
      isBanner.value = value == 1 ? true : false;
      window.localStorage.setItem("isBanner", JSON.stringify(isBanner.value));
      if (isBanner.value) {
        getBanner();
      } else {
        getSponsor();
      }
      selectedFillter.value = value;
    };
    const selectedStatus = ref({ name: "เลือกดูตามสถานะ", key: "" });
    const selectFilterByStaus = status => {
      pageSelect.value = 1;
      selectedStatus.value = status;
      if (isBanner.value) {
        getBanner();
      } else {
        getSponsor();
      }
    };
    const eventStatus = ref([
      { name: "ทั้งหมด", key: "" },
      { name: "เผยแพร่", key: 1 },
      { name: "ไม่เผยแพร่", key: 0 }
    ]);
    const paginationSelect = page => {
      pageSelect.value = page;
    };
    const onSearch = () => {
      pageSelect.value = 1;
      if (isBanner.value) {
        getBanner();
      } else {
        getSponsor();
      }
    };
    const confirmDeleteBanner = () => {
      bannerService
        .deleteBanner(deleteEvent.value.id)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          successTxt.value = "ลบแบนเนอร์เรียบร้อยแล้ว";
          cash("#delete-confirmation-modal").modal("hide");
          successNotificationToggle();
          getBanner();
        })
        .catch(err => {
          errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
          errorNotificationToggle();
          console.log(err);
        });
    };
    const confirmDeleteSponsor = () => {
      bannerService
        .deleteSponsor(deleteEvent.value.id)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          successTxt.value = "ลบ Sponsor เรียบร้อยแล้ว";
          cash("#delete-confirmation-modal").modal("hide");
          successNotificationToggle();
          getSponsor();
        })
        .catch(err => {
          errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
          errorNotificationToggle();
          console.log(err);
        });
    };

    onMounted(() => {
      isBanner.value = JSON.parse(localStorage.getItem("isBanner") || true);
      if (isBanner.value) {
        selectedFillter.value = 1;
        getBanner();
      } else {
        selectedFillter.value = 2;
        getSponsor();
      }
    });
    const setDelete = banner => {
      deleteEvent.value = { ...banner };
    };
    const getBanner = () => {
      params.search = searchContent.value ? searchContent.value : "";
      params.is_publish = selectedStatus.value ? selectedStatus.value.key : "";
      bannerService
        .getBannerList(params)
        .then(res => {
          arrayData.value = res.data.data;
        })
        .catch(() => {});
    };
    const getSponsor = () => {
      params.search = searchContent.value ? searchContent.value : "";
      params.is_publish = selectedStatus.value ? selectedStatus.value.key : "";
      bannerService
        .getSponsorList(params)
        .then(res => {
          arrayData.value = res.data.data;
        })
        .catch(() => {});
    };
    // notification;
    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };
    const updateStatusIsPublished = (id, index, status) => {
      bannerService
        .updateStatusIsPublished(id)
        .then(() => {
          let setArray = JSON.parse(JSON.stringify(arrayData.value));
          setArray[index].is_publish = !status;
          arrayData.value = setArray;
        })
        .catch(() => {});
    };
    const onMoveCallback = newArray => {
      let orders = [];
      newArray.map((item, idx) => {
        item.no = idx + 1;
        orders.push(item.id);
      });
      arrayData.value = newArray;
      bannerService
        .orderBanner(orders)
        .then(() => {})
        .catch(() => {});
    };
    const rewriteDragImg = () => {
      // event.dataTransfer.setDragImage(event.originalTarget, 200, 0);
    };
    return {
      filterItems,
      selectedFillter,
      selectFilter,
      selectFilterByStaus,
      eventStatus,
      searchContent,
      onSearch,
      selectedStatus,
      totalPages,
      pageSelect,
      pageSize,
      pageSizeSelect,
      paginationSelect,
      arrayData,
      confirmDeleteBanner,
      isBanner,
      deleteEvent,
      setDelete,
      errorTxt,
      successTxt,
      confirmDeleteSponsor,
      updateStatusIsPublished,
      onMoveCallback,
      rewriteDragImg
    };
  }
});
</script>
