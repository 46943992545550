<template>
  <div class="table intro-y">
    <div>
      <div class="flex px-5 py-3 my-2 space-x-3 items-center justify-between">
        <div class="w-5 h-5 mx-8" />
        <div class="w-32 ">รูปแบนเนอร์</div>
        <div class="w-1/5">ประเภทแบนเนอร์</div>
        <div class="w-2/4 truncate max-w-xl">หัวข้อ</div>
        <div class="w-32">แสดงบนเว็บไซต์</div>
        <div>จัดการ</div>
      </div>
    </div>
    <draggable
      v-model="bannerLists"
      item-key="no"
      v-bind="dragOptions"
      @change="onMoveCallback"
      @start="rewriteDragImg($event)"
      @end="drag = false"
    >
      <template #item="{element, index}">
        <div
          class=" text-gray-700 bg-white cursor-move inbox__item sm:block dark:text-gray-500 flex"
        >
          <div
            class="flex px-5 py-3 my-2 space-x-3 items-center justify-between"
          >
            <MenuIcon class="w-5 h-5 mx-8" />
            <img class="w-32 " :src="element.image_url" alt="" />
            <div class="w-1/5">{{ element.category }}</div>
            <div class="w-2/4 truncate max-w-xl">{{ element.title }}</div>
            <div class="text-center flex justify-center">
              <label class="toggle-control">
                <input
                  type="checkbox"
                  :checked="element.is_publish"
                  @click="
                    updateStatusIsPublished(
                      element.id,
                      index,
                      element.is_publish
                    )
                  "
                />
                <span class="control"></span>
              </label>
              <div class="flex items-center justify-center mt-1 ml-1 w-20">
                {{ element.is_publish ? "แสดง" : "ไม่แสดง" }}
              </div>
            </div>
            <div class="table-report__action">
              <div class="flex justify-center space-x-4 items-end">
                <router-link :to="`/banner/edit/${element.id}`">
                  <button class="flex items-end">
                    <EditIcon class="w-4 h-4" />
                  </button>
                </router-link>
                <a
                  class="flex items-center"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-confirmation-modal"
                  @click="setDelete(element, index)"
                >
                  <Trash2Icon class="w-4 h-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  props: {
    arrayData: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      bannerLists: []
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    arrayData(value) {
      this.bannerLists = JSON.parse(JSON.stringify(value));
    }
  },
  methods: {
    onMoveCallback() {
      this.$emit("onMoveCallback", this.bannerLists);
    },
    rewriteDragImg(e) {
      this.$emit("rewriteDragImg", e);
    },
    setDelete(element, index) {
      this.$emit("setDelete", element, index);
    },
    updateStatusIsPublished(id, index, status) {
      this.$emit("updateStatusIsPublished", id, index, status);
    }
  }
};
</script>
