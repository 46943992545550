<template>
  <div>
    <div class="mt-8 intro-y">
      <h2 class="mr-auto text-xl font-semibold">จัดการหน้าเว็ป</h2>
      <p class="mt-8 font-medium text-lg">วิดีโอบนเว็ปไซต์</p>
      <div class="mt-6">
        <label class="text-sm required">URL</label>
        <div class="relative">
          <input
            v-model="v$.video_url.$model"
            type="text"
            class="relative w-full form-control "
            placeholder="ระบุ URL ของวิดีโอ"
            :invalid="v$.video_url.$error"
          />
          <a
            :href="v$.video_url.$model"
            target="_blank"
            class="
							absolute
							cursor-pointer
							right-5
							top-3
							underline-offset-1
							text-theme-1
							hover:underline
						"
            >ดูตัวอย่าง</a
          >
        </div>
        <p v-if="v$.video_url.$error" class="field-error-noti mt-2">
          <InfoIcon class="w-4 h-4 ml-auto sm:ml-2" />
          <span>
            รูปแบบ URL ไม่ถูกต้อง
          </span>
        </p>
      </div>
      <div class="mt-6">
        <label class="text-sm required">ชื่อวิดีโอ</label>
        <div class="relative">
          <input
            v-model="v$.title.$model"
            type="text"
            class="relative w-full form-control"
            placeholder="ชื่อวิดีโอ"
            :invalid="v$.title.$error"
          />
        </div>
      </div>
      <div class="mt-6">
        <label class="text-sm required">คำอธิบาย</label>
        <div class="relative">
          <textarea
            v-model="v$.description.$model"
            class="relative w-full form-control"
            placeholder="คำอธิบายเกี่ยวกับวิดีโอ"
            resize-none
            rows="5"
            :invalid="v$.description.$error"
          />
        </div>
      </div>
      <div class="flex py-8 space-x-3">
        <button
          :class="{ 'opacity-50': v$.$invalid }"
          class="btn btn-primary"
          @click="submit"
        >
          บันทึกการเปลี่ยนแปลง
        </button>
        <button class="bg-white btn btn-outline-secondary" @click="reset">
          ยกเลิก
        </button>
      </div>
    </div>
    <div id="success-notification-content" class="flex hidden toastify-content">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">สำเร็จ !!</div>
        <div class="mt-1 text-gray-600">
          {{ notificationText }}
        </div>
      </div>
    </div>
    <div id="error-notification-content" class="flex hidden toastify-content">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          {{ notificationText }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  nextTick,
  ref,
  onMounted,
  toRefs
} from "vue";
import { useVuelidate } from "@vuelidate/core";
import { url, required } from "@vuelidate/validators";
import * as websiteManageService from "/src/services/website-manage";
import Toastify from "toastify-js";

export default defineComponent({
  setup() {
    const notificationText = ref("");

    const state = reactive({
      video_url: "",
      title: "",
      description: ""
    });
    const rules = {
      video_url: { required, url },
      title: { required },
      description: { required }
    };
    const v$ = useVuelidate(rules, toRefs(state));

    const reset = async () => {
      v$.value.$reset();
      state.video_url = "";
      state.title = "";
      state.description = "";
    };

    onMounted(async () => {
      const res = await websiteManageService.getVideo();
      state.video_url = res.data.data.video_url;
      state.title = res.data.data.title;
      state.description = res.data.data.description;
    });

    const submit = async () => {
      const isValidate = await v$.value.$validate();
      console.log("isValidate: ", isValidate);
      if (isValidate) {
        try {
          await websiteManageService.createVideo(state);
          successNotificationToggle();
        } catch {
          console.log("toastify-content");
        }
      }
    };

    const successNotificationToggle = () => {
      notificationText.value = "บันทึกการเปลี่ยนแปลงสำเร็จ !";
      nextTick(() => {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };
    return {
      v$,
      reset,
      submit,
      notificationText
    };
  }
});
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";

.form-group {
  @apply flex flex-col space-y-1 mb-4;
}
label.required::after {
  content: " *";
  color: red;
}
</style>
