<template>
  <div>
    <div class="col-span-12 overflow-auto lg:overflow-visible">
      <div class="w-full pt-2.5">
        <div
          class="col-span-12 overflow-auto lg:overflow-visible rounded-2xl bg-white p-0"
        >
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              :class="
                `flex w-full rounded-lg bg-white text-left text-sm font-normal focus:outline-none pl-4 pr-4 py-4 ${
                  open ? '' : ''
                }`
              "
            >
              <!-- Deleted account -->
              <div
                v-if="detailEmailBounce.is_delete"
                class="flex space-x-20 flex-grow button-left"
              >
                <div
                  :class="
                    `flex font-medium flex-shrink-0 w-14 ${
                      detailEmailBounce?.verify_code ? '' : 'justify-center'
                    }`
                  "
                >
                  {{ detailEmailBounce?.verify_code || "-" }}
                </div>
                <div class="flex font-medium items-center text-theme-1">
                  <div>
                    <AlertCircleIcon class="block w-4 h-4 stroke-2" />
                  </div>
                  <div
                    class="
                    font-prompt font-medium pl-1.5 flex
                  "
                  >
                    ผู้ใช้งานลบบัญชี
                  </div>
                </div>
                <div
                  class="items-center right-button flex flex-grow justify-end"
                >
                  <XCircleIcon
                    class="transform cursor-pointer inline-block w-4 mr-2.5 mx-1.5"
                    @click="onDeleteElementEmailBounce(detailEmailBounce.id)"
                    @click.prevent
                  />
                  <ChevronUpIcon
                    :class="open ? 'rotate-180 transform' : ''"
                    class="h-5 w-5 inline-block"
                  />
                </div>
              </div>
              <!-- End Deleted account -->
              <!-- Don't Deleted account -->
              <div v-else class="flex space-x-20 flex-grow button-left">
                <div
                  :class="
                    `flex font-medium flex-shrink-0 w-14 ${
                      detailEmailBounce?.verify_code ? '' : 'justify-center'
                    }`
                  "
                >
                  {{ detailEmailBounce?.verify_code || "-" }}
                </div>
                <div class="flex font-normal flex-shrink-0 w-16">
                  {{ detailEmailBounce?.user?.first_name || "" }}
                </div>
                <div class="flex font-normal flex-shrink-0 w-16">
                  {{ detailEmailBounce?.user?.last_name || "" }}
                </div>
                <div class="flex font-normal flex-shrink-0 w-21rem">
                  <div class="inline-block">
                    <div class="flex">
                      <div class="-mt-1">
                        <span
                          v-if="detailEmailBounce.email_reset"
                          class="bg-theme-39 rounded-full px-2 py-1 flex"
                        >
                          <div class="flex items-center mr-1">
                            <Tippy
                              :content="
                                'อีเมลถูกอัปเดตโดยผู้ใช้งาน<br>กดรีเซ็ตอีเมลอัปเดต'
                              "
                              tag="a"
                              href="javascript:;"
                            >
                              <AlertCircleIcon
                                class="text-theme-34 w-4 h-4 flex items-center"
                              />
                            </Tippy>
                          </div>
                          <div class="flex items-center text-theme-34">
                            อีเมลอัปเดต
                          </div>
                        </span>
                      </div>
                      <span
                        :class="
                          `'font-normal' ${
                            detailEmailBounce.is_email_updated
                              ? ''
                              : 'text-theme-34'
                          } ${detailEmailBounce.email_reset ? 'ml-1' : ''}`
                        "
                        >{{ detailEmailBounce?.email || "" }}</span
                      >
                      <span v-if="detailEmailBounce?.email">
                        <button
                          class="flex focus:ring-transparent"
                          type="button"
                          @click="onEdit"
                          @click.prevent
                        >
                          <EditIcon
                            class="cursor-pointer text-theme-33 focus:ring-transparent w-4 mx-1.5 -mt-0.5"
                          />
                        </button>
                      </span>
                    </div>
                    <div
                      v-if="open && detailEmailBounce.email_reset"
                      class="flex pt-1 items-center"
                    >
                      <span class="text-xs flex">อีเมลที่อัปเดต:</span>
                      <span
                        class="text-theme-37 font-14 flex items-center pl-1.5 pr-2"
                        >{{ detailEmailBounce.email_reset }}</span
                      ><span class="text-xs flex items-center underline"
                        ><button
                          type="button"
                          @click.stop
                          @click="
                            onResetEmail(
                              detailEmailBounce.id,
                              detailEmailBounce.email_reset
                            )
                          "
                        >
                          รีเซ็ตอีเมล
                        </button></span
                      >
                    </div>
                  </div>
                </div>
                <div
                  :class="
                    `flex ${
                      open ? '' : ''
                    } font-normal flex-shrink-0 w-20 flex-grow`
                  "
                >
                  {{
                    `${detailEmailBounce?.user?.phone_number ? "0" : ""}` +
                      detailEmailBounce?.user?.phone_number?.substring(3) || ""
                  }}
                </div>
                <div class="items-center right-button">
                  <XCircleIcon
                    class="transform cursor-pointer inline-block w-4 mr-2.5 mx-1.5"
                    @click="onDeleteElementEmailBounce(detailEmailBounce.id)"
                    @click.prevent
                  />
                  <ChevronUpIcon
                    :class="open ? 'rotate-180 transform' : ''"
                    class="h-5 w-5 inline-block"
                  />
                </div>
              </div>
              <!-- End Don't Deleted account -->
            </DisclosureButton>
            <DisclosurePanel class="p-4 text-sm">
              <div class="col-span-12 overflow-auto lg:overflow-visible">
                <table class="table w-full m-0">
                  <thead>
                    <tr>
                      <th class="whitespace-nowrap w-4 border-b">
                        <input
                          v-model="checkAll"
                          :disabled="detailEmailBounce.is_delete"
                          class="accent-orange min-w-18 min-h-18 flex justify-center items-center cursor-pointer"
                          type="checkbox"
                          @change="onClickCheckedAll"
                        />
                      </th>
                      <th
                        class="whitespace-nowrap text-left font-normal border-b"
                      >
                        หัวข้ออีเมล
                      </th>
                      <th
                        class="whitespace-nowrap text-left font-normal border-b"
                      >
                        สาเหตุข้อผิดพลาด
                      </th>
                      <th
                        class="whitespace-nowrap text-left font-normal border-b"
                      >
                        วันที่ส่ง
                      </th>
                      <th
                        class="whitespace-nowrap text-center font-normal border-b"
                      >
                        จัดการ
                      </th>
                      <th
                        class="whitespace-nowrap text-center font-normal border-b"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in detailEmailBounce.bounces"
                      :key="item.id"
                      class="max-h-14"
                    >
                      <td class="w-10">
                        <input
                          v-model="item.checked"
                          type="checkbox"
                          :value="item.id"
                          class="min-w-18 min-h-18 accent-orange cursor-pointer"
                          :disabled="detailEmailBounce.is_delete"
                          @change="onClickChecked(item.id, $event)"
                        />
                      </td>
                      <td class="lg:max-w-md break-all">
                        {{ item?.email_subject || "-" }}
                      </td>
                      <td class="w-64 break-all">
                        {{ item?.cause || "-" }}
                      </td>
                      <td class="w-40 break-all">
                        {{ getDateFormat(item?.created_at) || "-" }}
                      </td>
                      <td class="w-32">
                        <div class="flex justify-center items-center">
                          <div>
                            <button
                              type="button"
                              :class="
                                `focus:ring-transparent ${
                                  detailEmailBounce.is_delete
                                    ? 'opacity-30'
                                    : ''
                                }`
                              "
                              :disabled="detailEmailBounce.is_delete"
                              @click="
                                onSendEmail(item.id, detailEmailBounce.email)
                              "
                            >
                              <SendIcon
                                type="button"
                                class="mx-1.5 w-4 inline-block focus:ring-transparent cursor-pointer"
                              />
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              :class="
                                `focus:ring-transparent ${
                                  detailEmailBounce.is_delete
                                    ? 'opacity-30'
                                    : ''
                                }`
                              "
                              :disabled="detailEmailBounce.is_delete"
                              @click="onViewDetail(item.id)"
                            >
                              <EyeIcon
                                class="mx-1.5 w-4 inline-block focus:ring-transparent cursor-pointer"
                              />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td class="w-10">
                        <div>
                          <button
                            type="button"
                            class="focus:ring-transparent"
                            @click="
                              onDeleteOneEmailBounce(
                                item.id,
                                detailEmailBounce.email
                              )
                            "
                          >
                            <XCircleIcon
                              class="cursor-pointer inline-block w-4 mx-1.5"
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, nextTick, ref, computed } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import dayjs from "dayjs";
import Toastify from "toastify-js";

export default defineComponent({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel
  },
  props: {
    detailEmailBounce: {
      type: Object,
      required: true,
      default: () => ({})
    },
    indexParent: {
      type: Number,
      default: 0
    },
    isDetailActivity: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "onCheckedAll",
    "onChecked",
    "onDeleteOne",
    "onEditEmail",
    "onSendEmailBounce",
    "onViewDetailEmail",
    "onDeleteELement",
    "onResetEmailBounce"
  ],
  setup(props, { emit }) {
    const checkAll = ref(false);
    const hasUpdateEmail = ref(true);

    const selectedEmail = computed(() => {
      return props.detailEmailBounce.bounces.filter(item => item.checked);
    });

    const onEdit = () => {
      emit("onEditEmail", props.detailEmailBounce);
    };

    const onDeleteElementEmailBounce = () => {
      emit("onDeleteELement", props.indexParent);
    };

    const onDeleteOneEmailBounce = async (activityId, email) => {
      emit("onDeleteOne", activityId, email);
    };

    const onClickCheckedAll = event => {
      const checked = event.target.checked;
      if (checked) {
        props.detailEmailBounce.bounces.forEach(
          email => (email.checked = true)
        );
      } else {
        props.detailEmailBounce.bounces.forEach(
          email => (email.checked = false)
        );
      }
      const emailBounceId = props.detailEmailBounce.bounces.map(item => {
        return item.id;
      });
      emit("onCheckedAll", emailBounceId, checked);
    };

    const onClickChecked = (id, event) => {
      const checked = event.target.checked;
      if (checked) {
        checkAll.value =
          selectedEmail.value.length === props.detailEmailBounce.bounces.length;
      } else {
        checkAll.value = false;
      }
      emit("onChecked", id, checked, props.indexParent);
    };

    const onSendEmail = (emailId, email) => {
      emit("onSendEmailBounce", emailId, email);
    };

    const onViewDetail = async emailId => {
      emit("onViewDetailEmail", emailId);
    };
    const getDateFormat = date => {
      return dayjs(date).format("DD/MM/YYYY, H:mm");
    };

    const onResetEmail = async (id, email) => {
      emit("onResetEmailBounce", id, email);
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 1500,
          newWindow: true,
          gravity: "center",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    return {
      getDateFormat,
      onViewDetail,
      errorNotificationToggle,
      onSendEmail,
      onClickChecked,
      onClickCheckedAll,
      selectedEmail,
      onEdit,
      onDeleteElementEmailBounce,
      onDeleteOneEmailBounce,
      checkAll,
      hasUpdateEmail,
      onResetEmail
    };
  }
});
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";

label.required::after {
  content: " *";
  color: red;
}

.accent-orange {
  accent-color: #ec7113;
}
.v-dialog-close {
  display: grid;
  position: absolute;
  z-index: 1;
  border-radius: 0.375rem;
  background-color: white !important;
  block-size: 2rem;
  inset-block-start: 0;
  inset-inline-end: 0;
  transform: translate(0.5rem, -0.5rem);
  width: 34px;
  height: 34px;
  box-shadow: 0px 3px 8px rgba(167, 174, 181, 0.4);
}

.v-btn__content {
  grid-area: content;
  justify-content: center;
  white-space: nowrap;
}
.min-w-18 {
  min-width: 1.125rem;
}
.min-h-18 {
  min-height: 1.125rem;
}

.font-14 {
  font-size: 14px;
}

.w-21rem {
  width: 22rem;
}

.button-left {
  margin-top: 0.2rem;
}

.right-button {
  margin-top: -0.2rem;
}
</style>
