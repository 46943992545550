<template>
  <div
    id="crop-image-modal"
    class="modal"
    tabindex="-1"
    @click="preventCloseModal"
  >
    <div class="modal-dialog w-960">
      <div class="modal-content mt-40">
        <div class="modal-header">
          <h2 class="mr-auto text-base font-medium font-prompt">
            <b>ครอบตัดรูปภาพ</b>
          </h2>
        </div>
        <div>
          <div class="crop-image-dialog">
            <div v-show="showCropper" class="custom-dialog">
              <div class="image-container">
                <img
                  ref="cropperImage"
                  :src="chosenImage"
                  alt="Image not available"
                />
              </div>
            </div>
          </div>
          <div class="actions flex justify-end w-full space-x-4 font-prompt">
            <button class="btn btn-primary" @click="cropChosenImage">
              บันทึกรูปภาพ
            </button>
            <button
              class="bg-white btn btn-outline-secondary"
              @click="resetCropper"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "ImageCropperDialog",
  props: {
    chosenImage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showCropper: false,
      imageFileType: null,
      cropper: null
    };
  },
  methods: {
    preventCloseModal(event) {
      // Stop the click event propagation
      event.stopPropagation();
    },
    async initCropper(imageFileType) {
      this.showCropper = true;
      this.imageFileType = imageFileType;
      await new Promise(resolve => setTimeout(resolve, 50));
      const cropperElement = this.$refs.cropperImage;
      this.cropper = new Cropper(cropperElement, {
        aspectRatio: 16 / 9,
        guides: true,
        background: false,
        viewMode: 3,
        dragMode: "move",
        width: 960,
        height: 1000
      });
    },

    async resetCropper() {
      this.$emit("onReset");
      this.showCropper = false;
      const isDragging = false;
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }
      this.$emit("onHideModal", isDragging);
    },

    async cropChosenImage() {
      const fileName = `${new Date()}.jpeg`;
      const mimeType = "image/jpeg";

      // Add error handling for null or invalid Cropper object
      if (!this.cropper) {
        return;
      }

      const croppedCanvas = this.cropper.getCroppedCanvas();
      if (!croppedCanvas) {
        return;
      }

      croppedCanvas.toBlob(blob => {
        const file = new File([blob], fileName, { type: mimeType });
        this.$emit("uploadFile", file);
      }, "image/jpeg");

      const croppedDataURL = croppedCanvas.toDataURL("image/jpeg");
      this.$emit("onCrop", croppedDataURL);
      this.resetCropper();
      this.$emit("onHideModal");
    }
  }
};
</script>

<style>
.crop-image-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.custom-dialog {
  max-width: 500px;
  background-color: #fff;
  padding: 20px 16px 16px;
}

.image-container {
  max-width: 450px;
}

.image-container img {
  width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 4px 20px 20px 0;
}

.w-960 {
  min-width: 690px;
}
</style>
