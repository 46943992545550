<template>
  <div class="time-picker-container" tabindex="0">
    <div
      class="time-picker-input"
      @click="
        showSector = !showSector;
        touch();
      "
    >
      <span v-if="touchInput"
        >{{ hhFrom }}:{{ mmFrom }} - {{ hhTo }}:{{ mmTo }}</span
      >
      <span v-show="!touchInput && isEdit"
        >{{ hhFrom }}:{{ mmFrom }} - {{ hhTo }}:{{ mmTo }}</span
      >
      <ClockIcon class="ml-auto" />
    </div>
    <XIcon class="absolute ml-auto right-8 top-2 z-10" @click="clearData()" />
    <div
      v-if="showSector"
      class="absolute w-full right-0 mt-1 overflow-visible z-10"
    >
      <div
        class="flex flex-row bg-white rounded-lg py-4 border-2 items-center space-x-2 justify-center"
      >
        <div class="flex flex-row items-center space-x-3">
          <div class="dropdown w-20">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full flex justify-between bg-white"
              aria-expanded="false"
            >
              {{ hhFrom }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-full">
              <div
                class="dropdown-menu__content box dark:bg-dark-1 p-2 h-40 overflow-scroll"
              >
                <a
                  v-for="hh in hhOptions"
                  :key="hh"
                  href="javascript:;"
                  data-dismiss="dropdown"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="selectHHFrom(hh)"
                >
                  {{ hh }}
                </a>
              </div>
            </div>
          </div>
          <span>:</span>
          <div class="dropdown w-20">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full flex justify-between bg-white"
              aria-expanded="false"
            >
              {{ mmFrom }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-full">
              <div
                class="dropdown-menu__content box dark:bg-dark-1 p-2 h-40 overflow-scroll"
              >
                <a
                  v-for="mm in mmOptions"
                  :key="mm"
                  href="javascript:;"
                  data-dismiss="dropdown"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="selectMMFrom(mm)"
                >
                  {{ mm }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <MinusIcon />
        <div class="flex flex-row items-center space-x-3">
          <div class="dropdown w-20">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full flex justify-between bg-white"
              aria-expanded="false"
            >
              {{ hhTo }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-full">
              <div
                class="dropdown-menu__content box dark:bg-dark-1 p-2 h-40 overflow-scroll"
              >
                <a
                  v-for="hh in hhOptions"
                  :key="hh"
                  href="javascript:;"
                  data-dismiss="dropdown"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="selectHHTo(hh)"
                >
                  {{ hh }}
                </a>
              </div>
            </div>
          </div>
          <span>:</span>
          <div class="dropdown w-20">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full flex justify-between bg-white"
              aria-expanded="false"
            >
              {{ mmTo }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-full">
              <div
                class="dropdown-menu__content box dark:bg-dark-1 p-2 h-40 overflow-scroll"
              >
                <a
                  v-for="mm in mmOptions"
                  :key="mm"
                  href="javascript:;"
                  data-dismiss="dropdown"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="selectMMTo(mm)"
                >
                  {{ mm }}
                </a>
              </div>
            </div>
          </div>
          <butto class="btn btn-primary" @click="hideSelector">เลือก</butto>
          <butto class="btn bg-white" @click="hideSelector()">ปิด</butto>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { helper as $h } from "@/utils/helper";

export default {
  props: {
    selectTimeFrom: {
      type: String,
      default: ""
    },
    selectTimeTo: {
      type: String,
      default: ""
    },
    isEdit: { type: Boolean, default: false }
  },
  data() {
    const hourList = [];
    for (let h = 0; h <= 23; h++) {
      hourList.push(String(h).padStart(2, "0"));
    }

    const miniuteList = [];
    for (let m = 0; m <= 59; m++) {
      miniuteList.push(String(m).padStart(2, "0"));
    }

    return {
      hhOptions: hourList,
      mmOptions: miniuteList,
      hhFrom: $h.formatDate(this.selectTimeFrom, "HH") ?? "",
      mmFrom: $h.formatDate(this.selectTimeFrom, "mm") ?? "",
      hhTo: $h.formatDate(this.selectTimeTo, "HH") ?? "",
      mmTo: $h.formatDate(this.selectTimeTo, "mm") ?? "",
      showSector: false,
      touchInput: false
    };
  },
  watch: {
    selectTimeFrom(value) {
      this.touchInput = true;
      this.selectHHFrom(dayjs(value).format("HH"));
      this.selectMMFrom(dayjs(value).format("mm"));
    },
    selectTimeTo(value) {
      this.touchInput = true;
      this.selectHHTo(dayjs(value).format("HH"));
      this.selectMMTo(dayjs(value).format("mm"));
    }
  },
  async mounted() {},
  methods: {
    touch() {
      this.touchInput = true;
      if (!this.hhFrom && !this.mmFrom && !this.hhTo && !this.mmTo) {
        this.hhFrom = "00";
        this.mmFrom = "00";
        this.hhTo = "00";
        this.mmTo = "00";
      }
    },
    selectHHFrom(hh) {
      this.hhFrom = hh;
      this.selectTime();
    },
    selectMMFrom(mm) {
      this.mmFrom = mm;
      this.selectTime();
    },
    selectHHTo(hh) {
      this.hhTo = hh;
      this.selectTime();
    },
    selectMMTo(mm) {
      this.mmTo = mm;
      this.selectTime();
    },
    hideSelector() {
      this.showSector = false;
      this.selectTime();
    },
    clearData() {
      this.hhFrom = "";
      this.mmFrom = "";
      this.hhTo = "";
      this.mmTo = "";
      this.touchInput = false;
      this.showSector = false;
      this.selectTime();
    },
    selectTime() {
      this.$emit("update:modelValue", {
        from: {
          hh: this.hhFrom,
          mm: this.mmFrom
        },
        to: {
          hh: this.hhTo,
          mm: this.mmTo
        }
      });
    }
  }
};
</script>
<style lang="scss">
.time-picker-container {
  position: relative;
  .time-picker-input {
    min-height: 40px;
    min-width: 100%;
    border-radius: 5px;
    border-width: thin;
    border-style: solid;
    border-color: #d4d9de;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
  }
}
</style>
