import { apiApp } from "/src/config/api";
const endpoint = "admin/activities";
const endpoint2 = "admin/activity";
const endpoint3 = "admin/activity/type";

export const getList = params => {
  return apiApp.get(endpoint, { params });
};
export const createGathering = form => {
  return apiApp.post(endpoint2, form);
};
export const editGathering = (form, id) => {
  return apiApp.put(endpoint2 + "/" + id, form);
};
export const deleteGathering = id => {
  return apiApp.delete(endpoint2 + `/${id}`);
};
export const getListByOrder = () => {
  return apiApp.get(endpoint2 + "/pins");
};
export const getDetailGathering = id => {
  return apiApp.get(endpoint2 + `/${id}`);
};
export const updateStatusIsPublished = id => {
  return apiApp.patch(endpoint2 + `/${id}/status`);
};
export const updatePin = id => {
  return apiApp.patch(endpoint2 + `/${id}/pin`);
};
export const getGetheringType = () => {
  return apiApp.get(endpoint3);
};
export const orderGethering = order => {
  return apiApp.patch(endpoint2 + "/order-pin", { id: order });
};

export const updateQualifyRegisterUser = (activityId, payload) => {
  return apiApp.patch(endpoint + `/${activityId}/qualifies`, payload);
};

export const getQualifyRegisterUser = activityId => {
  return apiApp.patch(endpoint + `/${activityId}/qualifies`);
};

export const getActivityRegisteredUser = (activityId, params) => {
  return apiApp.get(`${endpoint}/${activityId}/registers`, { params });
};

export const getIsActive = activityId => {
  return apiApp.get(`${endpoint}/${activityId}/registers`);
};

export const getUserByRegisterId = (registerId, params) => {
  return apiApp.get(`${endpoint}/registers/${registerId}`, { params });
};

export const getHistoryEmails = activityId => {
  return apiApp.get(`${endpoint}/${activityId}/emails/logs`);
};

export const getEmailDetailByActivityId = activityId => {
  return apiApp.get(`${endpoint}/${activityId}/emails`);
};

export const sendEmailIndividual = (registerId, emailPath) => {
  return apiApp.post(`${endpoint}/registers/${registerId}/emails/${emailPath}`);
};

export const getActivitiesForm = params => {
  return apiApp.get(`${endpoint}/forms`, { params });
};

export const getActivitiesFormUserList = (activityId, params) =>
  apiApp.get(`admin/activities/${activityId}/register/form`, { params });

export const downloadActivityFile = activityId =>
  apiApp.get(`admin/activities/${activityId}/register/form/export`, {
    responseType: "blob"
  });

export const uploadActivityFile = (activityId, data) =>
  apiApp.post(`admin/activities/${activityId}/upload/form`, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
export const announcementActivity = activityId => {
  return apiApp.patch(endpoint + `/${activityId}/announcement`);
};
export const changeAnnouncedDisplay = activityId => {
  return apiApp.patch(endpoint + `/${activityId}/announcement/display`);
};
export const getQualifiedList = (activityId, params) => {
  return apiApp.get(endpoint + `/${activityId}/qualifies`, { params });
};
export const getCertificateUser = registerId => {
  return apiApp.get(endpoint + `/registers/${registerId}/certificate`);
};
export const updateQualifiedUser = registerId => {
  return apiApp.patch(endpoint + `/registers/${registerId}/certificate`);
};
export const updateAllQualifiedUser = activityId => {
  return apiApp.patch(endpoint + `/${activityId}/registers/certificate`);
};
export const deleteQualifiedUser = registerId => {
  return apiApp.patch(endpoint + `/registers/${registerId}/qualifies/delete`);
};
export const getExportedFiles = (activityId, statePath, params) => {
  return apiApp.get(endpoint + `/${activityId}/export/${statePath}`, {
    params,
    responseType: "blob"
  });
};

/* -------------------------------------------------------------------------- */
/*                                   lesson                                   */
/* -------------------------------------------------------------------------- */
export const getActivityLessons = (activityId, params) =>
  apiApp.get(`admin/activities/${activityId}/lessons`, { params });

export const createActivityLesson = (activityId, data) =>
  apiApp.post(`admin/activities/${activityId}/lessons`, data);

export const updateActivityLesson = (lessonId, data) =>
  apiApp.put(`admin/activities/lessons/${lessonId}`, data);

export const deleteActivityLesson = lessonId =>
  apiApp.delete(`admin/activities/lessons/${lessonId}`);

/* -------------------------------------------------------------------------- */
/*                                    Test                                    */
/* -------------------------------------------------------------------------- */
export const getActivityTests = (lessonId, params) =>
  apiApp.get(`admin/activities/lessons/${lessonId}/tests`, { params });

export const createActivityTest = (activityId, data) =>
  apiApp.post(`admin/activities/lessons/${activityId}/tests`, data);

export const updateActivityTest = (lessonId, data) =>
  apiApp.put(`admin/activities/tests/${lessonId}`, data);

export const deleteActivityTest = lessonId =>
  apiApp.delete(`admin/activities/tests/${lessonId}`);

export const getActivityTestForms = (testId, params) =>
  apiApp.get(`admin/activities/tests/${testId}/form`, {
    params
  });

export const downloadActivityTestFile = activityId =>
  apiApp.get(`admin/activities/tests/${activityId}/form/export`, {
    responseType: "blob"
  });

export const uploadActivityTestFile = (activityId, data) =>
  apiApp.post(`admin/activities/tests/${activityId}/upload/form`, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
