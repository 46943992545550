<template>
  <div class="w-full">
    <label
      :id="'drag' + multiple"
      class="flex items-center justify-center w-full transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer drop hover:border-gray-400 focus:outline-none"
      :class="getClasses"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
    >
      <img v-if="imageSource && !multiple" :src="imageSource" />
      <div
        v-if="(!imageSource && !isDragging && !wrongFile) || multiple"
        class="flex flex-col space-y-2 text-theme-36"
      >
        <div class="text-2xl text-center">
          Drop file here or click to upload
        </div>
        <div class="text-base text-center">
          {{ descLabel }}
        </div>
      </div>
      <input
        :id="'upload' + multiple"
        type="file"
        name="file_upload"
        class="hidden"
        @change="onBrowse"
      />
    </label>
    <div v-if="!multiple" class="w-full py-4">
      <div class="flex justify-center w-full space-x-4">
        <button class="w-20 btn btn-primary" @click="onEdit">
          แก้ไข
        </button>
        <button class="w-20 btn border-dark-1" @click="removeSingleImage">
          ลบ
        </button>
      </div>
    </div>
    <div v-if="multiple" class="flex flex-col w-full mt-4 space-y-2">
      <div
        v-for="(item, index) in multipleFile"
        :key="item.src"
        class="flex flex-row relative flex-nowrap items-center justify-between w-full p-2 md:p-4 bg-white border-2 border-gray-200 rounded-lg intro-y"
      >
        <div
          class="flex flex-row items-center space-x-4 relative w-full text-ellipsis"
        >
          <div class="w-20 relative md:w-32">
            <img class="w-full" :src="item.src ? item.src : item.image_url" />
          </div>
          <div class="flex-1 overflow-hidden text-overflow truncate">
            <span class="whitespace-nowrap" :title="item.image_url">{{
              item.image_url
            }}</span>
          </div>
          <button class="border-gray-400" @click="removeImage(index)">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1"
                y="1"
                width="30"
                height="30"
                rx="5"
                stroke="#F08485"
              />
              <path
                d="M20.7857 19.7857C21.0714 20.0714 21.0714 20.5 20.7857 20.7857C20.6429 20.9286 20.5 21 20.2857 21C20.0714 21 19.9286 20.9286 19.7857 20.7857L16 17L12.2143 20.7857C12.0714 20.9286 11.9286 21 11.7143 21C11.5 21 11.3571 20.9286 11.2143 20.7857C10.9286 20.5 10.9286 20.0714 11.2143 19.7857L15 16L11.2143 12.2143C10.9286 11.9286 10.9286 11.5 11.2143 11.2143C11.5 10.9286 11.9286 10.9286 12.2143 11.2143L16 15L19.7857 11.2143C20.0714 10.9286 20.5 10.9286 20.7857 11.2143C21.0714 11.5 21.0714 11.9286 20.7857 12.2143L17 16L20.7857 19.7857Z"
                fill="#EA5556"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div
      id="error-notification-upload-image"
      class="flex hidden toastify-content"
    >
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          กรุณาใช้รูปภาพขนาดต่ำกว่า 5MB
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as imageService from "/src/services/upload";
import Toastify from "toastify-js";
import { helper as $h } from "@/utils/helper";

export default {
  name: "DropAnImage",
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    descLabel: {
      type: String,
      default:
        "(อัพโหลดรูปสำหรับใช้เป็นภาพปกของบทความ, ไฟล์รูปแนะนำ JPG , PNG ขนาด 16:9)"
    },
    folderNameImage: {
      type: String,
      default: ""
    },
    dataImage: {
      type: String,
      default: ""
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDragging: false,
      wrongFile: false,
      imageSource: this.dataImage ?? "",
      multipleFile: [],
      fileData: {}
    };
  },
  computed: {
    getClasses() {
      return { isDragging: this.isDragging };
    }
  },
  watch: {
    dataImage(value) {
      this.$nextTick(() => {
        value = JSON.parse(JSON.stringify(value));
        if (this.isEdit) {
          if (value.image_url) {
            this.imageSource = value.image_url;
          } else {
            this.imageSource = value;
          }
        }
        this.multipleFile = value;
      });
    }
  },
  methods: {
    dragOver() {
      this.isDragging = true;
    },
    dragLeave() {
      this.isDragging = false;
    },
    drop(e) {
      let files = e.dataTransfer.files;
      this.wrongFile = false;
      if (files.length === 1) {
        let file = files[0];
        const isFileSizeOver = file.size > 5000000;
        const isImageFile = file.type.indexOf("image/") >= 0;
        if (isFileSizeOver || !isImageFile) {
          this.wrongFile = false;
          this.imageSource = null;
          this.isDragging = false;
          file = null;
          this.multipleFile = [];
          this.errorNotificationToggle();
          return;
        }
        var reader = new FileReader();
        reader.onload = f => {
          this.isDragging = false;
          this.onCallUrl(file, f);
        };
        reader.readAsDataURL(file);
      }
    },
    async onCallUrl(file, item) {
      let setDataImage = {
        folder: this.folderNameImage,
        file_name: file.name
      };
      const res = await imageService.uploadImage(setDataImage);
      let result = res.data.data;
      await $h.uploadImageToStorage(result.urlUpload, file);
      if (this.multiple) {
        this.multipleFile.push({
          file: file,
          src: item.target.result,
          image_url: result.urlPublic,
          name: file.name,
          size: file.size
        });
        this.$emit("update:modelValue", this.multipleFile);
      } else {
        this.imageSource = item.target.result;
        this.fileData = file;
        this.fileData.image_url = result.urlPublic;
        this.$emit("update:modelValue", this.fileData);
      }
    },
    onBrowse() {
      var src = document.querySelector("#upload" + this.multiple);
      this.drop({ dataTransfer: src });
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    removeImage(index) {
      this.multipleFile.splice(index, 1);
    },
    removeSingleImage() {
      this.file = null;
      this.wrongFile = false;
      this.imageSource = null;
      this.fileData = null;
      this.$emit("update:modelValue", this.fileData);
    },
    onEdit() {
      document.querySelector("#upload" + this.multiple).click();
    },
    errorNotificationToggle() {
      Toastify({
        node: cash("#error-notification-upload-image")
          .clone()
          .removeClass("hidden")[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true
      }).showToast();
    }
  }
};
</script>

<style scoped>
.drop {
  width: 100%;
  height: 300px;
  transition: background-color 0.2s ease-in-out;
}
.isDragging {
  background-color: #999;
  border-color: #fff;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
