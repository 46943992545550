<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">จำนวนผู้ใช้</h2>
            <a href="" class="ml-auto flex text-theme-1 dark:text-theme-10">
              <RefreshCcwIcon class="w-4 h-4 mr-3" /> Reload Data
            </a>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-6 md:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <UserIcon
                      class="report-box__icon text-theme-10 flex-shrink-0"
                    />
                    <div class="text-base text-gray-600 mt-1 ml-5">
                      จำนวนผู้ใช้งานทั้งหมด
                    </div>
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ $h.numberWithCommas(numberOfUser) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-6 md:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <UserIcon class="report-box__icon text-theme-10" />
                    <div class="text-base text-gray-600 mt-1 ml-5">เพศชาย</div>
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ $h.numberWithCommas(numberOfMale) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-6 md:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <UserIcon class="report-box__icon text-theme-10" />
                    <div class="text-base text-gray-600 mt-1 ml-5">เพศหญิง</div>
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ $h.numberWithCommas(numberOfFemale) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-6 md:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <UserIcon class="report-box__icon text-theme-10" />
                    <div class="text-base text-gray-600 mt-1 ml-5">ไม่ระบุ</div>
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ $h.numberWithCommas(numberOfNone) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <LivingWillUserStatistic
          :all="numberOfLivingWillUser"
          :male="numberOfLivingWillMale"
          :female="numberOfLivingWillFemale"
          :none="numberOfLivingWillNone"
        />
        <!-- BEGIN: Users By Age -->
        <div class="col-span-6 mt-10">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">อายุ</h2>
          </div>
          <div class="report-box-2 intro-y mt-5">
            <div class="box p-5">
              <div class="tab-content mt-6">
                <div class="relative">
                  <ReportDonutChart
                    ref="donutChartRef"
                    class="mt-3"
                    :data="dataSets"
                  />
                </div>
                <div class="mt-5">
                  <div class="flex items-center">
                    <div
                      class="w-2 h-2 rounded-full mr-3"
                      style="background-color: #ff8b26"
                    ></div>
                    <span class="truncate">18-29 ปี</span>
                    <div
                      class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                    ></div>
                    <span class="font-medium xl:ml-auto"
                      >{{ dataSets.datasets[0].data[0] }}%</span
                    >
                  </div>
                  <div class="flex items-center mt-4">
                    <div
                      class="w-2 h-2 bg-theme-1 rounded-full mr-3"
                      style="background-color: #285fd3"
                    ></div>
                    <span class="truncate">30-39 ปี</span>
                    <div
                      class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                    ></div>
                    <span class="font-medium xl:ml-auto"
                      >{{ dataSets.datasets[0].data[1] }}%</span
                    >
                  </div>
                  <div class="flex items-center mt-4">
                    <div
                      class="w-2 h-2 bg-theme-12 rounded-full mr-3"
                      style="background-color: #ffc533"
                    ></div>
                    <span class="truncate">40-49 ปี</span>
                    <div
                      class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                    ></div>
                    <span class="font-medium xl:ml-auto"
                      >{{ dataSets.datasets[0].data[2] }}%</span
                    >
                  </div>
                  <div class="flex items-center mt-4">
                    <div
                      class="w-2 h-2 bg-theme-12 rounded-full mr-3"
                      style="background-color: #ed9ded"
                    ></div>
                    <span class="truncate">50-59 ปี</span>
                    <div
                      class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                    ></div>
                    <span class="font-medium xl:ml-auto"
                      >{{ dataSets.datasets[0].data[3] }}%</span
                    >
                  </div>
                  <div class="flex items-center mt-4">
                    <div
                      class="w-2 h-2 bg-theme-12 rounded-full mr-3"
                      style="background-color: #9deda5"
                    ></div>
                    <span class="truncate">60 ปีขึ้นไป</span>
                    <div
                      class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                    ></div>
                    <span class="font-medium xl:ml-auto"
                      >{{ dataSets.datasets[0].data[4] }}%</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Users By Age -->
        <LivingWillAgeChart :data-sets="livingWillDataSets" />
        <!-- BEGIN: Weekly Best Sellers -->
        <div class="col-span-6 mt-10">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">จังหวัด</h2>
          </div>
          <div class="mt-5">
            <div
              v-for="(province, index) in provinces"
              :key="index"
              class="intro-y"
            >
              <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                <div class="mr-auto">
                  <div class="font-medium">
                    <span class="mr-1">{{ index + 1 }}.</span>
                    {{ province.provinceName }}
                  </div>
                </div>
                <div
                  class="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium"
                >
                  {{ province.numberOfUser }} คน
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'side-menu-dashboard-overview-1-provinces' }"
              tag="a"
              class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
            >
              ดูเพิ่มเติม
            </router-link>
          </div>
        </div>
        <!-- END: Weekly Best Sellers -->

        <div class="col-span-6 mt-10">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">อาชีพ</h2>
          </div>
          <div class="mt-5">
            <div
              v-for="(occupation, index) in occupations"
              :key="index"
              class="intro-y"
            >
              <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                <div class="mr-auto">
                  <div class="font-medium">
                    <span class="mr-1">{{ index + 1 }}.</span>
                    {{ occupation.occupationName }}
                  </div>
                </div>
                <div
                  class="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium"
                >
                  {{ occupation.numberOfUser }} คน
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'side-menu-dashboard-overview-1-occupations' }"
              tag="a"
              class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
            >
              ดูเพิ่มเติม
            </router-link>
          </div>
        </div>

        <div class="col-span-6 mt-10">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">ความสนใจ</h2>
          </div>
          <div class="mt-5">
            <div
              v-for="(attention, index) in attentions"
              :key="index"
              class="intro-y"
            >
              <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                <div class="mr-auto">
                  <div class="font-medium">
                    <span class="mr-1">{{ index + 1 }}.</span>
                    {{ attention.attentionName }}
                  </div>
                </div>
                <div
                  class="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium"
                >
                  {{ attention.numberOfUser }} คน
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'side-menu-dashboard-overview-1-attentions' }"
              tag="a"
              class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
            >
              ดูเพิ่มเติม
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import ReportDonutChart from "@/components/report-donut-chart/Main.vue";
import * as dashboardService from "/src/services/dashboard";
import { helper as $h } from "@/utils/helper";
import LivingWillUserStatistic from "@/components/dashboard/LivingWillUserStatistic.vue";
import LivingWillAgeChart from "@/components/dashboard/LivingWillAgeChart.vue";

export default defineComponent({
  components: {
    ReportDonutChart,
    LivingWillUserStatistic,
    LivingWillAgeChart
  },
  setup() {
    const donutChartRef = ref(null);

    const numberOfUser = ref(0);
    const numberOfFemale = ref(0);
    const numberOfMale = ref(0);
    const numberOfNone = ref(0);

    const numberOfLivingWillUser = ref(0);
    const numberOfLivingWillFemale = ref(0);
    const numberOfLivingWillMale = ref(0);
    const numberOfLivingWillNone = ref(0);

    const numberOfInterestedVolunteer = ref(0);
    const provinces = ref([]);
    const attentions = ref([]);
    const occupations = ref([]);

    const dataSets = ref({
      labels: ["18-29", "30-39", "40-49", "50-59", "60 ปีขึ้นไป"],
      datasets: [
        {
          data: [20, 20, 20, 20, 20],
          backgroundColor: [
            "#FF8B26",
            "#285FD3",
            "#FFC533",
            "#ed9ded",
            "#9deda5"
          ],
          hoverBackgroundColor: [
            "#FF8B26",
            "#285FD3",
            "#FFC533",
            "#ed9ded",
            "#9deda5"
          ],
          borderWidth: 5
        }
      ]
    });

    const livingWillDataSets = ref({
      labels: ["18-29", "30-39", "40-49", "50-59", "60 ปีขึ้นไป"],
      datasets: [
        {
          data: [20, 20, 20, 20, 20],
          backgroundColor: [
            "#FF8B26",
            "#285FD3",
            "#FFC533",
            "#ed9ded",
            "#9deda5"
          ],
          hoverBackgroundColor: [
            "#FF8B26",
            "#285FD3",
            "#FFC533",
            "#ed9ded",
            "#9deda5"
          ],
          borderWidth: 5
        }
      ]
    });

    const getAgeDataSet = ageInfo => {
      return [
        Math.round(
          ((ageInfo.maleGroup1 + ageInfo.femaleGroup1 + ageInfo.noneGroup1) *
            100) /
            ageInfo.total
        ),
        Math.round(
          ((ageInfo.maleGroup2 + ageInfo.femaleGroup2 + ageInfo.noneGroup2) *
            100) /
            ageInfo.total
        ),
        Math.round(
          ((ageInfo.maleGroup3 + ageInfo.femaleGroup3 + ageInfo.noneGroup3) *
            100) /
            ageInfo.total
        ),
        Math.round(
          ((ageInfo.maleGroup4 + ageInfo.femaleGroup4 + ageInfo.noneGroup4) *
            100) /
            ageInfo.total
        ),
        Math.round(
          ((ageInfo.maleGroup5 + ageInfo.femaleGroup5 + ageInfo.noneGroup5) *
            100) /
            ageInfo.total
        )
      ];
    };

    const getOverviewUserInfo = () => {
      dashboardService
        .getDashboadUsers()
        .then(res => {
          console.log("user", res.data);
          let data = res.data.data;

          numberOfUser.value = data.numberOfUser;
          numberOfFemale.value = data.numberFemaleOfUser;
          numberOfMale.value = data.numberMaleOfUser;
          numberOfNone.value = data.numberNoneOfUser;

          numberOfLivingWillUser.value = data.numberOfElivingWill;
          numberOfLivingWillFemale.value = data.numberFemaleOfElivingWill;
          numberOfLivingWillMale.value = data.numberMaleOfElivingWill;
          numberOfLivingWillNone.value = data.numberNoneOfElivingWill;

          numberOfInterestedVolunteer.value = data.numberOfInterestedVolunteer;

          dataSets.value.datasets[0].data = getAgeDataSet(data.userAgeInfo);

          livingWillDataSets.value.datasets[0].data = getAgeDataSet(
            data.elivingWillAgeInfo
          );

          console.log("pieChartRed", donutChartRef.value);
        })
        .catch(err => {
          console.log(err);
        });
    };

    const getOverviewProvince = () => {
      dashboardService
        .getDashboadProvinces()
        .then(res => {
          console.log("provinces", res.data);
          provinces.value = res.data.data.slice(0, 5);
        })
        .catch(err => {
          console.log(err);
        });
    };

    const getOverviewAttentions = () => {
      dashboardService
        .getDashboadAttentions()
        .then(res => {
          console.log("attentions", res.data);
          attentions.value = res.data.data.slice(0, 5);
        })
        .catch(err => {
          console.log(err);
        });
    };

    const getOverviewOccupations = () => {
      dashboardService
        .getDashboadOccupations()
        .then(res => {
          console.log("occupations", res.data);
          occupations.value = res.data.data.slice(0, 5);
        })
        .catch(err => {
          console.log(err);
        });
    };

    onMounted(() => {
      getOverviewUserInfo();
      getOverviewProvince();
      getOverviewAttentions();
      getOverviewOccupations();
    });

    return {
      donutChartRef,
      getOverviewUserInfo,
      getOverviewProvince,
      numberOfUser,
      numberOfFemale,
      numberOfMale,
      numberOfNone,
      numberOfLivingWillUser,
      numberOfLivingWillFemale,
      numberOfLivingWillMale,
      numberOfLivingWillNone,
      numberOfInterestedVolunteer,
      provinces,
      dataSets,
      occupations,
      attentions,
      livingWillDataSets,
      $h
    };
  }
});
</script>
