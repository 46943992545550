import { apiApp } from "/src/config/api";
const endpointEmail = "admin/activities";

export const sendDraftEmail = (activityId, payload) => {
  return apiApp.patch(endpointEmail + `/${activityId}/emails`, payload);
};
export const getDraftEmail = activityId => {
  return apiApp.get(endpointEmail + `/${activityId}/emails`);
};
export const sendEmailToUser = (activityId, emailPath) => {
  return apiApp.post(endpointEmail + `/${activityId}/emails/${emailPath}`);
};
export const getEmailBounceById = emailId => {
  return apiApp.get(endpointEmail + `/email-bounces/${emailId}`);
};
export const sendEmailBounce = payload => {
  return apiApp.post(endpointEmail + `/email-bounces`, payload);
};

export const getAmountEmailBounce = (activityId, type) => {
  return apiApp.get(endpointEmail + `/${activityId}/email-bounces/${type}`);
};

export const getEmailBounce = (activityId, type, params) => {
  return apiApp.get(endpointEmail + `/${activityId}/email-bounces/${type}`, {
    params
  });
};

export const deleteEmailBonceById = (activityId, payload) => {
  return apiApp.delete(endpointEmail + `/${activityId}/email-bounces`, {
    data: payload
  });
};

export const getEmailBounceAmount = activityId => {
  return apiApp.get(endpointEmail + `/${activityId}/email-bounces-amount`);
};

export const editEmailBounce = (registerId, type, payload) => {
  return apiApp.patch(
    endpointEmail + `/registers/${registerId}/email-bounces/${type}`,
    payload
  );
};

export const resetEmailBounce = (registerId, type) => {
  return apiApp.patch(
    endpointEmail + `/registers/${registerId}/email-bounces/${type}/reset-email`
  );
};
