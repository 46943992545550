<template>
  <div>
    <div class="p-5 text-center mt-20">
      <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
      <div class="text-3xl mt-5">ต้องการออกจากระบบ ?</div>
      <div class="text-gray-600 mt-2">
        เมื่อออกจากระบบแล้ว ระบบจะกลับไปหน้าเข้าสู่ระแบบ <br />
        ท่านสามารถกลับเข้าใช้งานระบบได้อีกครั้งผ่านหน้าเข้าสู่ระบบนี้
      </div>
    </div>
    <div class="px-5 pb-8 text-center">
      <button type="button" class="btn btn-danger" @click="userLogout()">
        ออกจากระบบ
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    userLogout() {
      this.$cookies.remove("access_token");
      this.$router.push({ path: "/login" });
      localStorage.clear();
    }
  }
});
</script>
