<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <router-link
        :to="{ name: 'side-menu-dashboard-overview-1' }"
        class="text-lg font-medium mr-auto flex items-center"
      >
        <ChevronLeftIcon />Dashboard (ความสนใจ)
      </router-link>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
      <table class="table table-report -mt-2">
        <thead>
          <tr>
            <th class="text-center whitespace-nowrap">#</th>
            <th class="whitespace-nowrap">ความสนใจ</th>
            <th class="text-center whitespace-nowrap">จำนวน</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="attention in attentions.value"
            :key="attention"
            class="intro-x"
          >
            <td class="w-10">
              <div class="text-center">
                {{ attention.no }}
              </div>
            </td>
            <td>
              <div class="flex">
                {{ attention.attentionName }}
              </div>
            </td>
            <td>
              <div class="text-center">
                <b>{{ attention.numberOfUser }}</b>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted } from "vue";
import * as dashboardService from "/src/services/dashboard";

export default defineComponent({
  setup() {
    const attentions = reactive([]);

    const getAttentionList = () => {
      dashboardService
        .getDashboadAttentions()
        .then(res => {
          console.log(res);
          if (res.data.data) {
            attentions.value = res.data.data.map((x, index) => {
              x.no = index + 1;
              return x;
            });
          } else {
            attentions.value = [];
          }
        })
        .catch(err => {
          console.log(err);
        });
    };

    onMounted(() => {
      getAttentionList();
    });

    return {
      attentions
    };
  }
});
</script>
