<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">รายการผู้ใช้งาน</h2>
    </div>
    <div class="intro-y flex flex-col items-center mt-8 mb-4 sm:flex-row">
      <div class="font-medium flex w-full">
        <input
          id="tabulator-html-filter-value"
          v-model="searchContent"
          type="text"
          class="form-control w-full"
          placeholder="ค้นหาชื่อ"
        />
        <button class="btn btn-primary shadow-md ml-4" @click="onSearch">
          ค้นหา
        </button>
      </div>
      <div class="flex w-full pl-2 mt-4 sm:mt-0">
        <div class="dropdown mr-2">
          <button
            class="dropdown-toggle btn btn-outline-secondary w-41"
            aria-expanded="false"
          >
            Living Will ({{ filterSelect.name }})
            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                v-for="filter in filterList"
                :key="filter"
                href="javascript:;"
                data-dismiss="dropdown"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="selectFilter(filter)"
              >
                {{ filter.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-4 sm:mt-0">
        <a
          id="tabulator-export-xlsx"
          href="javascript:;"
          class="dropdown flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
          @click="onExport"
        >
          <FileTextIcon class="w-4 h-4 mr-2" /> Export
        </a>
      </div>
    </div>

    <!-- Start total candidate activities-->
    <div
      v-if="filterSelect.id === 0"
      class="lg:grid-cols-2 lg:h-24 md:max-w-1/2 sm:max-w-1/2"
    >
      <div
        class="all-applicants w-full h-full rounded-lg m-0 pt-4 pb-3.5 pl-6 bg-white overflow-hidden shadow-lg"
      >
        <div class="mt-0 font-normal text-sm">
          ผู้ใช้งานทั้งหมด
        </div>
        <div class="m-0 pt-3">
          <span class="text-2xl font-prompt font-medium ml-0">{{
            allUser
          }}</span>
          <span class="text-sm ml-1">คน</span>
        </div>
      </div>
    </div>
    <!-- End total candidate activities Summary-->
    <!-- Start candidate did do living will-->
    <div
      v-else-if="filterSelect.id === 1"
      class="lg:grid-cols-2 lg:h-24 md:max-w-1/2 sm:max-w-1/2"
    >
      <div
        class="all-applicants w-full h-full rounded-lg m-0 pt-4 pb-3.5 pl-6 bg-white overflow-hidden shadow-lg"
      >
        <div class="mt-0 font-normal text-sm">
          ผู้ใช้งานทำ Living Will
        </div>
        <div class="m-0 pt-3">
          <span class="text-2xl font-prompt font-medium ml-0">{{
            allUser
          }}</span>
          <span class="text-sm ml-1">คน</span>
        </div>
      </div>
    </div>
    <!-- End candidate did do living will-->
    <!-- Start candidate didn't do living will -->
    <div v-else class="lg:grid-cols-2 lg:h-24 md:max-w-1/2 sm:max-w-1/2">
      <div
        class="all-applicants w-full h-full rounded-lg m-0 pt-4 pb-3.5 pl-6 bg-white overflow-hidden shadow-lg"
      >
        <div class="mt-0 font-normal text-sm">
          ผู้ใช้งานยังไม่ได้ทำ Living Will
        </div>
        <div class="m-0 pt-3">
          <span class="text-2xl font-prompt font-medium ml-0">{{
            allUser
          }}</span>
          <span class="text-sm ml-1">คน</span>
        </div>
      </div>
    </div>
    <!-- End candidate didn't do living will -->

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
      <table class="table table-report -mt-2 font-prompt">
        <thead>
          <tr class="text-xs font-normal">
            <th class="text-center whitespace-nowrap">#</th>
            <th class="whitespace-nowrap">ชื่อจริง</th>
            <th class="whitespace-nowrap">นามสกุล</th>
            <th class="text-center whitespace-nowrap">เบอร์โทร</th>
            <th class="text-center whitespace-nowrap">อีเมล</th>
            <th class="text-center whitespace-nowrap">ช่วงอายุ</th>
            <th class="text-center whitespace-nowrap">อาชีพ</th>
            <th class="text-center whitespace-nowrap">LIVING WILL</th>
            <th class="text-center whitespace-nowrap">ช่องทางการสมัคร</th>
            <th class="text-center whitespace-nowrap">วันที่สมัคร</th>
            <th class="text-center whitespace-nowrap">ตัวเลือก</th>
          </tr>
        </thead>
        <tbody class="text-sm">
          <tr
            v-for="user in users"
            :key="user"
            class="intro-x font-normal text-xs"
          >
            <td class="w-10">
              <div class="flex">
                {{ user?.no || "" }}
              </div>
            </td>
            <td class="w-150">
              <div class="flex">{{ user?.first_name || "-" }}</div>
            </td>
            <td class="text-center">
              <div class="flex">{{ user?.last_name || "-" }}</div>
            </td>
            <td class="text-center">
              {{
                `${user.phone_number ? "0" : ""}` +
                  user.phone_number?.substring(3) || "-"
              }}
            </td>
            <td class="text-center break-all">
              {{ user?.email || "-" }}
            </td>
            <td class="text-center">
              {{ user?.age_name || "-" }}
            </td>
            <td class="text-center">
              {{ user?.career_name || "-" }}
            </td>
            <td class="text-center">
              {{ user?.eliving_will ? "ทำแล้ว" : "ยังไม่ได้ทำ" }}
            </td>
            <td class="text-center">
              {{ user?.register_channel || "-" }}
            </td>
            <td class="text-center">
              {{ getDateFormat(user?.created_at) || "-" }}
            </td>
            <td class="table-report__action">
              <div class="flex justify-center items-center">
                <button type="button">
                  <EyeIcon
                    class="flex items-center w-4 h-3 text-theme-33 cursor-pointer"
                    @click="setUserInfo(user)"
                  />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <!-- BEGIN: Pagination -->
    <div
      class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-4"
    >
      <ul class="pagination">
        <li>
          <a class="pagination__link" @click="paginationSelect(1)">
            <ChevronsLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="paginationSelect(pageSelect == 1 ? 1 : pageSelect - 1)"
          >
            <ChevronLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li v-for="page in pages" :key="page" @click="paginationSelect(page)">
          <a
            v-if="pageSelect == page"
            class="pagination__link pagination__link--active"
            >{{ page }}</a
          >
          <a v-else class="pagination__link">{{ page }}</a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="
              paginationSelect(
                pageSelect == totalPages ? totalPages : pageSelect + 1
              )
            "
          >
            <ChevronRightIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a class="pagination__link" @click="paginationSelect(totalPages)">
            <ChevronsRightIcon class="w-4 h-4" />
          </a>
        </li>
      </ul>
      <select
        v-model="pageSizeSelect"
        class="w-20 form-select box mt-3 sm:mt-0"
        @change="
          pageSelect = 1;
          getUsers();
        "
      >
        <option v-for="page in pageSize" :key="page" :value="page">
          {{ page }}
        </option>
      </select>
    </div>
    <!-- END: Pagination -->

    <!-- BEGIN: Modal attention -->
    <div
      id="user-attention-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto"><b>ความสนใจ</b></h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body">
            <ul>
              <li
                v-for="(attention, $attentionIndex) in attentions"
                :key="attention"
                class="mt-3"
              >
                {{ $attentionIndex + 1 }}. {{ attention }}
              </li>
            </ul>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              ปิด
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal attention -->

    <div
      id="user-detail-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="mx-auto my-8 w-1/2">
        <div class="modal-content">
          <button
            type="button"
            class="v-dialog-close"
            aria-label="Close"
            data-dismiss="modal"
          >
            <span class="v-btn__content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#5E5873"
                class="h-6 w-6 m-0"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
          <div class="p-8 pb-14 modal-body px-16 font-prompt">
            <div class="text-center mt-6">
              <p class="bold text-2xl font-medium">รายละเอียดสมาชิก</p>
            </div>
            <div class="grid grid-cols-3 grid-flow-row gap-x-16 gap-y-7 mt-10">
              <div>
                <p>ชื่อจริง</p>
                <p class="font-semibold">
                  {{ userDetail?.first_name || "-" }}
                </p>
              </div>
              <div>
                <p>นามสกุล</p>
                <p class="font-semibold">{{ userDetail?.last_name || "-" }}</p>
              </div>
              <div>
                <p>ชื่อเล่น</p>
                <p class="font-semibold">
                  {{ userDetail?.nick_name || "-" }}
                </p>
              </div>
              <div>
                <p>จังหวัดที่อาศัยอยู่ในปัจจุบัน</p>
                <p class="font-semibold">
                  {{ userDetail?.province_name || "-" }}
                </p>
              </div>
              <div>
                <p>วันเดือนปีเกิด</p>
                <p class="font-semibold">
                  {{ $h.formatDate(userDetail?.date_of_birth, "DD/MM/YYYY") }}
                </p>
              </div>
              <div>
                <p>อายุ</p>
                <p class="font-semibold">{{ userDetail?.age_name || "-" }}</p>
              </div>
              <div>
                <p>เพศ</p>
                <p class="font-semibold">
                  {{ userDetail?.gender_name || "-" }}
                </p>
              </div>
              <div>
                <p>ระดับการศึกษา</p>
                <p class="font-semibold">
                  {{ userDetail?.education_name || "-" }}
                </p>
              </div>
              <div>
                <p>รายได้ครัวเรือน</p>
                <p class="font-semibold">
                  {{ userDetail?.income_name || "-" }}
                </p>
              </div>
              <div>
                <p>อาชีพ</p>
                <p class="font-semibold">
                  {{ userDetail?.career_name || "-" }}
                </p>
              </div>
              <div>
                <p>เบอร์โทร</p>
                <p class="font-semibold">
                  {{
                    `${userDetail?.phone_number ? "0" : ""}` +
                      userDetail?.phone_number?.substring(3) || ""
                  }}
                </p>
              </div>
              <div>
                <p>Line ID</p>
                <p class="font-semibold">
                  {{ userDetail?.line_id || "-" }}
                </p>
              </div>
              <div>
                <p>อีเมล</p>
                <p class="font-semibold">
                  {{ userDetail?.email || "-" }}
                </p>
              </div>
              <div>
                <p>Living Will</p>
                <p class="font-semibold">
                  {{ userDetail.eliving_will ? "ทำแล้ว" : "ยังไม่ได้ทำ" }}
                </p>
              </div>
              <div>
                <p>ช่องทางการติดต่อ</p>
                <p class="font-semibold">
                  {{ userDetail?.register_channel || "-" }}
                </p>
              </div>
            </div>
            <hr class="mt-10 mb-9" />
            <div>
              <p class="text-xs font-normal">
                จำนวนกิจกรรมที่สมัคร({{ userDetail?.activities?.length || 0 }})
              </p>
              <ul
                v-if="userDetail?.activities"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-32"
              >
                <li
                  v-for="activity in userDetail?.activities"
                  :key="activity.id"
                >
                  - {{ activity.name }}
                </li>
              </ul>
              <ul
                v-show="!userDetail?.activities"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-31"
              >
                <li>
                  - ไม่ได้กรอก
                </li>
              </ul>
            </div>
            <hr class="my-8" />
            <div>
              <p class="text-xs font-normal">
                ความสนใจ
              </p>
              <ul
                v-if="userDetail?.attentions"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-36"
              >
                <li
                  v-for="attention in userDetail?.attentions"
                  :key="attention.id"
                >
                  - {{ attention.name }}
                </li>
              </ul>
              <ul
                v-show="userDetail?.attention_text"
                class="px-2.5 text-sm font-normal text-theme-36"
              >
                <li>- อื่นๆ ({{ userDetail?.attention_text }})</li>
              </ul>
              <ul
                v-show="!userDetail?.attentions"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-31"
              >
                <li>
                  - ไม่ได้กรอก
                </li>
              </ul>
            </div>
            <hr class="my-8" />
            <div>
              <p class="text-xs font-normal">
                รู้จักชีวามิตรจากช่องทางไหน
              </p>
              <ul
                v-if="userDetail?.socials"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-36"
              >
                <li v-for="social in userDetail?.socials" :key="social.id">
                  - {{ social.name }}
                </li>
              </ul>
              <ul
                v-show="userDetail?.social_text"
                class="px-2.5 text-sm font-normal text-theme-36"
              >
                <li>- อื่นๆ ({{ userDetail?.social_text }})</li>
              </ul>
              <ul
                v-show="!userDetail?.socials"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-31"
              >
                <li>
                  - ไม่ได้กรอก
                </li>
              </ul>
            </div>
            <hr class="my-8" />
            <div>
              <p class="text-xs font-normal">
                เมื่อติดตามชีวามิตรได้รับประโยชน์อะไรกับชีวิต
              </p>
              <ul
                v-if="userDetail?.benefits"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-36"
              >
                <li v-for="benefit in userDetail?.benefits" :key="benefit.id">
                  - {{ benefit.name }}
                </li>
              </ul>
              <ul
                v-show="userDetail?.benefit_text"
                class="px-2.5 text-sm font-normal text-theme-36"
              >
                <li>- อื่นๆ ({{ userDetail?.benefit_text }})</li>
              </ul>
              <ul
                v-show="!userDetail?.benefits"
                class="px-2.5 mt-2.5 text-sm font-normal text-theme-31"
              >
                <li>
                  - ไม่ได้กรอก
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Detail User Modal-->

    <div class="modal" tabindex="-1" aria-hidden="true">
      <div id="error-notification-content" class="modal-dialog w-460">
        <div class="modal-content h-72 w-512 mt-40 rounded-lg">
          <div class="text-center">
            <div class="m-0 pt-14 font-prompt">
              <div class="flex justify-center items-center">
                <AlertCircleIcon class="text-red-500 w-16 h-16" />
              </div>
              <div class="font-medium text-xl mt-5">ผิดพลาด !!</div>
              <div
                class="mt-5 text-xl font-medium leading-8 text-theme-32 px-12"
              >
                {{ errorTxt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Modal Error-->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive, nextTick } from "vue";
import Toastify from "toastify-js";
import moment from "moment";
import * as userService from "/src/services/user";
import XLSX from "xlsx";
import { helper as $h } from "@/utils/helper";

export default defineComponent({
  setup() {
    const pageSize = ref([10, 20, 30, 40]);
    const pageSizeSelect = ref(pageSize.value[0]);
    const pages = ref([]);
    const totalPages = ref(0);
    const pageSelect = ref(1);
    const users = ref([]);
    const isLoading = ref(false);
    const userDetail = ref([]);
    const allUser = ref(null);
    const errorTxt = ref("");
    const maxAttentionLength = ref(0);
    const maxBenefitLength = ref(0);
    const maxSocialLength = ref(0);
    const filterList = ref([
      {
        id: 0,
        name: "ทั้งหมด"
      },
      {
        id: 1,
        name: "ทำแล้ว"
      },
      {
        id: 2,
        name: "ยังไม่ได้ทำ"
      }
    ]);

    const attentions = reactive([]);
    let userInfo = reactive({});
    const filterSelect = ref(filterList.value[0]);
    const searchContent = ref("");
    const isFetching = ref(false);

    const getUsers = async () => {
      try {
        isLoading.value = true;
        let params = {
          page: pageSelect.value,
          perPage: pageSizeSelect.value
        };
        if (filterSelect.value.id != 0) {
          params.is_eliving_will = filterSelect.value.id == 1;
        }
        if (searchContent.value) {
          params.search = searchContent.value;
        }

        const res = await userService.getUserList(params);
        const { total, items } = res.data.data;
        allUser.value = total;
        users.value = items.map((x, index) => {
          x.no = index + 1 + (pageSelect.value - 1) * pageSizeSelect.value;
          return x;
        });
        const pageResult = $h.calculatePagination({
          totalItems: total,
          pageSize: pageSizeSelect.value,
          currentPage: pageSelect.value
        });
        pages.value = pageResult.pages;
        totalPages.value = pageResult.totalPages;
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        isLoading.value = false;
      }
    };

    const getGender = index => {
      if (index == 1) {
        return "ชาย";
      } else if (index == 2) {
        return "หญิง";
      } else {
        return "อื่นๆ";
      }
    };

    const getUserDetail = async uid => {
      if (isLoading.value) {
        return;
      }
      isLoading.value = true;
      try {
        const res = await userService.getUserDetail(uid);
        userDetail.value = res.data.data.item;
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        isLoading.value = false;
      }
    };

    const selectFilter = filter => {
      pageSelect.value = 1;
      filterSelect.value = filter;
      getUsers();
    };

    const onSearch = () => {
      getUsers();
    };

    const onExport = async () => {
      let params = {
        perPage: 9999
      };
      try {
        isLoading.value = true;
        if (filterSelect.value.id != 0) {
          params.is_eliving_will = filterSelect.value.id == 1;
        }
        const res = await userService.getUserList(params);
        const { items } = res.data.data;

        calculateMaxValues(items);

        const headers = [
          "ชื่อ",
          "สกุล",
          "ชื่อเล่น",
          "เพศ",
          "อายุ",
          "วันเกิด",
          "อาชีพ",
          "จังหวัด",
          "การศึกษา",
          "รายได้",
          "ไลน์",
          "อีเมล",
          "เบอร์",
          "วันที่สมัคร",
          "LivingWill"
        ];

        headers.push(
          ...generateHeaderLabels("ความสนใจ", maxAttentionLength.value)
        );
        headers.push(
          ...generateHeaderLabels(
            "เมื่อติดตามชีวามิตรได้รับประโยชน์อะไรกับชีวิต",
            maxBenefitLength.value
          )
        );
        headers.push(
          ...generateHeaderLabels(
            "รู้จักชีวามิตรจากช่องทางไหน",
            maxSocialLength.value
          )
        );

        const data = [headers];
        for (let user of items) {
          const rowData = [
            user.first_name || "",
            user.last_name || "",
            user.nick_name || "",
            user.gender_name || "",
            user.age_name || "",
            $h.formatDate(user?.date_of_birth, "DD/MM/YYYY") || "",
            user.career_name || "",
            user.province_name || "",
            user.education_name || "",
            user.income_name || "",
            user.line_id || "",
            user.email || "",
            `${user.phone_number ? "0" : ""}` +
              user.phone_number?.substring(3) || "",
            getDateFormat(user.created_at) || "",
            answerYesOrNot(user.eliving_will) || ""
          ];

          appendRowData(
            user.attentions ?? [],
            rowData,
            maxAttentionLength.value
          );
          appendRowData(user.benefits ?? [], rowData, maxBenefitLength.value);
          appendRowData(user.socials ?? [], rowData, maxSocialLength.value);

          data.push(rowData);
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "export.xlsx");
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        isLoading.value = false;
      }
    };

    const calculateMaxValues = items => {
      const getMaxPropertyLength = (items, property) =>
        Math.max(...items.map(user => (user[property] ?? []).length));

      maxAttentionLength.value = getMaxPropertyLength(items, "attentions");
      maxBenefitLength.value = getMaxPropertyLength(items, "benefits");
      maxSocialLength.value = getMaxPropertyLength(items, "socials");
    };

    const generateHeaderLabels = (prefix, count) => {
      const labels = [];
      for (let i = 0; i < count; i++) {
        labels.push(prefix);
      }
      return labels;
    };

    const appendRowData = (dataList, rowData, maxCount) => {
      for (let i = 0; i < maxCount; i++) {
        if (dataList.length > i) {
          rowData.push(`${dataList[i].name}`);
        } else {
          rowData.push("");
        }
      }
    };

    const answerYesOrNot = answer => {
      return answer ? "ทำแล้ว" : "ยังไม่ได้ทำ";
    };

    const getDateFormat = date => {
      return moment(date).format("DD/MM/YYYY");
    };

    // Pagination
    const paginationSelect = page => {
      pageSelect.value = page;
      getUsers();
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 1500,
          newWindow: true,
          gravity: "center",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    onMounted(() => {
      getUsers();
    });

    const setAttentions = attn => {
      attentions.length = 0;
      attentions.push(...attn);
    };

    const setUserInfo = user => {
      getUserDetail(user.Uid);
      cash("#user-detail-modal").modal("show");
      Object.assign(userInfo, user);
    };

    return {
      attentions,
      userInfo,
      pageSize,
      pageSizeSelect,
      pageSelect,
      pages,
      totalPages,
      getGender,
      getDateFormat,
      getUserDetail,
      isLoading,
      userDetail,
      users,
      getUsers,
      filterList,
      filterSelect,
      selectFilter,
      onSearch,
      searchContent,
      onExport,
      paginationSelect,
      setAttentions,
      setUserInfo,
      allUser,
      errorNotificationToggle,
      errorTxt,
      isFetching,
      answerYesOrNot,
      calculateMaxValues,
      generateHeaderLabels,
      appendRowData
    };
  }
});
</script>
<style scoped>
button:focus {
  outline: none;
}
.w-512 {
  width: 512px;
}

.v-dialog-close {
  display: grid;
  position: absolute;
  z-index: 1;
  border-radius: 0.375rem;
  background-color: white !important;
  block-size: 2rem;
  inset-block-start: 0;
  inset-inline-end: 0;
  transform: translate(0.5rem, -0.5rem);
  width: 34px;
  height: 34px;
  box-shadow: 0px 3px 8px rgba(167, 174, 181, 0.4);
}
.v-btn__content {
  grid-area: content;
  justify-content: center;
  white-space: nowrap;
}
</style>
