<template>
  <div class="date-container" tabindex="0">
    <div
      :class="`date-input ${readOnly ? 'readonly' : ''}`"
      @click="
        if (!readOnly) {
          showSector = !showSector;
        }
      "
    >
      <div>{{ selectedDate }}</div>
      <div>
        <CalendarIcon class="ml-auto" />
      </div>
    </div>
    <XIcon
      v-if="!readOnly"
      class="absolute ml-auto right-8 top-2"
      @click="selectDate(null)"
    />
    <div
      v-if="showSector"
      class="fixed right-1/2 translate-x-2/4 transform sm:right-0 sm:translate-x-0 z-20 mt-1 overflow-visible sm:absolute"
      style="width: 300px"
    >
      <div class="flex flex-col py-4 bg-white border-2 rounded-lg">
        <div class="flex w-full mt-2 font-bold justify-evenly">
          <ChevronLeftIcon @click="backMonth" />
          <div class="px-1 py-1">
            {{ getMonthName(month) }} {{ year + 543 }}
          </div>
          <ChevronRightIcon @click="nextMonth" />
        </div>
        <div class="flex flex-wrap px-1 mt-2">
          <div
            v-for="name of daysName"
            :key="name"
            class="items-center px-1 day"
          >
            {{ name }}
          </div>
        </div>
        <div class="flex flex-wrap px-1">
          <div v-for="day of blankDays" :key="day" class="px-1 day"></div>
          <div
            v-for="day of days"
            :key="day"
            class="items-center px-1 rounded day "
            :class="
              !day.disabled
                ? 'cursor-pointer hover:bg-primary-1'
                : 'cursor-not-allowed text-gray-400'
            "
            @click="
              !day.disabled
                ? `${(selectDate(day.day), (showSector = false))}`
                : ''
            "
          >
            {{ day.day }}
          </div>
        </div>
        <div class="flex justify-end px-4">
          <button class="w-20 btn border-dark-1" @click="showSector = false">
            ปิด
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: {
    setSelected: { type: String, default: "" },
    diabledBeforeToday: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false }
  },
  data() {
    const today = moment();
    const month = today.month();
    const year = today.year();

    return {
      selectedDate: this.setSelected ?? "",
      today: today,
      month: month,
      year: year,
      blankDays: [],
      days: [],
      daysName: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
      months: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤษจิกายน",
        "ธันวาคม"
      ],
      showSector: false,
      isDiabledBeforToday: false
    };
  },
  watch: {
    setSelected(value) {
      this.selectedDate = value;
    },
    diabledBeforeToday: {
      deep: true,
      immediate: true,
      handler(value) {
        this.isDiabledBeforToday = value;
      }
    }
  },
  async mounted() {
    this.generateMonth();
  },
  methods: {
    generateMonth() {
      this.blankDays = [];
      this.days = [];
      const firstDayInmonth = moment({
        year: this.year,
        month: this.month,
        day: 1
      });
      const firstDayOfFirstWeek = firstDayInmonth.day();
      for (let day = 1; day <= firstDayInmonth.daysInMonth(); day++) {
        if (day <= firstDayOfFirstWeek) {
          this.blankDays.push(day);
        }
        if (this.isDiabledBeforToday) {
          let date = moment({
            year: this.year,
            month: this.month,
            day: day
          });
          let today = new Date(new Date().setDate(new Date().getDate() - 1));
          if (today < date) {
            this.days.push({ day: day, disabled: false });
          } else {
            this.days.push({ day: day, disabled: true });
          }
        } else {
          this.days.push({ day: day, disabled: false });
        }
      }
    },
    selectDate(day) {
      if (day) {
        this.selectedDate = moment([this.year, this.month, day]).format(
          "YYYY-MM-DD"
        );
      } else {
        this.selectedDate = "";
      }
      this.$emit("update:modelValue", this.selectedDate);
    },
    getMonthName(month) {
      return this.months[month];
    },
    hideSelector() {
      this.showSector = false;
    },
    nextMonth() {
      const isEndOfYear = this.month === 11;
      this.month += isEndOfYear ? -11 : 1;
      this.year += isEndOfYear ? 1 : 0;
      this.generateMonth();
    },
    backMonth() {
      const isFistOfYear = this.month === 0;
      this.month -= isFistOfYear ? -11 : 1;
      this.year -= isFistOfYear ? 1 : 0;
      this.generateMonth();
    }
  }
};
</script>
<style lang="scss">
.date-container {
  position: relative;
  .date-input {
    min-height: 40px;
    min-width: 100%;
    border-radius: 5px;
    border-width: thin;
    border-style: solid;
    border-color: #d4d9de;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    cursor: pointer;
    &.readonly {
      background: #f7fafc;
      cursor: not-allowed;
    }
  }
  .day {
    width: 14.28%;
    height: 30px;
    display: flex;
    justify-content: center;
  }
}
</style>
