<template>
  <div>
    <QuillEditor
      ref="quill"
      :options="editorOption"
      content-type="html"
      class="font-sarabun"
      @update:content="onUpdateData()"
    />
  </div>
</template>
<script>
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import axios from "axios";
import * as imageService from "/src/services/upload";

export default {
  props: {
    dataContent: {
      type: String,
      default: ""
    }
  },
  data() {
    var toolbarOptions = [
      ["bold", "italic", "underline", "strike"],
      ["blockquote"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image", "video"]
    ];
    return {
      content: "",
      editorOption: {
        theme: "snow",
        modules: {
          imageResize: {},
          toolbar: toolbarOptions,
          imageUploader: {
            upload: file => this.onUpload(file)
          }
        }
      }
    };
  },
  mounted() {
    this.$refs.quill.setHTML(this.dataContent);
  },
  methods: {
    onUpload(file) {
      return new Promise(resolve => {
        let setDataImage = {
          folder: "editor",
          file_name: file.name
        };
        imageService
          .uploadImage(setDataImage)
          .then(res => {
            let result = res.data.data;
            setTimeout(() => {
              let img_url = this.uploadImageToStorage(result, file);
              resolve(img_url);
            }, 1500);
          })
          .catch(err => {
            console.log("err", err);
          });
      });
    },
    async uploadImageToStorage(result, file) {
      try {
        await axios.put(result.urlUpload, file, {
          headers: {
            "content-type": file.type
          }
        });
        return result.urlPublic;
      } catch (error) {
        console.log(error);
      }
    },
    onUpdateData() {
      this.$emit("update:modelValue", this.$refs.quill.getContents());
    }
  }
};
</script>
<style>
.ql-editor {
  background-color: white;
  height: 50vh;
  font-size: 18px;
  @apply font-sarabun;
}

.ql-editor .ql-video {
  @apply flex mx-auto justify-center w-full h-64 md:h-72 md:w-10/12 lg:w-8/12 xl:w-1/2;
}
</style>
<!-- eslint-enable -->
