<template>
  <div class="mb-2">
    <div class="flex flex-col items-center mt-8 intro-y sm:flex-row">
      <div class="flex items-center mr-auto text-lg font-medium">
        {{ isEdit ? "แก้ไขแบนเนอร์" : "เพิ่มแบนเนอร์" }}
      </div>
    </div>
    <div class="flex flex-col w-full intro-y">
      <div class="form-group">
        <label class="text-base required">รูปแบนเนอร์</label>
        <div class="flex flex-col items-center space-x-4">
          <div class="w-full provider">
            <div
              class="flex items-center justify-center py-4 w-full"
              style="height: 23rem"
            >
              <DropAnImage
                v-model="providerImageSrc"
                :data-image="providerImageSrc"
                :folder-name-image="folderNameImage"
                :is-edit="isEdit"
              ></DropAnImage>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="text-sm required">ประเภทแบนเนอร์</label>
        <input
          id="category"
          v-model="category"
          type="text"
          class="w-full form-control"
        />
      </div>
      <div class="form-group">
        <label class="text-sm required">หัวข้อ</label>
        <input
          id="title"
          v-model="title"
          type="text"
          class="w-full form-control"
        />
      </div>
      <div class="form-group">
        <label class="text-sm required">คำอธิบาย</label>
        <input
          id="description"
          v-model="description"
          type="text"
          class="w-full form-control"
        />
      </div>
      <div class="form-group">
        <label class="text-sm required">เรียบเรียงโดย</label>
        <input
          id="description"
          v-model="writerName"
          type="text"
          class="w-full form-control"
        />
      </div>

      <div class="flex py-8 space-x-3">
        <button
          :disabled="isDisableSubmit()"
          :class="{ 'opacity-50': isDisableSubmit() }"
          class="btn btn-primary"
          @click="onSave()"
        >
          บันทึก
        </button>
        <router-link :to="{ name: 'banner-main' }">
          <button class="bg-white btn btn-outline-secondary">ยกเลิก</button>
        </router-link>
      </div>
    </div>
    <!-- BEGIN: Notification Content -->
    <div id="success-notification-content" class="flex hidden toastify-content">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">สำเร็จ !!</div>
        <div class="mt-1 text-gray-600">
          {{ successTxt }}
        </div>
      </div>
    </div>
    <div id="error-notification-content" class="flex hidden toastify-content">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          {{ errorTxt }}
        </div>
      </div>
    </div>
    <!-- END: Notification Content -->
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as bannerService from "/src/services/banner";
import DropAnImage from "@/global-components/drop-image/Main.vue";
import Toastify from "toastify-js";
export default defineComponent({
  components: {
    DropAnImage
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const selectedCatagory = ref(null);
    const selectedTags = ref(null);
    const selectedDate = ref(null);
    const coverImageFile = ref(null);
    const providerImage = ref(null);
    const providerImageSrc = ref(null);
    const category = ref(null);
    const title = ref(null);
    const description = ref(null);
    const writerName = ref(null);
    const sponsorImages = ref(null);
    const bannerId = ref(null);
    const isEdit = ref(false);
    const errorTxt = ref("");
    const successTxt = ref("");
    const is_published = ref(false);
    const folderNameImage = ref("banner");

    onMounted(() => {
      bannerId.value = parseInt(route.params.id)
        ? parseInt(route.params.id)
        : null;
      isEdit.value = bannerId.value != null ? true : false;
      if (bannerId.value) {
        getDetailBanner();
      }
    });

    const onSave = () => {
      let form = {
        title: title.value,
        description: description.value,
        image_url: providerImageSrc.value.image_url
          ? providerImageSrc.value.image_url
          : providerImageSrc.value,
        author_name: writerName.value,
        is_publish: is_published.value,
        category: category.value
      };
      if (isEdit.value) {
        onEdit(form);
      } else {
        onCreate(form);
      }
    };
    const onEdit = form => {
      bannerService
        .editBanner(form, bannerId.value)
        .then(() => {
          successTxt.value = "แก้ไขแบนเนอร์เรียบร้อยแล้ว";
          cash("#header-footer-modal-preview").modal("hide");
          successNotificationToggle();
          router.push("/banner");
        })
        .catch(() => {
          cash("#header-footer-modal-preview").modal("hide");
          errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
          errorNotificationToggle();
        });
    };
    const onCreate = form => {
      bannerService
        .createBanner(form)
        .then(() => {
          successTxt.value = "เพิ่มแบนเนอร์เรียบร้อยแล้ว";
          cash("#header-footer-modal-preview").modal("hide");
          successNotificationToggle();
          router.push("/banner");
        })
        .catch(() => {
          cash("#header-footer-modal-preview").modal("hide");
          errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
          errorNotificationToggle();
        });
    };
    const getDetailBanner = () => {
      bannerService.detailBanner(bannerId.value).then(res => {
        let result = res.data.data;
        title.value = result.title;
        description.value = result.description;
        coverImageFile.value = result.image;
        writerName.value = result.author_name;
        is_published.value = result.is_publish;
        category.value = result.category;
        providerImageSrc.value = result.image_url;
      });
    };
    const isDisableSubmit = () => {
      return (
        isNullOrEmpty(providerImageSrc) ||
        isNullOrEmpty(title) ||
        isNullOrEmpty(description) ||
        isNullOrEmpty(category) ||
        isNullOrEmpty(writerName)
      );
    };

    const isNullOrEmpty = val => {
      return val.value === null || val.value === "" || val.value.length == 0;
    };
    // Notification
    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };
    return {
      selectedCatagory,
      selectedTags,
      selectedDate,
      category,
      coverImageFile,
      providerImage,
      providerImageSrc,
      title,
      description,
      sponsorImages,
      onSave,
      isDisableSubmit,
      isNullOrEmpty,
      getDetailBanner,
      onEdit,
      writerName,
      onCreate,
      errorNotificationToggle,
      successNotificationToggle,
      successTxt,
      errorTxt,
      folderNameImage,
      isEdit
    };
  }
});
</script>
<style scoped lang="scss">
.form-group {
  @apply flex flex-col space-y-1 mb-4;
}
label.required::after {
  content: " *";
  color: red;
}
.custom-file-upload {
  input {
    display: none;
  }
  border: 1px solid #ccc;
  cursor: pointer;
}
</style>
