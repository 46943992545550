<template>
  <div
    v-if="isEdit === true && isLastFile === true"
    class="flex items-center content-center mb-3.5 mt-2"
  >
    <div class="input-file-upload-wrapper inline-block">
      <div>
        <input type="file" @change="handleEditFileUpload" />
        <div class="text-file-name truncate">
          {{ item?.file?.originalName }}
        </div>
      </div>
    </div>
    <div v-if="item?.file?.originalName" class="inline-block pl-32">
      <button
        class="border-gray-400 remove-file-button w-auto h-auto flex"
        @click="handleRemoveLastFileEdit"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="30" height="30" rx="5" stroke="#F08485" />
          <path
            d="M20.7857 19.7857C21.0714 20.0714 21.0714 20.5 20.7857 20.7857C20.6429 20.9286 20.5 21 20.2857 21C20.0714 21 19.9286 20.9286 19.7857 20.7857L16 17L12.2143 20.7857C12.0714 20.9286 11.9286 21 11.7143 21C11.5 21 11.3571 20.9286 11.2143 20.7857C10.9286 20.5 10.9286 20.0714 11.2143 19.7857L15 16L11.2143 12.2143C10.9286 11.9286 10.9286 11.5 11.2143 11.2143C11.5 10.9286 11.9286 10.9286 12.2143 11.2143L16 15L19.7857 11.2143C20.0714 10.9286 20.5 10.9286 20.7857 11.2143C21.0714 11.5 21.0714 11.9286 20.7857 12.2143L17 16L20.7857 19.7857Z"
            fill="#EA5556"
          />
        </svg>
      </button>
    </div>
  </div>
  <div
    v-else-if="isEdit === true && isLastFile === false"
    class="flex items-center content-center mb-3.5 mt-2"
  >
    <div class="input-file-upload-wrapper inline-block">
      <div>
        <input type="file" @change="handleEditFileUpload" />
        <div class="text-file-name truncate">
          {{ newFile?.original_name }}
        </div>
      </div>
    </div>
    <div
      v-if="newFile.original_name || lengthFile > 1 || lengthGetEditFile > 1"
      class="inline-block pl-32"
    >
      <button
        class="border-gray-400 remove-file-button w-auto h-auto flex"
        @click="handleRemoveEdit"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="30" height="30" rx="5" stroke="#F08485" />
          <path
            d="M20.7857 19.7857C21.0714 20.0714 21.0714 20.5 20.7857 20.7857C20.6429 20.9286 20.5 21 20.2857 21C20.0714 21 19.9286 20.9286 19.7857 20.7857L16 17L12.2143 20.7857C12.0714 20.9286 11.9286 21 11.7143 21C11.5 21 11.3571 20.9286 11.2143 20.7857C10.9286 20.5 10.9286 20.0714 11.2143 19.7857L15 16L11.2143 12.2143C10.9286 11.9286 10.9286 11.5 11.2143 11.2143C11.5 10.9286 11.9286 10.9286 12.2143 11.2143L16 15L19.7857 11.2143C20.0714 10.9286 20.5 10.9286 20.7857 11.2143C21.0714 11.5 21.0714 11.9286 20.7857 12.2143L17 16L20.7857 19.7857Z"
            fill="#EA5556"
          />
        </svg>
      </button>
    </div>
  </div>
  <div v-else class="flex items-center content-center mb-3.5 mt-2">
    <div class="input-file-upload-wrapper inline-block">
      <div>
        <input type="file" @change="handleFileUpload" />
        <div class="text-file-name truncate">
          {{ item.original_name }}
        </div>
      </div>
    </div>
    <div
      v-show="item.fileSize !== null || lengthFile > 1"
      class="inline-block pl-32"
    >
      <button
        class="border-gray-400 remove-file-button w-auto h-auto flex"
        @click="handleRemove"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="30" height="30" rx="5" stroke="#F08485" />
          <path
            d="M20.7857 19.7857C21.0714 20.0714 21.0714 20.5 20.7857 20.7857C20.6429 20.9286 20.5 21 20.2857 21C20.0714 21 19.9286 20.9286 19.7857 20.7857L16 17L12.2143 20.7857C12.0714 20.9286 11.9286 21 11.7143 21C11.5 21 11.3571 20.9286 11.2143 20.7857C10.9286 20.5 10.9286 20.0714 11.2143 19.7857L15 16L11.2143 12.2143C10.9286 11.9286 10.9286 11.5 11.2143 11.2143C11.5 10.9286 11.9286 10.9286 12.2143 11.2143L16 15L19.7857 11.2143C20.0714 10.9286 20.5 10.9286 20.7857 11.2143C21.0714 11.5 21.0714 11.9286 20.7857 12.2143L17 16L20.7857 19.7857Z"
            fill="#EA5556"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({})
    },
    newFile: { type: Object, required: false, default: () => ({}) },
    isEdit: {
      type: Boolean,
      default: false
    },
    isLastFile: {
      type: Boolean,
      default: false
    },
    lengthFile: {
      type: Number,
      default: 0
    },
    lengthGetEditFile: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.$emit("file-uploaded", file);
    },
    handleRemove() {
      this.$emit("remove-uploaded");
    },
    handleEditFileUpload(event) {
      const file = event.target.files[0];
      this.$emit("file-edit-uploaded", file);
    },
    handleRemoveEdit() {
      this.$emit("remove-edit-uploaded");
    },
    handleRemoveLastFileEdit() {
      this.$emit("remove-last-edit-uploaded");
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/sass/components/_file-upload-activity.scss";
.form-group {
  @apply flex flex-col space-y-1 mb-4;
}
label.required::after {
  content: " *";
  color: red;
}
</style>
