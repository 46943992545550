<template>
  <div class="mx-2">
    <div class="flex flex-col w-full intro-y">
      <div class="form-group">
        <div class="grid grid-cols-2 pb-3 gap-4">
          <div class="col-start-1 col-end-3 flex items-end text-lg">
            อัพโหลดรูปแบบใบประกาศนียบัตร
          </div>

          <div class="col-end-7 col-span-2 flex">
            <div class="pr-3.5">
              <a
                :href="`${state.publicPath}cert.png`"
                download="certificate-template.ai"
              >
                <button
                  class="w-full h-full text-sm font-normal shadow-md btn btn-outline-secondary"
                >
                  ดาวน์โหลดรูปแบบ
                </button>
              </a>
            </div>

            <div>
              <button
                class="w-full h-full text-sm font-normal shadow-md btn btn-primary"
                :class="{ 'opacity-30': isDisabled }"
                :disabled="isDisabled"
                @click="onClickSendCert"
              >
                ส่งใบประกาศนียบัตร
              </button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <DropAnImage
            v-model="certificateImages"
            :folder-name-image="folderNameImage"
            desc-label="(อัพโหลดรูปสำหรับใช้เป็นรูปภาพสนับสนุนกิจกรรม, ไฟล์รูปแนะนำ JPG , PNG ขนาด 1755x1240 px)"
            :data-image="certificateImages"
            :is-edit="isEdit"
          ></DropAnImage>
        </div>
      </div>

      <div class="flex py-8 space-x-3">
        <button
          :disabled="isDisabled"
          :class="{ 'opacity-30': isDisabled }"
          class="btn btn-primary"
          @click="onCreate"
        >
          บันทึกการเปลี่ยนแปลง
          <LoadingIcon
            v-if="loadPage"
            icon="oval"
            color="white"
            class="w-4 h-4 ml-2"
          />
        </button>
      </div>
    </div>

    <!--Start Send cert to user Modal-->
    <div
      id="send-cert-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 inline-block w-24 h-20" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              ยืนยันการส่งใบประกาศนียบัตร ?
            </div>
            <div class="pt-2 leading-5 text-sm text-theme-32">
              <p>คุณต้องการส่งใบประกาศนียบัตร</p>
              <p>ไปยังผู้ผ่านคัดเลือกทั้งหมด, ใช่หรือไม่ ?</p>
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-36 h-10 mr-2 btn btn-primary"
              @click="onSendCertToUser"
            >
              ยืนยันการส่งอีเมล
            </button>

            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Send cert to user Modal-->

    <!--Start Not Have Cert Modal-->
    <div
      id="not-have-cert-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-80 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 inline-block w-24 h-20" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              ยังไม่มีรูปแบบสำหรับการส่งใบประกาศนียบัตร
            </div>
            <div class="pt-2 leading-5 text-sm text-theme-32">
              <p>
                ไม่สามารถกดส่งอีเมลได้ เนื่องจากคุณไม่ได้อัพโหลดรูปแบบ <br />
                สำหรับการสร้างใบประกาศนียบัตร กรุณาอัพโหลดรูปแบบไฟล์ <br />
                และใบประกาศตามที่ระบบกำหนด
              </p>
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-36 h-10 mr-2 btn btn-primary"
            >
              ตกลง
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Not Have Cert Modal-->

    <!--Start need update Cert Modal-->
    <div
      id="need-update-cert-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-80 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 inline-block w-24 h-20" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              กรุณาอัพโหลดรูปแบบไฟล์ก่อนส่งใบประกาศนียบัตร
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-36 h-10 mr-2 btn btn-primary"
            >
              ตกลง
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End need update Cert Modal-->

    <!--Start Modal Success-->
    <div
      id="success-content-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 mt-40 rounded-lg">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-16">
              <div
                class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
              >
                <div>
                  <CheckIcon
                    class="absolute left-2 top-2.5 text-white w-16 h-16"
                  />
                </div>
              </div>
              <div class="mt-6 text-xl leading-8 text-theme-32">
                {{ successText }}
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Success-->

    <!--Start Modal Error Do not have user to send certificate-->
    <div
      id="nohave-user-to-send-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 mt-40 mx-14 rounded-lg">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-8">
              <div>
                <MailIcon class="text-theme-1 inline-block w-24 h-20" />
              </div>
              <div class="mt-1 text-xl font-medium leading-8 text-theme-32">
                ไม่มีรายชื่อสำหรับการส่งใบประกาศนียบัตร
              </div>
              <div class="mt-0 mx-12 text-sm font-normal text-theme-32">
                ไม่สามารถส่งใบประกาศนียบัตรได้
                เนื่องจากไม่มีรายชื่อที่ต้องส่ง<br />หรือส่งครบแล้ว
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
          <div class="flex justify-center w-full pb-10 pt-6">
            <button
              type="button"
              data-dismiss="modal"
              class="w-24 h-10 mr-2 btn btn-primary"
            >
              เข้าใจแล้ว
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--End Modal Success-->

    <div class="modal" tabindex="-1" aria-hidden="true">
      <div id="error-notification-content" class="modal-dialog">
        <div class="modal-content h-72 mt-46 rounded-lg">
          <div class="text-center">
            <div class="m-0 pt-14 font-prompt">
              <div class="flex justify-center items-center">
                <AlertCircleIcon class="text-red-500 w-16 h-16" />
              </div>
              <div class="font-medium text-xl mt-5">ผิดพลาด !!</div>
              <div
                class="mt-5 text-xl font-medium leading-8 text-theme-32 px-12"
              >
                {{ errorTxt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  nextTick,
  reactive,
  onMounted,
  watch,
  computed
} from "vue";
import DropAnImage from "./drop-cert-image/Main";
import * as certificateService from "/src/services/certificate";
import Toastify from "toastify-js";
import { useRoute } from "vue-router";
import * as emailService from "/src/services/email.js";
export default defineComponent({
  components: {
    DropAnImage
  },

  setup() {
    const route = useRoute();
    const certificateImages = ref(null);
    const certificateId = ref(null);
    const isEdit = ref(true);
    const activityCertData = reactive({
      data: null
    });
    const loadPage = ref(false);
    const isFirstTimeUpload = ref(false);
    const successText = ref("");
    const errorTxt = ref("");
    const folderNameImage = ref("certificate");
    const EMAIL_TYPE = {
      ANNOUNCE: "announcement",
      EXAMPLE: "admin",
      CERT: "certificate"
    };
    const state = reactive({ publicPath: process.env.BASE_URL });
    const isFile = value => {
      return value instanceof File;
    };
    const isDisabled = computed(() => {
      return isFirstTimeUpload.value;
    });
    watch(
      () => certificateImages.value,
      newVal => {
        if (isFile(newVal)) {
          isFirstTimeUpload.value = false;
          certificateId.value = newVal.id;
        }
        if (!newVal) {
          isFirstTimeUpload.value = true;
        }
      }
    );
    const fetchActivityCert = async () => {
      loadPage.value = true;
      try {
        const res = await certificateService.getActivityCert(route.params.id);
        const { item } = res.data.data;
        activityCertData.data = item;
        certificateImages.value = item.url;
        certificateId.value = item.id;
      } catch (error) {
        isFirstTimeUpload.value = true;
      } finally {
        loadPage.value = false;
      }
    };

    const onCreate = async () => {
      if (
        !isFirstTimeUpload.value &&
        !certificateImages.value.id &&
        activityCertData.data.id === certificateId.value
      ) {
        return;
      }

      loadPage.value = true;
      try {
        const payload = {
          certificate_id: certificateImages.value.id
        };
        await certificateService.updateActivityCert(route.params.id, payload);
        fetchActivityCert();
        successText.value = "บันทึกใบประกาศนียบัตรสำเร็จ";
        cash("#success-content-modal").modal("show");
        setTimeout(() => {
          cash(`#success-content-modal`).modal("hide");
        }, 1500);
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        loadPage.value = false;
      }
    };

    const onClickSendCert = () => {
      cash("#send-cert-modal").modal("show");
    };

    const onSendCertToUser = async () => {
      try {
        if (!certificateImages.value) {
          cash("#not-have-cert-modal").modal("show");
          return;
        }
        if (activityCertData.data.id !== certificateId.value) {
          cash("#need-update-cert-modal").modal("show");
          return;
        }
        await emailService.sendEmailToUser(route.params.id, EMAIL_TYPE.CERT);
        successText.value = "ส่งอีเมลสำเร็จ";
        cash(`#success-content-modal`).modal("show");
        setTimeout(() => {
          cash(`#success-content-modal`).modal("hide");
        }, 1500);
      } catch (error) {
        cash(`#nohave-user-to-send-modal`).modal("show");
      }
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 1000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    onMounted(() => {
      fetchActivityCert();
    });

    return {
      isEdit,
      isDisabled,
      loadPage,
      onCreate,
      errorTxt,
      successText,
      fetchActivityCert,
      certificateId,
      certificateImages,
      activityCertData,
      folderNameImage,
      errorNotificationToggle,
      onClickSendCert,
      onSendCertToUser,
      state
    };
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/sass/main.scss";
</style>
