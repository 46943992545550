<template>
  <div class="mx-2">
    <div class="mt-0 pt-6 text-2xl flex font-normal intro-y">
      <a class="cursor-pointer" @click="$router.go(-1)">
        <ChevronLeftIcon class="w-6 h-6 inline-block" />ตั้งค่าอีเมล
      </a>
    </div>
    <div class="grid grid-cols-2 pb-3 gap-4">
      <div class="col-start-1 col-end-3 flex items-end text-lg">
        ประวัติการส่งอีเมล
      </div>

      <div class="col-end-7 col-span-2 flex">
        <a class="cursor-pointer" @click="$router.go(-1)">
          <button
            class="w-full h-full text-sm font-normal shadow-md btn btn-primary"
          >
            ตั้งค่า
          </button></a
        >
      </div>
    </div>

    <!--Start Table-->
    <div class="col-span-12 overflow-auto intro-y lg:overflow-visible">
      <table class="table table-report">
        <thead>
          <tr>
            <th class="whitespace-nowrap w-48 text-left">
              วันและเวลาที่ส่ง
            </th>
            <th class="whitespace-nowrap text-left grow">
              จำนวนที่ส่ง
            </th>
            <th class="text-center whitespace-nowrap w-44">รายละเอียด</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(email, index) in emails"
            :key="index"
            class="intro-x max-h-14"
          >
            <td class="">{{ getDateFormat(email.created_at) }}</td>
            <td class="">{{ email.amount }} ฉบับ</td>
            <td class="text-center">
              <EyeIcon
                class="w-4 cursor-pointer inline-block"
                @click="onViewEmailDetail"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--End Table-->

    <div
      id="email-detail-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="mx-auto mt-8 w-1/2">
        <div class="modal-content">
          <button
            type="button"
            class="v-dialog-close"
            aria-label="Close"
            data-dismiss="modal"
          >
            <span class="v-btn__content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#5E5873"
                class="h-6 w-6 m-0"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
          <div class="pt-16 pb-36 modal-body px-28">
            <div
              class="text-lg pb-3 font-extrabold"
              v-html="mail_subject"
            ></div>
            <div v-html="mail_body"></div>
          </div>
        </div>
      </div>
    </div>
    <!--End Modal onViewDetail-->

    <div class="modal" tabindex="-1" aria-hidden="true">
      <div id="error-notification-content" class="modal-dialog">
        <div class="modal-content h-72 mt-40 rounded-lg">
          <div class="text-center">
            <div class="m-0 pt-14 font-prompt">
              <div class="flex justify-center items-center">
                <AlertCircleIcon class="text-red-500 w-16 h-16" />
              </div>
              <div class="font-medium text-xl mt-5">ผิดพลาด !!</div>
              <div
                class="mt-5 text-xl font-medium leading-8 text-theme-32 px-12"
              >
                {{ errorTxt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Modal Error-->
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  nextTick
} from "vue";
import { useRoute } from "vue-router";
import * as getheringService from "/src/services/gethering";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import Toastify from "toastify-js";

export default defineComponent({
  setup() {
    const route = useRoute();
    const states = reactive({
      mail_subject: "",
      mail_body: ""
    });
    const emails = ref([]);
    const errorTxt = ref("");

    const onViewEmailDetail = async () => {
      try {
        const res = await getheringService.getEmailDetailByActivityId(
          route.params.id
        );
        states.mail_subject = res.data.data.item.mail_subject;
        states.mail_body = res.data.data.item.mail_body;
        cash("#email-detail-modal").modal("show");
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const getDateFormat = date => {
      return dayjs(date).format("DD/MM/YYYY, h:mm");
    };

    const mail_subject = computed(() =>
      DOMPurify.sanitize(states.mail_subject)
    );

    const mail_body = computed(() => DOMPurify.sanitize(states.mail_body));

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          gravity: "center",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    const fetchEmailsHistory = async () => {
      try {
        const history = await getheringService.getHistoryEmails(
          route.params.id
        );
        emails.value = history.data.data.items;
      } catch (error) {
        throw new Error("Fetch emails history fail");
      }
    };

    onMounted(() => {
      fetchEmailsHistory();
    });

    return {
      onViewEmailDetail,
      fetchEmailsHistory,
      route,
      emails,
      getDateFormat,
      states,
      mail_subject,
      mail_body,
      errorNotificationToggle,
      errorTxt
    };
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/sass/main.scss";

.v-dialog-close {
  display: grid;
  position: absolute;
  z-index: 1;
  border-radius: 0.375rem;
  background-color: white !important;
  block-size: 2rem;
  inset-block-start: 0;
  inset-inline-end: 0;
  transform: translate(0.5rem, -0.5rem);
  width: 34px;
  height: 34px;
  box-shadow: 0px 3px 8px rgba(167, 174, 181, 0.4);
}
.v-btn__content {
  grid-area: content;
  justify-content: center;
  white-space: nowrap;
}
</style>
