<template>
  <div class="flex items-center">
    <div class="chip-box bg-primary-1 rounded text-white flex items-center">
      {{ chip.text }}
      <button class="delete-chip-btn" @click.prevent="removeChip">
        <XIcon class="w-4 w-4" />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    chip: {
      default: null,
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {
    removeChip() {
      this.$emit("chipRemoved", this.chip);
    }
  }
};
</script>
<style lang="scss">
.chip-box {
  height: 28px;
  padding: 4px;
  padding-left: 10px;
  margin-right: 5px;
}
.delete-chip-btn {
  width: 22px;
  height: 22px;
  border-radius: 6px;
  background: #d57012;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #a65b15;
  }
}
</style>
