<template>
  <div>
    <div class="container sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <!-- BEGIN: Login Info -->
        <div class="flex-col hidden min-h-screen xl:flex">
          <div class="my-auto">
            <img
              alt="Midone Tailwind HTML Admin Template"
              class="w-1/2 -mt-16 -intro-x"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="mt-10 text-4xl font-medium leading-tight text-white -intro-x"
            >
              Cheevamitr Admin <br />
            </div>
            <div
              class="mt-5 text-lg text-white -intro-x text-opacity-70 dark:text-gray-500"
            >
              Manage all your Cheevamitr Application
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0">
          <div
            class="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-dark-1 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left"
            >
              Sign In
            </h2>
            <div class="mt-8 intro-x">
              <input
                v-model="loginForm.username"
                type="text"
                class="block px-4 py-3 border-gray-300 intro-x login__input form-control"
                placeholder="Username"
                @keydown.enter="doLogin"
              />
              <input
                v-model="loginForm.password"
                type="password"
                class="block px-4 py-3 mt-4 border-gray-300 intro-x login__input form-control"
                placeholder="Password"
                @keydown.enter="doLogin"
              />
            </div>
            <div
              class="flex mt-4 text-xs text-gray-700 intro-x dark:text-gray-600 sm:text-sm"
            ></div>
            <div class="mt-5 text-center intro-x xl:mt-8 xl:text-center">
              <button
                class="w-full px-4 py-3 align-top btn btn-primary xl:w-32 xl:mr-3"
                @click="doLogin"
              >
                เข้าสู่ระบบ
                <LoadingIcon
                  v-if="loadPage"
                  icon="oval"
                  color="white"
                  class="w-4 h-4 ml-2"
                />
              </button>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
        <!-- BEGIN: Notification Content -->
        <div id="error-notification-content" class="flex toastify-content">
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">ผิดผลาด !!</div>
            <div class="mt-1 text-gray-600">
              {{ notiText }}
            </div>
          </div>
        </div>
        <!-- END: Notification Content -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, nextTick } from "vue";
import cookie from "js-cookie";
import { noAccess, apiApp } from "/src/config/api";
import Toastify from "toastify-js";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const loadPage = ref(false);
    const loginForm = ref({ username: "", password: "" });
    const notiText = ref("");
    const router = useRouter();

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });

    // Success notification
    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const doLogin = () => {
      loadPage.value = true;
      noAccess
        .post("admin/login", loginForm.value)
        .then(res => {
          if (!res.data.error) {
            loadPage.value = false;
            cookie.set("access_token", res.data.data.token, {
              path: "/",
              expires: 1
            });
            apiApp.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.data.token}`;
            router.push({
              name: "side-menu-dashboard-overview-1"
            });
          } else {
            loadPage.value = false;
            notiText.value = "Sorry, someting wrong";
            errorNotificationToggle();
          }
        })
        .catch(err => {
          if (err.response) {
            loadPage.value = false;
            notiText.value = err.response.data.error;
            errorNotificationToggle();
          }
        });
    };

    return {
      errorNotificationToggle,
      notiText,
      doLogin,
      loadPage,
      loginForm,
      router
    };
  }
});
</script>
