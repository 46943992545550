<template>
  <div>
    <FormCreateEdit />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import FormCreateEdit from "../../components/views/article/FormCreateEdit.vue";
export default defineComponent({
  components: {
    FormCreateEdit
  },
  setup() {}
});
</script>
