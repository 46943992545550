<template>
  <div class="items-center mt-8 intro-y lg:flex-row ">
    <div class="my-2 text-xl font-bold">
      {{ activityName }}
    </div>
    <div class="flex justify-between mt-8">
      <div class="my-2 text-lg font-bold">
        แบบทดสอบ
      </div>
      <div class="flex">
        <a
          class="flex items-center mr-3 "
          href="javascript:;"
          data-toggle="modal"
          data-target="#test-modal"
        >
          <button class="ml-4 shadow-md btn btn-primary">
            เพิ่มแบบทดสอบ
          </button>
        </a>
        <a
          class="ml-4 shadow-md btn btn-primary"
          href="javascript:;"
          data-toggle="modal"
          data-target="#import-modal"
        >
          นำเข้าข้อมูล
        </a>
      </div>
    </div>
    <div class="col-span-12 mt-4 overflow-auto intro-y lg:overflow-visible">
      <table class="table -mt-2 table-report">
        <thead>
          <tr>
            <th class="whitespace-nowrap text-center w-48">ประเภทไฟล์</th>
            <th class="whitespace-nowrap ">ชื่อไฟล์</th>
            <th class="whitespace-nowrap w-20">จำนวนข้อมูล</th>
            <th class="w-32 whitespace-nowrap">วันที่อัพเดทข้อมูลล่าสุด</th>
            <th class="w-20 whitespace-nowrap">จัดการ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tests" :key="item" class="intro-x">
            <td class="truncate text-center">
              {{ item.name }}
            </td>
            <td class="truncate max-w-xl">
              {{ item.file_name }}
            </td>
            <td class="text-center">
              {{ item.amount_row }}
            </td>
            <td class="text-center">
              {{ $h.formatDate(item.uploaded_at, "DD/MM/YYYY, HH:mm") }}
            </td>
            <td class="flex justify-end text-center">
              <div v-if="item.uploaded_at" class="flex">
                <LoadingIcon
                  v-if="isLoadingExport"
                  icon="oval"
                  color="black"
                  class="w-4 mr-1"
                />
                <DownloadIcon
                  v-else
                  class="w-4 cursor-pointer mr-2"
                  @click="onDownloadFile(item.id)"
                />
                <div
                  class="flex items-center mr-1 cursor-pointer"
                  @click="onEditTest(item)"
                >
                  <EditIcon class="w-4 h-4 " />
                </div>
              </div>
              <a
                class="flex items-center w-4 h-4 my-1"
                href="javascript:;"
                data-toggle="modal"
                data-target="#delete-confirmation-modal"
                @click="onConfirmDeleteTest(item)"
              >
                <Trash2Icon />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      id="test-modal"
      class="modal font-kanit"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="mr-auto text-base font-medium">
              <b v-if="isEdit">แก้ไขแบบทดสอบ</b>
              <b v-else>เพิ่มแบบทดสอบ</b>
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3">
            <div class="col-span-12 sm:col-span-12">
              <label for="modal-form-1" class="form-label"
                ><b>ชื่อแบบทดสอบ <span class="text-red-500">*</span></b></label
              >
              <input
                id="modal-form-5"
                v-model="testName"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="text-left modal-footer">
            <button
              type="button"
              class="w-20 mr-2 btn btn-primary"
              @click="handleName()"
            >
              ตกลง
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
              @click="onModalClose()"
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
        <!-- END: Notification Content -->
      </div>
    </div>

    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <Trash2Icon class="w-16 h-16 mx-auto mt-3 " />
              <div class="mt-5 text-2xl">ลบรายการ ?</div>
              <div class="mt-2 text-gray-700 text-md">
                คุณต้องการลบรายการนี้, ใช่หรือไม่ ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" class="mr-4 btn btn-danger w-28">
                <div v-if="!pageLoad" @click="confirmDeleteEvent()">
                  ใช่, ลบรายการ
                </div>
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 btn btn-outline-secondary"
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
    <!-- BEGIN: Add/Edit Content Modal -->
    <div
      id="import-modal"
      class="modal font-kanit"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="w-1/3 mx-auto mt-8">
        <div class="modal-content">
          <div class="flex items-center justify-between p-2 px-4 bg-gray-200">
            <div>นำเข้าข้อมูล</div>
            <div>
              <XIcon @click="onImportModalClose" />
            </div>
          </div>
          <div class="p-4 modal-body ">
            <label class="required">ข้อมูล </label>
            <div class="dropdown mt-4">
              <div>
                <Multiselect
                  v-model="selectedTest"
                  :options="tests"
                  :searchable="true"
                  label="name"
                  valueProp="id"
                  :classes="multiSelectStyle"
                >
                </Multiselect>
              </div>
            </div>
            <div class="mt-4">
              <label class="required ">เลือกไฟล์</label>
              <div class="w-full">
                <div class="input-file-upload-wrapper relative truncate">
                  <input
                    id="upload"
                    name="file-upload-field"
                    type="file"
                    class="file-upload-field z-50"
                    @change="onBrowse"
                  />
                  <div class="text-file-name truncate">
                    {{ file?.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-2" />
          <div class="w-4 h-4 ml-2"></div>
          <div class="p-4">
            <button
              class="btn btn-primary w-28"
              :disabled="isLoading"
              @click="onSubmitForm"
            >
              <LoadingIcon
                icon="oval"
                color="white"
                v-if="isLoading"
                class="w-4 mr-4"
              />
              <span>ตกลง</span>
            </button>
            <button
              class="ml-4 btn w-28"
              @click="onImportModalClose"
              :disabled="isLoading"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Notification Content -->
    <div id="success-notification-content" class="flex hidden toastify-content">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">สำเร็จ !!</div>
        <div class="mt-1 text-gray-600">
          {{ successTxt }}
        </div>
      </div>
    </div>
    <div id="error-notification-content" class="flex hidden toastify-content">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          {{ errorTxt }}
        </div>
      </div>
    </div>
    <!-- END: Notification Content -->
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, nextTick } from "vue";
import Toastify from "toastify-js";
import dayjs from "dayjs";
import * as getheringService from "/src/services/gethering";
import { helper as $h } from "@/utils/helper";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const tests = ref([]);
    const detailEventID = ref(null);
    const deleteEvent = ref(null);
    const errorTxt = ref("");
    const successTxt = ref("");
    const activityName = ref("");
    const testName = ref("");
    const test = ref();
    const isEdit = ref(false);
    const isLoading = ref(false);
    const isLoadingExport = ref(false);
    const selectedTest = ref();
    const multiSelectStyle = ref({
      containerActive: "ring ring-primary-6 ring-opacity-30",
      optionSelected: "text-white bg-primary-1",
      optionSelectedPointed: "text-white bg-primary-1 opacity-90"
    });
    const file = ref(null);

    onMounted(() => {
      fetchTests();
    });

    const fetchTests = async () => {
      const res = await getheringService.getActivityTests(
        route.params.lessonId
      );
      activityName.value = res.data.data.activity_name;
      tests.value = res.data.data.tests;
    };

    const getDateFormat = date => {
      return dayjs(date).format("DD/MM/YYYY, HH:mm");
    };
    const setSeeDetailEvent = id => {
      detailEventID.value = id;
    };
    const onBrowse = event => {
      file.value = event.target.files[0];
    };
    const setDeleteEvent = (event, index) => {
      event.index = index;
      deleteEvent.value = { ...event };
    };

    const handleName = async () => {
      if (!testName.value) {
        errorTxt.value = "กรุณากรอกชื่อแบบทดสอบ";
        errorNotificationToggle();
        return;
      }
      const data = { name: testName.value };
      try {
        if (isEdit.value) {
          await getheringService.updateActivityTest(test.value.id, data);
        } else {
          await getheringService.createActivityTest(
            route.params.lessonId,
            data
          );
        }
        fetchTests();
        onModalClose();
      } catch {
        errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const onModalClose = () => {
      cash("#test-modal").modal("hide");
      testName.value = "";
      isEdit.value = false;
    };

    const confirmDeleteEvent = async () => {
      try {
        await getheringService.deleteActivityTest(test.value.id);
        successTxt.value = "ลบแบบทดสอบสำเร็จ";
        successNotificationToggle();
        fetchTests();
      } catch {
        errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        cash("#delete-confirmation-modal").modal("hide");
      }

      getheringService
        .deleteGathering(deleteEvent.value.id)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          successTxt.value = "ลบแบบทดสอบเรียบร้อยแล้ว";
          cash("#delete-confirmation-modal").modal("hide");
          successNotificationToggle();
          fetchTests();
        })
        .catch(() => {
          errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
          errorNotificationToggle();
        });
    };

    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const onDownloadFile = async testId => {
      isLoadingExport.value = true;
      try {
        const res = await getheringService.downloadActivityTestFile(testId);
        $h.downloadFile(`${testId}_data.xlsx`, res.data);
      } finally {
        isLoadingExport.value = false;
      }
    };

    const onConfirmDeleteTest = item => {
      test.value = item;
    };

    const onImportModalClose = () => {
      cash("#import-modal").modal("hide");
    };

    const onSubmitForm = async () => {
      isLoading.value = true;
      try {
        const formData = new FormData();
        formData.append("file", file.value);
        const res = await getheringService.uploadActivityTestFile(
          selectedTest.value,
          formData
        );

        if (res?.data?.data?.errors?.code === 409) {
          errorTxt.value = res.data.data.errors.message;
          errorNotificationToggle();
          return;
        }

        successTxt.value = "นำเข้าข้อมูลสำเร็จ";
        successNotificationToggle();
        fetchTests();
        onImportModalClose();
      } catch {
        errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        isLoading.value = false;
      }
    };

    const onEditTest = item => {
      isEdit.value = true;
      test.value = item;
      testName.value = item.name;
      cash("#test-modal").modal("show");
    };

    return {
      tests,
      errorTxt,
      successTxt,
      getDateFormat,
      setSeeDetailEvent,
      onBrowse,
      fetchTests,
      deleteEvent,
      setDeleteEvent,
      confirmDeleteEvent,
      multiSelectStyle,
      activityName,
      handleName,
      testName,
      onDownloadFile,
      isLoading,
      isLoadingExport,
      onConfirmDeleteTest,
      onImportModalClose,
      selectedTest,
      file,
      onSubmitForm,
      route,
      onEditTest,
      isEdit
    };
  }
});
</script>
<style lang="scss" scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-group {
  @apply flex flex-col space-y-1 mb-4;
}
label.required::after {
  content: " *";
  color: red;
}
.dropdown-menu.show {
  z-index: 11111;
}
</style>
