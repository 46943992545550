<template>
  <div class="items-center mt-8 intro-y lg:flex-row ">
    <div class="mb-2 text-lg font-bold">
      โครงการห้องเรียนชีวิต "วิชาเพื่อนตาย" ตอน "สื่อสาร สร้างสรรค์"
      งานอบรมเชิงปฏิบัติการ - Onlin...ing
    </div>
    <div class="flex">
      <div class="w-1/2">
        <div class="flex w-full font-medium">
          <input
            id="tabulator-html-filter-value"
            v-model="searchContent"
            type="text"
            class="w-full form-control"
            placeholder="ค้นหากิจกรรม"
            @keyup.enter="onSearch()"
          />
          <button class="ml-4 btn btn-primary" @click="onSearch()">
            ค้นหา
          </button>
        </div>
      </div>
    </div>
    <div class="col-span-12 mt-4 overflow-auto intro-y lg:overflow-visible">
      <table class="table -mt-2 table-report">
        <thead>
          <tr>
            <th class="whitespace-nowrap ">รหัสการลงทะเบียน</th>
            <th class="text-center whitespace-nowrap">คำนำหน้าชื่อ</th>
            <th class="whitespace-nowrap">ชื่อและนามสกุล</th>
            <th class="text-center whitespace-nowrap">ชื่อเล่น</th>
            <th class="text-center whitespace-nowrap">เพศ</th>
            <th class="text-center whitespace-nowrap">วันเกิด</th>
            <th class="text-center whitespace-nowrap">อายุ</th>
            <th class="text-center whitespace-nowrap">เบอร์โทรศัพท์</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" :key="item" class="intro-x">
            <td class="">
              {{ item.register_code }}
            </td>
            <td class="text-center">
              {{ item.prefix }}
            </td>
            <td class="">
              {{ item.full_name }}
            </td>
            <td class="text-center">
              {{ item.nick_name }}
            </td>
            <td class="text-center">
              {{ item.gender }}
            </td>
            <td class="text-center">
              {{ getDateFormat(item.update_at) }}
            </td>
            <td class="text-center">{{ item.age }}</td>
            <td class="text-center">
              {{ item.phone_number }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="flex flex-wrap items-center col-span-12 mt-4 intro-y sm:flex-row sm:flex-nowrap"
    >
      <ul class="pagination">
        <li>
          <a class="pagination__link" @click="paginationSelect(1)">
            <ChevronsLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="paginationSelect(pageSelect == 1 ? 1 : pageSelect - 1)"
          >
            <ChevronLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li v-for="page in pages" :key="page" @click="paginationSelect(page)">
          <a
            v-if="pageSelect == page"
            class="pagination__link pagination__link--active"
            >{{ page }}</a
          >
          <a v-else class="pagination__link">{{ page }}</a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="
              paginationSelect(
                pageSelect == totalPages ? totalPages : pageSelect + 1
              )
            "
          >
            <ChevronRightIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a class="pagination__link" @click="paginationSelect(totalPages)">
            <ChevronsRightIcon class="w-4 h-4" />
          </a>
        </li>
      </ul>
      <select
        v-model="pageSizeSelect"
        class="w-20 mt-3 form-select box sm:mt-0"
        @change="onSearch()"
      >
        <option v-for="page in pageSize" :key="page" :value="page">
          {{ page }}
        </option>
      </select>
    </div>
    <div
      id="detail-registed-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="w-1/3 mx-auto mt-8">
        <div class="modal-content">
          <div class="flex items-center justify-between p-2 px-4 bg-gray-200">
            <div>นำเข้าข้อมูล</div>
            <div>
              <XIcon />
            </div>
          </div>
          <div class="p-4 modal-body intro-x">
            <label class="require">ข้อมูล</label>
            <div class="flex">
              <div class="mt-2 mr-6 form-check">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="vertical_radio_button"
                  value="vertical-radio-chris-evans"
                />
                <label class="form-check-label" for="radio-switch-1"
                  >อัพเดทไฟล์เดิม</label
                >
              </div>
              <div class="mt-2 form-check">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="vertical_radio_button"
                  value="vertical-radio-liam-neeson"
                />
                <label class="form-check-label" for="radio-switch-2"
                  >สร้างใหม่</label
                >
              </div>
            </div>
            <div class="mt-4 dropdown">
              <button
                class="flex justify-between w-full dropdown-toggle btn btn-outline-secondary"
                aria-expanded="false"
              >
                <!-- {{ selectedStatus.name }} -->
                {{ "เลือกหัวข้อ" }}
                <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
              </button>
              <!-- <div class="w-40 dropdown-menu">
                <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                  <a
                    v-for="status in eventStatus"
                    :key="status.key"
                    href="javascript:;"
                    data-dismiss="dropdown"
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                    @click="selectFilterByStaus(status)"
                  >
                    {{ status.name }}
                  </a>
                </div>
              </div> -->
            </div>
            <div class="mt-2 input-group">
              <input
                type="file"
                aria-label="File browser example"
                class="w-full input-group-text"
                placeholder="เลือกไฟล์"
              />
            </div>
          </div>
          <hr class="my-2" />
          <div class="p-4">
            <button class="shadow-md btn btn-primary w-30">
              ตกลง
            </button>
            <button class="ml-4 shadow-md btn w-30">
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import dayjs from "dayjs";
import * as getheringService from "/src/services/gethering";
import { helper as $h } from "@/utils/helper";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const formList = ref([
      {
        register_code: "AAA",
        date_of_birth: new Date(),
        prefix: "นาย",
        first_name: "AAAAA",
        last_name: "BBBB",
        nick_name: "OOO",
        gender: "male",
        location: "north",
        age_range: "25 - 30",
        phone_number: "0900000000",
        id: 1
      }
    ]);
    const searchContent = ref("");
    const pageSize = ref([10, 20, 30, 40]);
    const pageSizeSelect = ref(10);
    const totalPages = ref(0);
    const pageSelect = ref(1);
    const pages = ref([1]);
    const detailEventID = ref(null);
    const errorTxt = ref("");
    const successTxt = ref("");
    const list = ref([]);
    const params = {};
    onMounted(() => {
      fetchTestForms();
      console.log(`🚨 ~ onMounted ~ route`, route);
    });
    const fetchTestForms = async () => {
      params.search = searchContent.value ? searchContent.value : undefined;
      params.size = pageSizeSelect.value;
      params.page = pageSelect.value;

      const res = await getheringService.getActivityTestForms(
        route.params.exampleId,
        params
      );
      list.value = res.data.data.forms;

      const pageResult = $h.calculatePagination({
        totalItems: res.data.data.lastPage * pageSizeSelect.value,
        pageSize: pageSizeSelect.value,
        currentPage: pageSelect.value
      });
      pages.value = pageResult.pages;
      totalPages.value = pageResult.totalPages;
    };
    const onSearch = () => {
      pageSelect.value = 1;
      fetchTestForms();
    };
    const paginationSelect = page => {
      pageSelect.value = page;
      fetchTestForms();
    };
    const getDateFormat = date => {
      return dayjs(date).format("DD/MM/YYYY");
    };
    const setSeeDetailEvent = id => {
      detailEventID.value = id;
    };
    return {
      formList,
      searchContent,
      pageSize,
      pageSizeSelect,
      totalPages,
      pageSelect,
      errorTxt,
      successTxt,
      onSearch,
      paginationSelect,
      getDateFormat,
      setSeeDetailEvent,
      pages,
      fetchTestForms,
      params,
      list
    };
  }
});
</script>
<style lang="scss" scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
