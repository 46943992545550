<template>
  <div class="relative" tabindex="0" @blur="hideSelector">
    <div
      class="form-control chip-input bg-white flex flex-wrap rounded px-2 focus:utline-offset-2 cursor-pointer"
      @click="showSector = true"
    >
      <chip
        v-for="selectedChip in selectedChips"
        :key="selectedChip.value"
        :chip="selectedChip"
        @chipRemoved="removeChip"
        class="chip-item"
      />
    </div>
    <div
      v-if="showSector"
      class="chip-selector absolute w-full z-10 rounded bg-white overflow-y-scroll shadow-xl"
    >
      <ul class="p-0 block p-2">
        <li
          v-for="item in selectList"
          :key="item.value"
          class="chip-option block p-2 hover:bg-gray-200 hover:ocacity-90 rounded px-2 py-2 cursor-pointer"
          @click="setSelectedChip(item)"
        >
          <div>{{ item.text }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Chip from "./Chip";
export default {
  components: {
    chip: Chip
  },
  props: {
    selected: Array,
    selectList: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      selectedChips: [],
      showSector: false
    };
  },
  watch: {
    selected(value) {
      this.selectedChips = JSON.parse(JSON.stringify(value));
    }
  },
  methods: {
    setSelectedChip(chip) {
      if (
        !this.selectedChips.find(
          selectedChip => selectedChip.value == chip.value
        )
      ) {
        this.selectedChips.push(chip);
        this.$emit("update:modelValue", this.selectedChips);
      }
    },
    setFocus() {},
    removeChip(chip) {
      this.selectedChips = this.selectedChips.filter(
        selectedChip => selectedChip.value !== chip.value
      );
      this.$emit("update:modelValue", this.selectedChips);
    },
    hideSelector() {
      this.showSector = false;
    }
  }
};
</script>
<style lang="scss">
.chip-item {
  white-space: nowrap;
  margin: 3px;
}
.chip-input {
  height: auto;
  white-space: wrap;
  min-height: 40px;
}
.chip-selector {
  max-height: 320px;
}
</style>
