<template>
  <div>
    <div class="flex flex-col items-center mt-8 intro-y sm:flex-row">
      <div class="flex items-center w-full mr-auto text-lg font-medium">
        จัดการลำดับ Highlight
      </div>
    </div>
    <div
      class="flex items-center justify-between mt-4 mb-4 intro-y sm:flex-row"
    ></div>
    <div class="table intro-y">
      <draggable
        v-model="articles"
        item-key="no"
        v-bind="dragOptions"
        @change="onMoveCallback"
        @start="drag = true"
        @end="drag = false"
      >
        <template #item="{element, index}">
          <div
            class="inline-block text-gray-700 bg-white cursor-move inbox__item sm:block dark:text-gray-500"
          >
            <div class="flex px-5 py-3 my-2 space-x-3">
              <MenuIcon class="w-5 h-5" />
              <div class="mx-4">{{ index + 1 }}</div>
              <div>{{ element.title }}</div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import draggable from "vuedraggable";
import * as articleService from "/src/services/article";
export default defineComponent({
  components: {
    draggable
  },
  setup() {
    const dragOptions = {
      animation: 300,
      disabled: false,
      ghostClass: "ghost"
    };
    const articles = ref([]);

    const onMoveCallback = () => {
      let orders = [];
      articles.value.map((item, index) => {
        item.no = index + 1;
        orders.push(item.id);
      });
      articleService
        .orderArticle(orders)
        .then(() => {})
        .catch(() => {});
    };

    onMounted(() => {
      getArticles();
    });
    const getArticles = () => {
      articleService
        .getListByOrder()
        .then(res => {
          articles.value = res.data.data;
        })
        .catch(() => {});
    };
    return {
      dragOptions,
      articles,
      onMoveCallback
    };
  }
});
</script>

<style lang="scss" scoped>
.ghost {
  @apply bg-gray-300;
  @apply text-gray-500;
}
</style>
