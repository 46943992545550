<template>
  <div class="w-full">
    <label
      id="drag"
      class="flex items-center justify-center w-full transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer drop hover:border-gray-400 focus:outline-none"
      :class="getClasses"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
    >
      <img v-if="imageSource" class="img-preview" :src="imageSource" />
      <div v-if="!imageSource" class="flex flex-col space-y-2 text-theme-36">
        <div class="text-2xl text-center">
          Drop file here or click to upload
        </div>
        <div class="text-base text-center">
          {{ descLabel }}
        </div>
      </div>
      <input
        id="upload"
        ref="filePickerField"
        type="file"
        name="file_upload"
        class="hidden"
        @change="onBrowse"
      />
    </label>
    <div class="w-full py-4">
      <div class="flex justify-center w-full space-x-4">
        <button class="w-20 btn btn-primary" @click="onEdit">
          แก้ไข
        </button>
        <button class="w-20 btn border-dark-1" @click="removeSingleImage">
          ลบ
        </button>
      </div>
    </div>
    <!-- BEGIN: Add/Edit Content Modal -->
    <ImageCropperDialog
      ref="cropperDialog"
      :chosen-image="chosenImage"
      @uploadFile="uploadFile"
      @onHideModal="hideModal"
      @onReset="$refs.filePickerField.value = null"
      @onCrop="handleCropImage"
    />
    <!-- END: Add/Edit Content Modal -->
    <!--Start Error File Size Modal-->
    <div
      id="error-notification-upload-image"
      class="flex hidden toastify-content"
    >
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          กรุณาใช้รูปภาพขนาดต่ำกว่า 5MB
        </div>
      </div>
    </div>
    <!--End Error File Size Modal-->

    <!--Start Error File Size Modal-->
    <div id="fetch-upload-img-error" class="flex hidden toastify-content">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          อัพโหลดรูปภาพล้มเหลว
        </div>
      </div>
    </div>
    <!--End Error File Size Modal-->
  </div>
</template>

<script>
import ImageCropperDialog from "./ImageCropperDialog.vue";
import * as imageService from "/src/services/upload";
import { helper as $h } from "@/utils/helper";
import Toastify from "toastify-js";

export default {
  name: "Main",
  components: {
    ImageCropperDialog
  },
  props: {
    descLabel: {
      type: String,
      default:
        "(อัพโหลดรูปสำหรับใช้เป็นภาพปกของบทความ, ไฟล์รูปแนะนำ JPG , PNG ขนาด 16:9)"
    },
    folderNameImage: {
      type: String,
      default: ""
    },
    dataImage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isDragging: false,
      wrongFile: false,
      chosenImage: null,
      imageSource: this.dataImage ?? "",
      fileData: {}
    };
  },
  computed: {
    getClasses() {
      return { isDragging: this.isDragging };
    }
  },
  watch: {
    dataImage(value) {
      this.$nextTick(() => {
        if (value) {
          value = JSON.parse(JSON.stringify(value));
          if (value.image_url) {
            this.imageSource = value.image_url;
          } else {
            this.imageSource = value;
          }
        }
      });
    }
  },

  methods: {
    dragOver() {
      this.isDragging = true;
    },
    dragLeave() {
      this.isDragging = false;
    },
    async drop(e) {
      let files = e.dataTransfer.files;
      this.wrongFile = false;
      if (files.length === 1) {
        let file = files[0];
        const isFileSizeOver = file.size > 5000000;
        const isImageFile = file.type.indexOf("image/") >= 0;
        if (isFileSizeOver || !isImageFile) {
          this.wrongFile = false;
          this.imageSource = null;
          this.isDragging = false;
          file = null;
          this.errorNotificationToggle();
          return;
        }
        this.chosenImage = await this.toBase64(file);
        await this.$refs.cropperDialog.initCropper(file);
        cash(`#crop-image-modal`).modal("show");
      }
    },
    onBrowse() {
      var src = document.querySelector("#upload");
      this.drop({ dataTransfer: src });
    },
    async uploadFile(fileUpload) {
      var reader = new FileReader();
      reader.onload = f => {
        this.isDragging = false;
        this.onCallUrl(fileUpload, f);
      };
      reader.readAsDataURL(fileUpload);
    },
    async onCallUrl(file, item) {
      try {
        let setDataImage = {
          folder: this.folderNameImage,
          file_name: file.name
        };
        const res = await imageService.uploadImage(setDataImage);
        let result = res.data.data;
        await $h.uploadImageToStorage(result.urlUpload, file);
        this.imageSource = item.target.result;
        this.fileData = file;
        this.fileData.image_url = result.urlPublic;
        this.$emit("update:modelValue", this.fileData);
      } catch (error) {
        this.errorFetchUploadImageError();
      }
    },
    hideModal(isDragging) {
      this.isDragging = isDragging;
      cash(`#crop-image-modal`).modal("hide");
    },

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    removeSingleImage() {
      this.file = null;
      this.wrongFile = false;
      this.imageSource = null;
      this.fileData = null;
      this.$emit("update:modelValue", this.fileData);
    },
    handleCropImage(croppedImage) {
      this.imageSource = croppedImage;
    },
    onEdit() {
      document.querySelector("#upload").click();
    },
    errorNotificationToggle() {
      Toastify({
        node: cash("#error-notification-upload-image")
          .clone()
          .removeClass("hidden")[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true
      }).showToast();
    },
    errorFetchUploadImageError() {
      Toastify({
        node: cash("#fetch-upload-img-error")
          .clone()
          .removeClass("hidden")[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true
      }).showToast();
    }
  }
};
</script>

<style scoped>
.drop {
  width: 100%;
  height: 300px;
  transition: background-color 0.2s ease-in-out;
}
.isDragging {
  background-color: #999;
  border-color: #fff;
}
.img-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
