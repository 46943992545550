<template>
  <div class="mx-2">
    <div class="mt-0 pt-6 text-2xl flex font-normal intro-y">
      <router-link :to="`/gathering/edit/${currentActivity}?activeTab=2`">
        <a class="cursor-pointer">
          <ChevronLeftIcon class="w-6 h-6 inline-block" />ตั้งค่าอีเมล
        </a></router-link
      >
    </div>
    <div class="my-0 mt-5 w-full text-lg font-medium intro-y">
      รายละเอียดอีเมล
    </div>

    <div class="flex flex-col w-full intro-y">
      <div class="form-group">
        <div class="grid grid-cols-2 gap-4">
          <div class="col-start-1 col-end-3 flex items-end mb-2">
            <label class="text-xs required">หัวข้อ</label>
          </div>

          <!--Start Group Right Buttons-->
          <div class="col-end-7 col-span-2 w-40 mb-3">
            <!--Start Announce Lists Button Modal-->
            <a
              class="flex"
              href="javascript:;"
              data-toggle="modal"
              data-target="#announce-content-modal"
            >
              <router-link
                :to="{
                  name: 'emails-history'
                }"
              >
                <button class="ml-4 w-full h-10 shadow-md btn btn-primary">
                  ประวัติการส่งอีเมล
                </button>
              </router-link>
            </a>
          </div>
        </div>

        <input
          id="topic"
          v-model="v$.mail_subject.$model"
          type="text"
          class="w-full mt-4 form-control"
          :invalid="v$.mail_subject.$error"
        />
        <p v-if="v$.mail_subject.$error" class="field-error-noti mt-2">
          <span>
            โปรดระบุ
          </span>
        </p>
      </div>
      <div class="form-group mt-0 pt-6">
        <label class="text-xs required">เนื้อหาอีเมล</label>
        <Editor
          v-if="!isLoading"
          v-model="v$.mail_body.$model"
          :data-content="v$.mail_body.$model"
          class="editor mt-0 pt-2.5"
        ></Editor>
        <p v-if="v$.mail_body.$error" class="field-error-noti mt-2">
          <span>
            โปรดระบุ
          </span>
        </p>
      </div>
      <div class="flex py-8 space-x-3.5">
        <button class="btn btn-primary" @click="onDraftEmail">
          บันทึกการเปลี่ยนแปลง
        </button>
        <button
          class="bg-white btn btn-outline-secondary"
          @click="onSendExampleEmail"
        >
          ส่งอีเมลตัวอย่าง
        </button>
        <button
          class="btn bg-theme-32 text-white"
          :class="{ 'opacity-30': qualifiedAmount }"
          :disabled="qualifiedAmount"
          @click="onSendEmail"
        >
          ส่งอีเมล
        </button>
        <router-link :to="`/gathering/edit/${currentActivity}?activeTab=2`">
          <button class="bg-white btn">
            ยกเลิก
          </button>
        </router-link>
      </div>
    </div>

    <!--Start Save Success Modal-->
    <div
      id="save-success-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 mt-40 rounded-lg">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-16">
              <div
                class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
              >
                <div>
                  <CheckIcon
                    class="left-2.5 top-2.5 text-white w-16 h-16 absolute"
                  />
                </div>
              </div>
              <div class="mt-6 leading-5 text-xl font-medium text-theme-32">
                บันทึกการปรับแก้อีเมลสำเร็จ
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Save Success Modal-->

    <!--Modal Send Example Email-->
    <div
      id="success-send-example-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-16">
              <div
                class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
              >
                <div>
                  <CheckIcon
                    class="left-2 top-2.5 text-white w-16 h-16 absolute"
                  />
                </div>
              </div>
              <div class="mt-6 leading-5 text-sm text-theme-32">
                ระบบทำการส่งตัวอย่างรายละเอียดกิจกรรม<br />ไปยัง&nbsp;<strong>team@cheevamitr.com</strong>&nbsp;เรียบร้อยแล้ว
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send Example Email-->

    <!--Modal Send Email Success-->
    <div
      id="success-send-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-16">
              <div
                class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
              >
                <div>
                  <CheckIcon
                    class="left-2 top-2.5 text-white w-16 h-16 absolute"
                  />
                </div>
              </div>
              <div class="mt-6 leading-5 text-xl font-medium text-theme-32">
                ส่งอีเมลสำเร็จ
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send Example Email-->

    <!--Modal Send Email Success-->
    <div
      id="error-send-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-12">
              <div class="flex justify-center items-center">
                <AlertCircleIcon class="text-red-500 w-16 h-16" />
              </div>
              <div class="font-medium text-xl mt-4">ผิดพลาด !!</div>
              <div class="mt-0 leading-7 text-xl font-medium text-theme-32">
                ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่<br />อีกครั้ง
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send Example Email-->

    <!--Modal Send Real Email-->
    <div
      id="send-real-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 w-24 h-20 inline-block" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              ยืนยันการส่งรายละเอียดกิจกรรม ?
            </div>
            <div class="pt-2 leading-5 text-sm text-theme-32">
              คุณต้องการส่งอีเมลสำหรับการแจ้งรายละเอียดกิจกรรม<br />ไปยังผู้ผ่านการคัดเลือกทั้งหมด,
              ใช่หรือไม่ ?
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-36 h-10 mr-2 btn btn-primary"
              @click="onSendEmailToUser"
            >
              ยืนยันการส่งอีเมล
            </button>

            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send Real Email-->

    <div class="modal" tabindex="-1" aria-hidden="true">
      <div id="success-send-real-email" class="modal-dialog w-460">
        <div class="modal-content h-72 mt-40 rounded-lg">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-20">
              <div
                class="relative w-20 h-20 bg-theme-35 rounded-full inline-block"
              >
                <CheckIcon
                  class="absolute left-2 top-2.5 text-white w-16 h-16"
                />
              </div>
              <div
                class="mt-5 text-xl font-medium leading-8 font-prompt text-theme-32"
              >
                {{ successTxt }}
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Success-->

    <div class="modal" tabindex="-1" aria-hidden="true">
      <div id="error-notification-content" class="modal-dialog">
        <div class="modal-content h-72 mt-40 rounded-lg">
          <div class="text-center">
            <div class="m-0 pt-14 font-prompt">
              <div class="flex justify-center items-center">
                <AlertCircleIcon class="text-red-500 w-16 h-16" />
              </div>
              <div class="font-medium text-xl mt-4">ผิดพลาด !!</div>
              <div
                class="mt-0 text-xl font-medium leading-7 text-theme-32 px-12"
              >
                {{ errorTxt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Modal Error-->
  </div>
</template>
<script>
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  toRefs,
  computed,
  nextTick
} from "vue";
import Toastify from "toastify-js";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Editor from "@/global-components/quill-editor/Main.vue";
import * as emailService from "/src/services/email.js";
import * as getheringService from "/src/services/gethering";
import { useRoute } from "vue-router";

const EMAIL_TYPE = {
  ANNOUNCE: "announcement",
  EXAMPLE: "admin"
};

export default defineComponent({
  components: {
    Editor
  },
  setup() {
    const route = useRoute();
    const isLoading = ref(false);
    const emailFormData = reactive({ mail_body: "", mail_subject: "" });
    const qualifyAmount = ref(0);
    const NO_USER_QUALIFIED = 0;
    const errorTxt = ref("");
    const successTxt = ref("");
    const currentActivity = ref(route.params.id);
    const qualifiedAmount = computed(() => {
      return qualifyAmount.value <= NO_USER_QUALIFIED;
    });

    const isEmpty = htmlString => {
      const parser = new DOMParser();
      const { textContent } = parser.parseFromString(
        htmlString,
        "text/html"
      ).documentElement;

      return textContent.trim();
    };
    const rules = computed(() => ({
      mail_subject: { required },
      mail_body: { required, isEmpty }
    }));
    const v$ = useVuelidate(rules, toRefs(emailFormData));

    const onSendEmailToUser = async () => {
      try {
        await emailService.sendEmailToUser(
          route.params.id,
          EMAIL_TYPE.ANNOUNCE
        );
        cash(`#success-send-email-modal`).modal("show");
        setTimeout(() => {
          cash(`#success-send-email-modal`).modal("hide");
        }, 1500);
      } catch (error) {
        cash(`#error-send-email-modal`).modal("show");
        setTimeout(() => {
          cash(`#error-send-email-modal`).modal("hide");
        }, 1500);
      }
    };
    const onSendEmail = () => {
      cash("#send-real-email-modal").modal("show");
    };
    const onSendExampleEmail = async () => {
      try {
        await emailService.sendEmailToUser(route.params.id, EMAIL_TYPE.EXAMPLE);
        cash(`#success-send-example-email-modal`).modal("show");
        setTimeout(() => {
          cash(`#success-send-example-email-modal`).modal("hide");
        }, 1500);
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };
    const onDraftEmail = async () => {
      const isValidate = await v$.value.$validate();
      if (isValidate) {
        const payload = emailFormData;
        try {
          await emailService.sendDraftEmail(route.params.id, payload);
          successTxt.value = "บันทึกการปรับแก้อีเมลสำเร็จ";
          successNotificationToggle();
        } catch (error) {
          errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
          errorNotificationToggle();
        }
      }
    };
    const fetchActivityDraftEmail = async () => {
      isLoading.value = true;
      try {
        const res = await emailService.getDraftEmail(route.params.id);
        emailFormData.mail_subject = res.data.data.item.mail_subject;
        emailFormData.mail_body = res.data.data.item.mail_body;
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        isLoading.value = false;
      }
    };
    const fetchActivityDetail = async () => {
      try {
        const res = await getheringService.getQualifiedList(route.params.id);
        qualifyAmount.value = res.data.data.items.qualify_amount;
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-send-real-email")
            .clone()
            .removeClass("hidden")[0],
          duration: 1500,
          newWindow: true,
          gravity: "center",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 1500,
          newWindow: true,
          gravity: "center",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    onMounted(() => {
      fetchActivityDraftEmail();
      fetchActivityDetail();
    });

    return {
      v$,
      isEmpty,
      isLoading,
      qualifiedAmount,
      fetchActivityDraftEmail,
      fetchActivityDetail,
      emailFormData,
      onSendExampleEmail,
      onSendEmailToUser,
      onSendEmail,
      onDraftEmail,
      errorTxt,
      successTxt,
      errorNotificationToggle,
      successNotificationToggle,
      currentActivity
    };
  }
});
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";

.form-group {
  @apply flex flex-col space-y-1 mb-4;
}
label.required::after {
  content: " *";
  color: red;
}
.w-460 {
  width: 460px;
}
</style>
