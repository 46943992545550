<template>
  <div>
    <div class="flex flex-col items-center mt-8 intro-y sm:flex-row">
      <h2 class="mr-auto text-lg font-medium">จัดการบทความ</h2>
    </div>
    <div class="flex flex-col items-center mt-4 space-x-4 intro-y lg:flex-row">
      <div v-for="(item, index) in filterItems" :key="index">
        <button
          class="w-[100px] h-8"
          :class="{ 'btn btn-primary': selectedFillter.id == item.id }"
          @click="selectFilter(index)"
        >
          <span>{{ item.name }}</span>
        </button>
      </div>
    </div>
    <div class="flex flex-col items-center mt-4 intro-y lg:flex-row">
      <div class="flex w-full font-medium">
        <input
          id="tabulator-html-filter-value"
          v-model="searchContent"
          type="text"
          class="w-full form-control"
          placeholder="ค้นหา"
          @keyup.enter="onSearch()"
        />
        <button class="ml-4 shadow-md btn btn-primary" @click="onSearch()">
          ค้นหา
        </button>
      </div>
      <div class="flex justify-end w-full mt-2 space-x-2 lg:mt-0">
        <div class="dropdown">
          <button
            class="dropdown-toggle btn btn-outline-secondary"
            aria-expanded="false"
          >
            {{ selectedStatus.name }}
            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
          </button>
          <div class="w-40 dropdown-menu">
            <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
              <a
                v-for="status in articleStatus"
                :key="status.key"
                href="javascript:;"
                data-dismiss="dropdown"
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                @click="selectFilterByStatus(status)"
              >
                {{ status.name }}
              </a>
            </div>
          </div>
        </div>
        <router-link
          v-if="selectedFillter.id == null"
          :to="{ name: 'article-order' }"
        >
          <button class="shadow-md btn btn-primary">
            จัดลำดับ Highlight
          </button>
        </router-link>
        <router-link :to="{ name: 'article-create', query: route.query }">
          <button class="shadow-md btn btn-primary">
            <PlusIcon class="w-4 h-4" />
            เพิ่มบทความ
          </button>
        </router-link>
      </div>
    </div>
    <div v-if="articles.length > 0">
      <div class="col-span-12 mt-4 overflow-auto intro-y lg:overflow-visible">
        <table class="table -mt-2 table-report">
          <thead>
            <tr>
              <th class="text-center whitespace-nowrap">#</th>
              <th class="whitespace-nowrap max-w-xl">หัวข้อ</th>
              <th class="text-center whitespace-nowrap">แชร์แล้ว</th>
              <th class="text-center whitespace-nowrap">คนถูกใจ</th>
              <th class="text-center whitespace-nowrap">วันที่เพิ่ม</th>
              <th class="w-36 whitespace-nowrap">สถานะ</th>
              <th class="text-center whitespace-nowrap">ตัวเลือก</th>
              <th class="text-center whitespace-nowrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(article, index) in articles"
              :key="article"
              class="intro-x"
            >
              <td class="w-10">
                <div class="flex">
                  {{ (pageSelect - 1) * pageSizeSelect + index + 1 }}
                </div>
              </td>
              <td class="">
                <div
                  class="flex 2xl:max-w-xl  xl:truncate xl:max-w-md max-w-xs"
                >
                  {{ article.title }}
                </div>
              </td>
              <td class="text-center">
                {{ article.amount_shared }}
              </td>
              <td class="text-center">
                {{ article.amount_liked }}
              </td>
              <td class="text-center">
                {{ getDateFormat(article.created_at) }}
              </td>
              <td>
                <div class="flex justify-start text-center">
                  <label class="toggle-control">
                    <input
                      type="checkbox"
                      :checked="article.is_publish"
                      @click="
                        updateStatusIsPublished(
                          article.id,
                          index,
                          article.is_publish
                        )
                      "
                    />
                    <span class="control"></span>
                  </label>
                  <div class="flex items-center justify-center mt-0.5 ml-1.5">
                    {{ article.is_publish ? "แสดง" : "ไม่แสดง" }}
                  </div>
                </div>
              </td>
              <td class="table-report__action">
                <div class="flex justify-center space-x-4">
                  <div class="flex item-center">
                    <a
                      v-if="article.is_pin"
                      @click="pinArticle(article.id, article.is_pin, index)"
                    >
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.80496 16C3.6187 16 3.43375 15.9429 3.2759 15.8308C2.98186 15.6215 2.84451 15.2618 2.92453 14.9147L4.01819 10.1833L0.309205 6.98884C0.0365692 6.75509 -0.0675822 6.38492 0.0443514 6.04621C0.156285 5.70814 0.460827 5.46878 0.821402 5.43605L5.72884 4.99849L7.66907 0.53921C7.81213 0.211582 8.13794 0 8.49994 0C8.86194 0 9.18776 0.211582 9.33082 0.538446L11.271 4.99849L16.1777 5.43605C16.5391 5.46802 16.8436 5.70814 16.9555 6.04621C17.0675 6.38428 16.964 6.75509 16.6913 6.98884L12.9823 10.1827L14.076 14.9139C14.1562 15.2618 14.0187 15.6215 13.7248 15.8302C13.4315 16.0389 13.0405 16.0549 12.7316 15.8726L8.49994 13.389L4.26826 15.874C4.12519 15.9575 3.96579 16 3.80496 16Z"
                          fill="#FF7B16"
                        />
                      </svg>
                    </a>
                    <a
                      v-else
                      class="flex items-center"
                      @click="pinArticle(article.id, article.is_pin, index)"
                    >
                      <StarIcon class="w-4 h-4" />
                    </a>
                  </div>
                  <router-link
                    :to="{
                      path: `/article/edit/${article.id}`,
                      query: route.query
                    }"
                    class="flex justify-center"
                  >
                    <button class="flex justify-start">
                      <EditIcon class="w-4 h-4" />
                    </button>
                  </router-link>
                </div>
              </td>
              <td class="table-report__action">
                <div class="flex justify-center text-theme-34">
                  <a
                    class="flex items-center"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                    @click="setDeleteArticle(article)"
                  >
                    <Trash2Icon class="w-4 h-4" />
                    <span class="pl-1">ลบ</span>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="flex flex-wrap items-center col-span-12 mt-4 intro-y sm:flex-row sm:flex-nowrap"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" @click="paginationSelect(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a
              class="pagination__link"
              @click="paginationSelect(pageSelect == 1 ? 1 : pageSelect - 1)"
            >
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li v-for="page in pages" :key="page" @click="paginationSelect(page)">
            <a
              v-if="pageSelect == page"
              class="pagination__link pagination__link--active"
              >{{ page }}</a
            >
            <a v-else class="pagination__link">{{ page }}</a>
          </li>
          <li>
            <a
              class="pagination__link"
              @click="
                paginationSelect(
                  pageSelect == totalPages ? totalPages : pageSelect + 1
                )
              "
            >
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" @click="paginationSelect(totalPages)">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <select
          v-model="pageSizeSelect"
          class="w-20 mt-3 form-select box sm:mt-0"
          @change="onSearch"
        >
          <option v-for="page in pageSize" :key="page" :value="page">
            {{ page }}
          </option>
        </select>
      </div>
      <div
        id="delete-confirmation-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="p-0 modal-body">
              <div class="p-5 text-center">
                <Trash2Icon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
                <div class="mt-5 text-2xl">ลบรายการ ?</div>
                <div class="text-base">
                  คุณต้องการลบรายการนี้, ใช่หรือไม่ ?
                </div>
              </div>
              <div class="flex justify-center w-full py-4">
                <button
                  type="button"
                  class="w-24 mr-4 btn btn-danger whitespace-nowrap"
                  @click="confirmDeleteArticle()"
                >
                  <div>ใช่, ลบรายการ</div>
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 btn btn-outline-secondary"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-10 text-base text-center text-gray-600">
      ไม่พบข้อมูล
    </div>
    <!-- BEGIN: Notification Content -->
    <div id="success-notification-content" class="flex hidden toastify-content">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">สำเร็จ !!</div>
        <div class="mt-1 text-gray-600">
          {{ successTxt }}
        </div>
      </div>
    </div>
    <div id="error-notification-content" class="flex hidden toastify-content">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          {{ errorTxt }}
        </div>
      </div>
    </div>
    <!-- END: Notification Content -->
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, nextTick } from "vue";
import moment from "moment";
import * as articleService from "/src/services/article";
import Toastify from "toastify-js";
import { helper as $h } from "@/utils/helper";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const articles = ref([]);
    const searchContent = ref();
    const pageSize = ref([10, 20, 30, 40]);
    const pages = ref([]);
    const pageSizeSelect = ref(10);
    const totalPages = ref(0);
    const pageSelect = ref(1);
    const deleteArticle = ref({});
    const params = {};
    const errorTxt = ref("");
    const successTxt = ref("");
    const filterItems = ref([]);
    const selectedFillter = ref({});

    const router = useRouter();
    const route = useRoute();

    const articleStatus = ref([
      { name: "ทั้งหมด", key: "" },
      { name: "เผยแพร่", key: 1 },
      { name: "ไม่เผยแพร่", key: 0 }
    ]);
    const selectedStatus = ref({ name: "เลือกดูตามสถานะ", key: "" });

    onMounted(() => {
      searchContent.value = route?.query?.search ?? undefined;
      pageSizeSelect.value = route?.query?.size ?? 10;
      pageSelect.value = route?.query?.page ?? 1;
      if (route.query?.is_publish) {
        selectedStatus.value.key = route.query?.is_publish ?? undefined;
      }
      if (route?.query?.category_id) {
        selectedFillter.value.id = +route?.query?.category_id ?? undefined;
      }
      getArticles();
      getArticlesCatergory();
    });

    const getArticles = () => {
      params.search = searchContent.value ? searchContent.value : undefined;
      params.is_publish = selectedStatus.value
        ? selectedStatus.value.key
        : undefined;
      params.size = pageSizeSelect.value;
      params.page = pageSelect.value;
      params.category_id = selectedFillter?.value?.id ?? undefined;

      router.push({
        path: route.path,
        query: {
          search: params.search,
          is_publish: params.is_publish,
          size: params.size,
          page: params.page,
          category_id: params.category_id
        }
      });

      articleService
        .getList(params)
        .then(res => {
          articles.value = res.data.data.posts;
          if (res.data.data.posts) {
            articles.value = res.data.data.posts.map((item, index) => {
              item.no =
                index + 1 + (pageSelect.value - 1) * pageSizeSelect.value;
              return item;
            });
            if (params.page > 1 && articles.value.length == 0) {
              pageSelect.value = 1;
              getArticles();
            }
          } else {
            articles.value = [];
          }

          const pageResult = $h.calculatePagination({
            totalItems: res.data.data.lastPage * pageSizeSelect.value,
            pageSize: pageSizeSelect.value,
            currentPage: pageSelect.value
          });
          pages.value = pageResult.pages;
          totalPages.value = pageResult.totalPages;
        })
        .catch(() => {});
    };
    const getArticlesCatergory = () => {
      articleService
        .getListCatergory()
        .then(res => {
          filterItems.value = res.data.data;
          filterItems.value.unshift({
            name: "ทั้งหมด",
            id: null,
            active: true
          });
          const findCategory = filterItems.value.find(
            val => val.id === +route?.query?.category_id
          );
          selectedFillter.value = findCategory
            ? findCategory
            : filterItems.value[0];
        })
        .catch(() => {});
    };
    const selectFilter = index => {
      pageSelect.value = 1;
      filterItems.value.forEach(item => (item.active = false));
      filterItems.value[index].active = true;
      selectedFillter.value = filterItems.value[index];
      getArticles();
    };

    const selectFilterByStatus = status => {
      pageSelect.value = 1;
      selectedStatus.value = status;
      getArticles();
    };

    const paginationSelect = page => {
      pageSelect.value = page;
      getArticles();
    };

    const getDateFormat = date => {
      return moment(date).format("DD/MM/YYYY");
    };

    const pinArticle = (id, isPin, index) => {
      articleService
        .updatePin(id)
        .then(res => {
          if (res) {
            articles.value[index].is_pin = !isPin;
          }
        })
        .catch(() => {});
    };

    const setDeleteArticle = article => {
      deleteArticle.value = { ...article };
    };

    const confirmDeleteArticle = () => {
      articleService
        .deleteArticle(deleteArticle.value.id)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          successTxt.value = "ลบบทความเรียบร้อยแล้ว";
          cash("#delete-confirmation-modal").modal("hide");
          successNotificationToggle();
          getArticles();
        })
        .catch(() => {
          errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
          errorNotificationToggle();
        });
    };

    const onSearch = () => {
      pageSelect.value = 1;
      getArticles();
    };
    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };
    const updateStatusIsPublished = (id, index, status) => {
      articleService
        .updateStatusIsPublished(id)
        .then(() => {
          JSON.parse(
            JSON.stringify((articles.value[index].is_publish = !status))
          );
        })
        .catch(() => {});
    };
    return {
      articles,
      articleStatus,
      selectedStatus,
      selectedFillter,
      filterItems,
      pageSize,
      totalPages,
      pageSizeSelect,
      searchContent,
      deleteArticle,
      setDeleteArticle,
      confirmDeleteArticle,
      pinArticle,
      paginationSelect,
      getDateFormat,
      selectFilter,
      onSearch,
      selectFilterByStatus,
      updateStatusIsPublished,
      pages,
      pageSelect,
      getArticlesCatergory,
      successTxt,
      errorTxt,
      route
    };
  }
});
</script>
<style lang="postcss" scoped></style>
