<template>
  <div class="items-center m-0 pt-3.5 intro-y lg:flex-row  ">
    <div class="flex w-full m-0 pb-6 font-medium ">
      <div class="col-start-1 col-end-3 flex items-end text-lg">
        ข้อผิดพลาดการส่งอีเมล ({{ emailAmount }})
      </div>
    </div>

    <div class="flex flex-col items-center my-6 space-x-4 intro-y lg:flex-row">
      <div v-for="(item, index) in filterItems" :key="index">
        <button
          class="w-[100px] h-8"
          :class="{ 'btn btn-primary': selectedFillter == item.key }"
          @click="selectFilter(item.key)"
        >
          <span>{{ item.text }}</span>
        </button>
      </div>
    </div>

    <div class="grid grid-cols-4 gap-4">
      <div class="col-span-3">
        <input
          id="tabulator-html-filter-value"
          v-model="searchText"
          type="text"
          class="w-2/5 form-control"
          placeholder="ค้นหาชื่อ นามสกุล เบอร์โทรศัพท์ อีเมล และ ID Code"
          @keyup.enter="onSearch"
        />
        <button class="ml-4 shadow-md btn btn-primary" @click="onSearch">
          ค้นหา
        </button>
      </div>
      <div class="col-span-1">
        <div class="flex justify-end w-full mt-4 sm:mt-0">
          <button
            :class="
              `dropdown-toggle text-sm w-40 font-normal btn btn-outline-secondary mr-3 ${
                !selectedEmailBounce.length ? 'opacity-60' : ''
              }`
            "
            :disabled="!selectedEmailBounce.length"
            @click="handleDeleteAllCheckedEmailBounce"
          >
            ยกเลิกการส่งอีเมล
          </button>
          <button
            :class="
              `shadow-md w-24 btn btn-primary ${
                !selectedEmailBounce.length ? 'opacity-60' : ''
              }`
            "
            :disabled="!selectedEmailBounce.length"
            @click="handleSendAllCheckedEmailBounce"
          >
            ส่งอีเมล
          </button>
        </div>
      </div>
    </div>

    <div v-if="totalEmailBounce !== 0" class="mt-7">
      <div v-if="isDetailActivity">
        <div
          v-for="(emailBounce, index) in detailEmailBounces"
          :key="emailBounce.id"
          class="intro-x"
        >
          <Collapse
            :detail-email-bounce="emailBounce"
            :index-parent="index"
            @onCheckedAll="checkAllHandler"
            @onChecked="handleChecked"
            @onDeleteOne="handleDeleteOne"
            @onDeleteELement="handleDeleteElement"
            @onEditEmail="handleEditEmail"
            @onSendEmailBounce="handleSendEmailBounce"
            @onViewDetailEmail="handleViewDetailEmailBounce"
            @onResetEmailBounce="handleResetEmailBounce"
          />
        </div>
      </div>
      <div v-else>
        <div
          v-for="(emailBounce, index) in detailEmailBounces"
          :key="emailBounce.id"
          class="intro-x"
        >
          <Collapse
            :detail-email-bounce="emailBounce"
            :index-parent="index"
            @onCheckedAll="checkAllHandler"
            @onChecked="handleChecked"
            @onDeleteOne="handleDeleteOne"
            @onDeleteELement="handleDeleteElement"
            @onEditEmail="handleEditEmail"
            @onSendEmailBounce="handleSendEmailBounce"
            @onViewDetailEmail="handleViewDetailEmailBounce"
            @onResetEmailBounce="handleResetEmailBounce"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="font-prompt text-xl font-medium mt-40 mb-80 text-center">
        ไม่มีรายการ
      </div>
    </div>

    <div
      class="flex flex-wrap items-center col-span-12 mt-4 intro-y sm:flex-row sm:flex-nowrap"
    >
      <ul class="pagination">
        <li>
          <a class="pagination__link" @click="paginationSelect(1)">
            <ChevronsLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="paginationSelect(pageSelect == 1 ? 1 : pageSelect - 1)"
          >
            <ChevronLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li v-for="page in pages" :key="page" @click="paginationSelect(page)">
          <a
            v-if="pageSelect == page"
            class="pagination__link pagination__link--active"
            >{{ page }}</a
          >
          <a v-else class="pagination__link">{{ page }}</a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="
              paginationSelect(
                pageSelect == totalPages ? totalPages : pageSelect + 1
              )
            "
          >
            <ChevronRightIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a class="pagination__link" @click="paginationSelect(totalPages)">
            <ChevronsRightIcon class="w-4 h-4" />
          </a>
        </li>
      </ul>
      <select
        v-model="pageSizeSelect"
        class="w-20 mt-3 form-select box sm:mt-0"
        @change="onPageSizeHandle()"
      >
        <option v-for="page in pageSize" :key="page" :value="page">
          {{ page }}
        </option>
      </select>
    </div>
    <!--Modal Send One Email Bounce-->
    <div
      id="confirm-send-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 w-24 h-20 inline-block" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              {{ emailTitleText }}
            </div>
            <div class="pt-2 px-14 leading-5 text-sm text-theme-32">
              {{ emailBodyText
              }}<span class="text-theme-1 font-semibold">{{
                emailSended
              }}</span>
              ?
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 h-10 mr-2 btn btn-primary"
              @click="onConfirmSendEmailBounce"
            >
              ยืนยัน
            </button>

            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send One Email Bounce-->
    <!--Modal Modal Send Checked Email Bounce-->
    <div
      id="confirm-send-checked-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 w-24 h-20 inline-block" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              {{ emailTitleText }}
            </div>
            <div class="pt-2 px-14 leading-5 text-sm text-theme-32">
              {{ emailBodyText }}
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class=" w-20 h-10 mr-2 btn btn-primary"
              @click="onConfirmSendAllCheckedEmailBounce"
            >
              ยืนยัน
            </button>

            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send Checked Email Bounce-->
    <!--Modal Cancel Send Email-->
    <div
      id="confirm-cancel-send-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 w-24 h-20 inline-block" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              {{ emailTitleText }}
            </div>
            <div class="pt-2 leading-5 text-sm text-theme-32">
              {{ emailBodyText }}<br />
              ไปยัง
              <span class="text-theme-1 font-semibold text-sm">{{
                emailDeleted
              }}</span>
              ?
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 h-10 mr-2 btn btn-primary"
              @click="confirmHandleDeleteOne"
            >
              ยืนยัน
            </button>

            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Cancel Send Email-->
    <!--Modal Delete Element Email-->
    <div
      id="confirm-delete-element-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 w-24 h-20 inline-block" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              {{ emailTitleText }}
            </div>
            <div class="pt-2 leading-5 text-sm text-theme-32">
              {{ emailBodyText }}
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 h-10 mr-2 btn btn-primary"
              @click="confirmHandleDeleteElement"
            >
              ยืนยัน
            </button>

            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Cancel Send Email-->
    <!--Modal Cancel Send All Checked Email-->
    <div
      id="confirm-delete-send-checked-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 w-24 h-20 inline-block" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              {{ emailTitleText }}
            </div>
            <div class="pt-2 leading-5 text-sm text-theme-32">
              {{ emailBodyText }}
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 h-10 mr-2 btn btn-primary"
              @click="confirmHandleDeleteAllCheckedEmailBounce"
            >
              ยืนยัน
            </button>

            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Cancel Send All Checked Email-->

    <!--Modal Send Email Success-->
    <div
      id="success-send-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-16">
              <div
                class="relative w-20 h-20 bg-primary-1 rounded-full inline-block"
              >
                <div>
                  <CheckIcon
                    class="left-2 top-2.5 text-white w-16 h-16 absolute"
                  />
                </div>
              </div>
              <div class="mt-6 leading-5 text-xl font-medium text-theme-32">
                ส่งอีเมลสำเร็จ
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send Example Email-->

    <!--Modal Send Email Success-->
    <div
      id="error-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3 text-center">
            <div class="col-span-12 sm:col-span-12 m-0 pt-12">
              <div class="flex justify-center items-center">
                <AlertCircleIcon class="text-red-500 w-16 h-16" />
              </div>
              <div class="font-medium text-xl mt-4">ผิดพลาด !!</div>
              <div class="mt-0 leading-7 text-xl font-medium text-theme-32">
                ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่<br />อีกครั้ง
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!--End Modal Send Example Email-->

    <div
      id="detail-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="mx-auto my-8 w-1/2">
        <div class="modal-content">
          <button
            type="button"
            class="v-dialog-close"
            aria-label="Close"
            data-dismiss="modal"
          >
            <span class="v-btn__content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#5E5873"
                class="h-6 w-6 m-0"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
          <div class="pt-16 pb-36 modal-body px-28">
            <div
              class="font-kanit text-lg pb-3 font-extrabold"
              v-html="mail_subject"
            ></div>
            <div
              class="text-lg font-normal font-sarabun"
              v-html="mail_body"
            ></div>
            <div
              v-if="states.certificate || states.attachFileEmails"
              class="pt-10"
            >
              <hr />
            </div>
            <div v-if="states.certificate">
              <div class="pt-8 font-medium text-lg font-prompt">
                ใบประกาศนียบัตร
              </div>
              <div class="pt-2 flex justify-center">
                <img :src="urlImage" />
              </div>
            </div>
            <div v-if="states.attachFileEmails">
              <div class="pt-8 font-medium text-lg font-prompt">
                ไฟล์แนบอีเมลตอบกลับ
              </div>
              <div class="pt-2 flex">
                <div v-for="file in states.attachFileEmails" :key="file.id">
                  <button
                    @click="onDownload(file.original_name, file.url)"
                    type="button"
                    class="bg-theme-38 w-44 h-10 rounded-md flex items-center justify-center mr-2"
                  >
                    <span class="ml-4"
                      ><FileTextIcon class="w-4 inline-block"/></span
                    ><span class="ml-2 mr-4 truncate">{{
                      file.original_name
                    }}</span>
                    <span> <DownloadIcon class="w-4 mr-4 inline-block"/></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Add/Edit Content Modal -->
    <div id="edit-email-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="mr-auto text-base font-medium font-prompt">
              <b>แก้ไขอีเมล</b>
            </h2>
          </div>
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3">
            <div class="col-span-12 sm:col-span-12">
              <label
                for="modal-form-1"
                class="form-label font-prompt font-normal text-xs required"
                >แก้ไขอีเมล</label
              >
              <input
                v-model="editedEmail"
                type="text"
                :class="
                  `form-control focus:ring-transparent font-prompt font-normal text-sm ${
                    v$?.email?.$error ? 'border-red-500' : ''
                  }`
                "
                @keydown.space.prevent
              />
              <div
                v-if="v$?.email?.$error"
                class="error-message text-red-500 font-prompt text-sm mt-2"
              >
                รูปแบบอีเมลของคุณผิด ตัวอย่างอีเมล email@example.com
              </div>
            </div>
          </div>
          <div class="text-left modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              :class="
                `w-20 mr-2 btn btn-primary my-2 font-prompt font-normal text-sm ${
                  isDisableSubmit() || v$?.email?.$error
                    ? 'opacity-50 cursor-not-allowed'
                    : ''
                }`
              "
              :disabled="isDisableSubmit() || v$?.email?.$error"
              @click="onConfirmEditEmail"
            >
              แก้ไข
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary my-2 font-prompt font-normal text-sm"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Add/Edit Content Modal -->
    <!--Start Modal Confirm Reset Email Bounce-->
    <div
      id="confirm-reset-email-modal"
      class="modal font-prompt"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content h-72 rounded-lg mt-40">
          <!-- BEGIN: Modal Body -->
          <div class="text-center">
            <div class="m-0 pt-9">
              <MailIcon class="text-theme-1 w-24 h-20 inline-block" />
            </div>
            <div class="leading-8 text-xl font-medium text-theme-32">
              รีเซ็ตอีเมล
            </div>
            <div class="pt-2 leading-5 text-sm text-theme-32">
              ยืนยันการรีเซ็ตอีเมลเป็น
              <span class="text-theme-1 font-semibold">{{ emailReset }}</span> ?
            </div>
          </div>
          <div class="flex justify-center w-full pt-5">
            <button
              type="button"
              data-dismiss="modal"
              class=" w-20 h-10 mr-2 btn btn-primary"
              @click="confirmHandleResetEmailBounce"
            >
              ยืนยัน
            </button>

            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!---End Modal Confirm Reset Email Bounce-->
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  reactive,
  onUnmounted,
  nextTick
} from "vue";
import * as emailService from "/src/services/email.js";
import { useRoute } from "vue-router";
import Collapse from "./collapse/Main.vue";
import { helper as $h } from "@/utils/helper";
import DOMPurify from "dompurify";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import Toastify from "toastify-js";

export default defineComponent({
  components: {
    Collapse
  },
  props: {
    amount: {
      type: Number,
      default: 0
    },
    childAmount: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const route = useRoute();
    const selectedEmailBounce = ref([]);
    const filterItems = ref([
      { text: "รายละเอียดกิจกรรม", key: "announcement" },
      { text: "ใบประกาศนียบัตร", key: "certificate" }
    ]);
    const selectedFillter = ref("announcement");
    const searchText = ref();
    const pageSize = ref([10, 20, 30, 40]);
    const pages = ref([]);
    const pageSizeSelect = ref(pageSize.value[0]);
    const totalPages = ref(0);
    const pageSelect = ref(1);
    const errorTxt = ref("");
    const isDetailActivity = ref(true);
    const currentActivityId = ref(route.params.id);
    const detailEmailBounces = ref([]);
    const totalEmailBounce = ref(null);
    const emailSelectedChecked = ref([]);
    const emailBounceAmount = ref();
    const idSendEmail = ref();
    const idCancelSendEmail = ref();
    const urlImage = ref("");
    const urlFiles = ref("");
    const indexParentEMail = ref();
    const editIndex = ref();
    const editedEmail = ref("");
    const idEditedEmail = ref("");
    const emailTitleText = ref("");
    const emailBodyText = ref();
    const indexDeleteElement = ref();
    const idResetEmail = ref();
    const emailReset = ref();
    const emailBounceInterval = ref();
    const emailAmount = ref(0);
    const attachFileEmails = ref([]);
    const emailDeleted = ref("");
    const emailSended = ref("");

    const rules = {
      email: {
        required,
        email
      }
    };
    const v$ = useVuelidate(rules, { email: editedEmail });
    const states = reactive({
      mail_subject: "",
      mail_body: "",
      certificate: null,
      attachFileEmails: null
    });

    const isDisableSubmit = () => {
      return isNullOrEmpty(editedEmail);
    };

    const isNullOrEmpty = val => {
      return val.value === null || val.value === "" || val.value.length == 0;
    };

    const selectedEmail = computed(() => {
      return detailEmailBounces.value.forEach(item => {
        item.map(bounce => {
          return bounce.id;
        });
      });
    });

    const onDownload = async (fileName, url) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      } catch (error) {
        errorTxt.value = "ไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const selectedEmailInElement = computed(() => {
      return detailEmailBounces?.value[indexParentEMail]?.bounces;
    });

    const mail_subject = computed(() =>
      DOMPurify.sanitize(states.mail_subject)
    );

    const mail_body = computed(() => DOMPurify.sanitize(states.mail_body));

    const selectFilter = type => {
      pageSelect.value = 1;
      isDetailActivity.value = type === "announcement";
      window.localStorage.setItem(
        "isDetailActivity",
        JSON.stringify(isDetailActivity.value)
      );
      if (isDetailActivity.value) {
        getDetailActivity(type);
      } else {
        getDetailActivity(type);
      }
      selectedFillter.value = type;
      selectedEmailBounce.value = [];
    };

    const checkAllHandler = (data, checked) => {
      if (checked) {
        selectedEmailBounce.value.push(...data);
      } else {
        for (const item of data) {
          const index = selectedEmailBounce.value.findIndex(
            bounce => bounce.id === item.id
          );
          selectedEmailBounce.value.splice(index, 1);
        }
      }
    };

    const handleChecked = (bounceId, checked, indexParent) => {
      indexParentEMail.value = indexParent;
      if (checked) {
        selectedEmailBounce.value.push(bounceId);
      } else {
        const index = selectedEmailBounce.value.findIndex(
          bounce => bounce.id === bounceId
        );
        selectedEmailBounce.value.splice(index, 1);
      }
    };

    const handleDeleteAllCheckedEmailBounce = async () => {
      emailTitleText.value = "ยกเลิกการส่งอีเมล";
      emailBodyText.value = `ยืนยันการยกเลิกการส่งอีเมล ?`;
      cash("#confirm-delete-send-checked-email-modal").modal("show");
    };

    const confirmHandleDeleteAllCheckedEmailBounce = async () => {
      try {
        const payload = {
          bounces: [...selectedEmailBounce.value]
        };
        await emailService.deleteEmailBonceById(
          currentActivityId.value,
          payload
        );
        if (isDetailActivity.value) {
          getDetailActivity("announcement");
        } else {
          getDetailActivity("certificate");
        }
      } catch (error) {
        onAlertErrorModal();
      }
    };

    const handleDeleteOne = async (id, email) => {
      emailDeleted.value = email;
      idCancelSendEmail.value = id;
      emailTitleText.value = "ยกเลิกการส่งอีเมล";
      emailBodyText.value = `ยืนยันการยกเลิกการส่งอีเมล`;
      cash("#confirm-cancel-send-email-modal").modal("show");
    };

    const confirmHandleDeleteOne = async () => {
      try {
        const emailArrayData = [];
        const idEmail = await $h.toRaw(idCancelSendEmail.value);
        emailArrayData.push(idEmail);
        const payload = {
          bounces: emailArrayData
        };
        await emailService.deleteEmailBonceById(
          currentActivityId.value,
          payload
        );
        if (isDetailActivity.value) {
          getDetailActivity("announcement");
        } else {
          getDetailActivity("certificate");
        }
      } catch (error) {
        onAlertErrorModal();
      }
    };

    const handleDeleteElement = async indexParent => {
      indexDeleteElement.value = await $h.toRaw(indexParent);
      emailTitleText.value = "ยกเลิกการส่งอีเมล";
      emailBodyText.value = `ยืนยันการยกเลิกการส่งอีเมลทั้งหมด ?`;
      cash("#confirm-delete-element-modal").modal("show");
    };

    const confirmHandleDeleteElement = async () => {
      const emailArrayData = [];
      for (const item of detailEmailBounces.value[indexDeleteElement.value]
        .bounces) {
        emailArrayData.push(item.id);
      }
      const payload = {
        bounces: emailArrayData
      };
      await emailService.deleteEmailBonceById(currentActivityId.value, payload);
      if (isDetailActivity.value) {
        fetchInitialEmailBounceAmount();
        getDetailActivity("announcement");
      } else {
        fetchInitialEmailBounceAmount();
        getDetailActivity("certificate");
      }
    };

    const handleEditEmail = async data => {
      const { email, id } = data;
      editedEmail.value = email;
      idEditedEmail.value = id;
      cash(`#edit-email-modal`).modal("show");
    };

    const onConfirmEditEmail = async () => {
      try {
        const idEmail = await $h.toRaw(idEditedEmail.value);
        const payload = {
          email: editedEmail.value
        };
        if (isDetailActivity.value) {
          const type = "announcement";
          await emailService.editEmailBounce(String(idEmail), type, payload);
          getDetailActivity("announcement");
        } else {
          const type = "certificate";
          await emailService.editEmailBounce(String(idEmail), type, payload);
          getDetailActivity("certificate");
        }
      } catch (error) {
        onAlertErrorModal();
      }
    };

    const handleSendAllCheckedEmailBounce = async () => {
      emailTitleText.value = "ส่งอีเมล";
      emailBodyText.value = `ยืนยันการส่งอีเมล ?`;
      cash("#confirm-send-checked-email-modal").modal("show");
    };

    const handleSendEmailBounce = (id, email) => {
      emailSended.value = email;
      idSendEmail.value = $h.toRaw(id);
      emailTitleText.value = "ส่งอีเมล";
      emailBodyText.value = `ยืนยันการส่งอีเมลไปยัง `;
      cash("#confirm-send-email-modal").modal("show");
    };

    const handleResetEmailBounce = (id, email) => {
      idResetEmail.value = id;
      emailReset.value = email;
      cash("#confirm-reset-email-modal").modal("show");
    };

    const confirmHandleResetEmailBounce = async () => {
      try {
        if (isDetailActivity.value) {
          const type = "announcement";
          await emailService.resetEmailBounce(String(idResetEmail.value), type);
        } else {
          const type = "certificate";
          await emailService.resetEmailBounce(String(idResetEmail.value), type);
        }
        if (isDetailActivity.value) {
          getDetailActivity("announcement");
        } else {
          getDetailActivity("certificate");
        }
      } catch (error) {
        onAlertErrorModal();
      }
    };

    const onConfirmSendEmailBounce = async () => {
      try {
        const emailArrayData = [];
        emailArrayData.push(idSendEmail.value);
        const payload = {
          bounces: emailArrayData
        };
        await emailService.sendEmailBounce(payload);
        onSuccessModal();
        if (isDetailActivity.value) {
          getDetailActivity("announcement");
        } else {
          getDetailActivity("certificate");
        }
      } catch (error) {
        onAlertErrorModal();
      }
    };

    const onConfirmSendAllCheckedEmailBounce = async () => {
      try {
        const payload = {
          bounces: [...selectedEmailBounce.value]
        };
        await emailService.sendEmailBounce(payload);
        onSuccessModal();
        if (isDetailActivity.value) {
          getDetailActivity("announcement");
        } else {
          getDetailActivity("certificate");
        }
      } catch (error) {
        onAlertErrorModal();
      }
    };

    const handleViewDetailEmailBounce = async emailId => {
      try {
        const res = await emailService.getEmailBounceById(emailId);
        const {
          mail_subject,
          mail_body,
          certificate,
          files
        } = res.data.data.item;
        states.mail_subject = mail_subject;
        states.mail_body = mail_body;
        states.certificate = certificate;
        states.attachFileEmails = files;
        if (isDetailActivity.value) {
          const fileUrl = `data:image/png;base64, ${states.attachFileEmails}`;
          urlFiles.value = fileUrl;
        } else {
          const fileUrl = `data:image/png;base64, ${states.certificate}`;
          urlImage.value = fileUrl;
        }
        cash("#detail-email-modal").modal("show");
      } catch (error) {
        onAlertErrorModal();
      }
    };

    const getDetailActivity = async type => {
      try {
        let params = {
          page: pageSelect.value,
          perPage: pageSizeSelect.value,
          search: searchText?.value || undefined
        };
        const res = await emailService.getEmailBounce(
          currentActivityId.value,
          type,
          params
        );
        const { bounce_amount, bounces, total } = res.data.data.item;
        emailBounceAmount.value = bounce_amount;
        detailEmailBounces.value = bounces;
        totalEmailBounce.value = detailEmailBounces.value.length;

        const pageResult = $h.calculatePagination({
          totalItems: total,
          pageSize: pageSizeSelect.value,
          currentPage: pageSelect.value
        });
        pages.value = pageResult.pages;
        totalPages.value = pageResult.totalPages;
      } catch (error) {
        onAlertErrorModal();
      }
    };

    const onSuccessModal = () => {
      cash(`#success-send-email-modal`).modal("show");
      setTimeout(() => {
        cash(`#success-send-email-modal`).modal("hide");
      }, 1500);
    };

    const onAlertErrorModal = () => {
      cash(`#error-email-modal`).modal("show");
      setTimeout(() => {
        cash(`#error-email-modal`).modal("hide");
      }, 1500);
    };

    const onSearch = () => {
      pageSelect.value = 1;
      if (isDetailActivity.value) {
        getDetailActivity("announcement");
      } else {
        getDetailActivity("certificate");
      }
    };

    const paginationSelect = page => {
      pageSelect.value = page;
      if (isDetailActivity.value) {
        getDetailActivity("announcement");
      } else {
        getDetailActivity("certificate");
      }
    };

    const onPageSizeHandle = () => {
      pageSelect.value = 1;
      if (isDetailActivity.value) {
        getDetailActivity("announcement");
      } else {
        getDetailActivity("certificate");
      }
    };

    const setTypeEmailBounce = () => {
      isDetailActivity.value = JSON.parse(
        localStorage.getItem("isDetailActivity") || true
      );
      if (isDetailActivity.value) {
        selectedFillter.value = "announcement";
        getDetailActivity("announcement");
        fetchInitialEmailBounceAmount();
      } else {
        selectedFillter.value = "certificate";
        getDetailActivity("certificate");
        fetchInitialEmailBounceAmount();
      }
    };

    const onValidateInput = () => {
      v$.value.$validate();
    };

    const fetchInitialEmailBounceAmount = async () => {
      const res = await emailService.getEmailBounceAmount(route.params.id);
      emailAmount.value = res.data.data.item.bounce_amount;
      props.childAmount(emailAmount.value);
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 1200,
          newWindow: true,
          gravity: "center",
          position: "center",
          stopOnFocus: true
        }).showToast();
      });
    };

    onMounted(() => {
      onValidateInput();
      setTypeEmailBounce();
      fetchInitialEmailBounceAmount();
      emailBounceInterval.value = setInterval(() => {
        fetchInitialEmailBounceAmount();
      }, 20000);
    });

    onUnmounted(() => {
      clearInterval(emailBounceInterval.value);
    });

    return {
      searchText,
      pages,
      pageSize,
      pageSizeSelect,
      totalPages,
      pageSelect,
      onSearch,
      paginationSelect,
      onPageSizeHandle,
      errorTxt,
      filterItems,
      selectedFillter,
      selectFilter,
      isDetailActivity,
      getDetailActivity,
      detailEmailBounces,
      totalEmailBounce,
      handleChecked,
      selectedEmailBounce,
      checkAllHandler,
      emailSelectedChecked,
      emailBounceAmount,
      selectedEmail,
      handleDeleteOne,
      handleEditEmail,
      handleSendEmailBounce,
      handleSendAllCheckedEmailBounce,
      handleDeleteAllCheckedEmailBounce,
      onConfirmSendEmailBounce,
      idSendEmail,
      onConfirmSendAllCheckedEmailBounce,
      confirmHandleDeleteOne,
      idCancelSendEmail,
      confirmHandleDeleteAllCheckedEmailBounce,
      handleViewDetailEmailBounce,
      states,
      mail_subject,
      mail_body,
      urlImage,
      urlFiles,
      onAlertErrorModal,
      onSuccessModal,
      handleDeleteElement,
      selectedEmailInElement,
      indexParentEMail,
      editIndex,
      editedEmail,
      idEditedEmail,
      isDisableSubmit,
      isNullOrEmpty,
      v$,
      onConfirmEditEmail,
      emailTitleText,
      emailBodyText,
      confirmHandleDeleteElement,
      indexDeleteElement,
      handleResetEmailBounce,
      idResetEmail,
      emailReset,
      confirmHandleResetEmailBounce,
      emailBounceInterval,
      emailAmount,
      fetchInitialEmailBounceAmount,
      attachFileEmails,
      emailDeleted,
      emailSended,
      onDownload
    };
  }
});
</script>

<style scoped lang="scss">
label.required::after {
  content: " *";
  color: red;
}
.accent-orange {
  accent-color: #ec7113;
}
.v-dialog-close {
  display: grid;
  position: absolute;
  z-index: 1;
  border-radius: 0.375rem;
  background-color: white !important;
  block-size: 2rem;
  inset-block-start: 0;
  inset-inline-end: 0;
  transform: translate(0.5rem, -0.5rem);
  width: 34px;
  height: 34px;
  box-shadow: 0px 3px 8px rgba(167, 174, 181, 0.4);
}

.v-btn__content {
  grid-area: content;
  justify-content: center;
  white-space: nowrap;
}
</style>
