import { createRouter, createWebHistory } from "vue-router";
import SideMenu from "../layouts/side-menu/Main.vue";
import DashboardOverview1 from "../views/dashboard-overview-1/Main.vue";
import DashboardViewMoreProvince from "../views/dashboard-overview-1/Provinces.vue";
import DashboardViewMoreOccupation from "../views/dashboard-overview-1/Occupations.vue";
import DashboardViewMoreAttention from "../views/dashboard-overview-1/Attentions.vue";
import Login from "../views/login/Main.vue";
import ErrorPage from "../views/error-page/Main.vue";
import UserMain from "../views/user/Main.vue";
import Logout from "../views/logout/Main.vue";
import Tag from "../views/tag/Main.vue";
import cookie from "js-cookie";
import Article from "../views/article/Main.vue";
import CreateArticle from "../views/article/Create.vue";
import EditArticle from "../views/article/Edit.vue";
import OrderArticle from "../views/article/Order.vue";
import Gathering from "../views/gathering/Main.vue";
import Banner from "../views/banner/Main.vue";
import BannerCreate from "../views/banner/BannerCreate.vue";
import BannerEdit from "../views/banner/BannerEdit.vue";
import SponsorCreate from "../views/banner/SponsorCreate.vue";
import SponsorEdit from "../views/banner/SponsorEdit.vue";
import CreateGathering from "../views/gathering/Create.vue";
import FormEmailSetting from "../views/gathering/Setting.vue";
import EmailSendingHistory from "../views/gathering/History.vue";
import EditGathering from "../views/gathering/Edit.vue";
import OrderGathering from "../views/gathering/Order.vue";
import ExampleDocument from "../views/example-document/Main.vue";
import RegisterDocument from "../views/register-document/Main.vue";
import RegisterDocumentById from "../views/register-document/document-list-detail/_id.vue";
import ExampleDocumentLesson from "../views/example-document/lesson-list/_id.vue";
import ExampleDocumentExampleByid from "../views/example-document/lesson-list/example-list/_id.vue";
import ExampleDocumentExampleByidAndExample from "../views/example-document/lesson-list/example-list/example-list-detail/_id.vue";
import PreviewArticle from "../views/article/PreviewArticle.vue";
import WebsiteManage from "../views/websiteManage/Main.vue";

const routes = [
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "/dashboard",
        name: "side-menu-dashboard-overview-1",
        component: DashboardOverview1
      },
      {
        path: "dashboard/provinces",
        name: "side-menu-dashboard-overview-1-provinces",
        component: DashboardViewMoreProvince
      },
      {
        path: "dashboard/occupations",
        name: "side-menu-dashboard-overview-1-occupations",
        component: DashboardViewMoreOccupation
      },
      {
        path: "dashboard/attentions",
        name: "side-menu-dashboard-overview-1-attentions",
        component: DashboardViewMoreAttention
      },
      {
        path: "article",
        name: "article-main",
        component: Article
      },
      {
        path: "article/create",
        name: "article-create",
        component: CreateArticle
      },
      {
        path: "article/edit/:id",
        name: "article-edit",
        component: EditArticle
      },
      {
        path: "article/order",
        name: "article-order",
        component: OrderArticle
      },
      {
        path: "gathering",
        name: "gathering-main",
        component: Gathering
      },
      {
        path: "banner",
        name: "banner-main",
        component: Banner
      },
      {
        path: "banner/create",
        name: "banner-create",
        component: BannerCreate
      },
      {
        path: "banner/edit/:id",
        name: "banner-edit",
        component: BannerEdit
      },
      {
        path: "banner/sponsor-create",
        name: "banner-sponsor-create",
        component: SponsorCreate
      },
      {
        path: "banner/sponsor-edit/:id",
        name: "banner-sponsor-edit",
        component: SponsorEdit
      },
      {
        path: "gathering/create",
        name: "gathering-create",
        component: CreateGathering
      },
      {
        path: "gathering/email-setting/:id",
        name: "email-setting",
        component: FormEmailSetting
      },
      {
        path: "gathering/emails-history/:id",
        name: "emails-history",
        component: EmailSendingHistory
      },
      {
        path: "gathering/edit/:id",
        name: "gathering-edit",
        component: EditGathering
      },
      {
        path: "gathering/order",
        name: "gathering-order",
        component: OrderGathering
      },
      {
        path: "user",
        name: "user",
        component: UserMain
      },
      {
        path: "logout",
        name: "logout",
        component: Logout
      },
      {
        path: "/tag",
        name: "tag",
        component: Tag
      },
      {
        path: "/document/register-document",
        name: "register-document",
        component: RegisterDocument
      },
      {
        path: "/document/register-document/:id",
        name: "register-document-id",
        component: RegisterDocumentById
      },
      {
        path: "/document/example-form",
        name: "example-form",
        component: ExampleDocument
      },
      {
        path: "/document/example-form/lesson/:id",
        name: "example-form-id",
        component: ExampleDocumentLesson
      },
      {
        path: "/document/example-form/lesson/:id/example/:lessonId",
        name: "example-form-id-by-lesson",
        component: ExampleDocumentExampleByid
      },
      {
        path:
          "/document/example-form/lesson/:id/example/:lessonId/example-list-detail/:exampleId",
        name: "example-form-id-by-lesson-and-example",
        component: ExampleDocumentExampleByidAndExample
      },
      {
        path: "/website-manage",
        name: "website-manage",
        component: WebsiteManage
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage
  },
  {
    path: "/preview-article",
    name: "preview-article",
    component: PreviewArticle
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  let accessToken = cookie.get("access_token");
  if (
    to.name !== "login" &&
    !(accessToken !== undefined && accessToken !== null)
  )
    next({ name: "login" });
  else next();
});

export default router;
