const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "side-menu-dashboard-overview-1",
        title: "Dashboard",
        page: "dashboard"
      },
      {
        icon: "BookOpenIcon",
        pageName: "article-main",
        title: "จัดการบทความ",
        page: "article"
      },
      {
        icon: "CalendarIcon",
        pageName: "gathering-main",
        title: "จัดการกิจกรรม",
        page: "gathering"
      },
      {
        icon: "CreditCardIcon",
        pageName: "banner-main",
        title: "จัดการแบนเนอร์",
        page: "banner"
      },
      {
        icon: "SettingsIcon",
        pageName: "tag",
        title: "Tag บทความ",
        page: "tag"
      },
      {
        icon: "UsersIcon",
        pageName: "user",
        title: "Users",
        page: "user"
      },
      {
        icon: "folderPlusIcon",
        title: "นำเข้าข้อมูล",
        page: "document",
        subMenu: [
          {
            icon: "fileTextIcon",
            pageName: "register-document",
            page: "document",
            title: "แบบฟอร์มใบสมัคร"
          },
          {
            icon: "fileTextIcon",
            pageName: "example-form",
            page: "document",
            title: "แบบทดสอบ"
          }
        ]
      },
      {
        icon: "monitorIcon",
        pageName: "website-manage",
        title: "จัดการหน้าเว็ป",
        page: "website-manage"
      },
      {
        icon: "Log-outIcon",
        pageName: "logout",
        title: "Logout",
        page: "logout"
      }
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
