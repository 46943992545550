import axios from "axios";
import cookie from "js-cookie";

let serviceUrl = "";

if (process.env.NODE_ENV === "development") {
  serviceUrl = "https://cheevamitr-service-dev-txifm6vwia-as.a.run.app/v1";
} else {
  serviceUrl = process.env.VUE_APP_API_URL;
}

export const noAccess = axios.create({
  baseURL: serviceUrl
});

export const apiApp = axios.create({
  baseURL: serviceUrl,
  headers: {
    Authorization: "Bearer " + cookie.get("access_token")
  }
});

export const apiChApp = axios.create({
  baseURL: process.env.VUE_APP_API_URL_EXT_CVM
});

apiApp.interceptors.request.use(
  request => {
    let accessToken = cookie.get("access_token") || "";
    request.headers["Authorization"] = `Bearer ${accessToken}`;
    return request;
  },
  error => Promise.reject(error)
);
