<template>
  <div>
    <div class="flex flex-col items-center mt-8 intro-y sm:flex-row">
      <h2 class="mr-auto text-lg font-medium">Tag บทความ</h2>
    </div>
    <div class="flex flex-col items-center mt-8 mb-4 intro-y sm:flex-row">
      <div class="flex w-full font-medium">
        <input
          id="tabulator-html-filter-value"
          v-model="searchContent"
          type="text"
          class="w-full form-control"
          placeholder="ค้นหา"
          @keyup.enter="onSearch()"
        />
        <button class="ml-4 shadow-md btn btn-primary" @click="onSearch()">
          ค้นหา
        </button>
      </div>
      <div class="flex justify-end w-full mt-4 sm:mt-0">
        <div>
          <a
            class="flex items-center mr-3 "
            href="javascript:;"
            data-toggle="modal"
            data-target="#edit-content-modal"
            @click="setDataEdit({})"
          >
            <button class="ml-4 shadow-md btn btn-primary">
              + เพิ่ม Tag
            </button>
          </a>
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div v-if="tags.length > 0">
      <div class="col-span-12 overflow-auto intro-y lg:overflow-visible">
        <table class="table -mt-2 table-report">
          <thead>
            <tr>
              <th class="whitespace-nowrap">ชื่อ Tag</th>
              <th class="text-center whitespace-nowrap w-40">
                บทความที่ติดแท็ก
              </th>
              <th class="text-center whitespace-nowrap w-40">วันที่สร้าง</th>
              <th class="text-center whitespace-nowrap w-20">จัดการ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tag in tags" :key="tag" class="intro-x">
              <td class="text-left">
                <div class="flex w-300">{{ tag.name }}</div>
              </td>
              <td class="text-center">
                {{ tag.amount_post }}
              </td>
              <td class="text-center">
                {{ setDateTime(tag.created_at) }}
              </td>
              <td class="w-30 table-report__action">
                <div class="flex items-center justify-center">
                  <a
                    class="flex items-center mr-3 "
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#edit-content-modal"
                    @click="setDataEdit(tag)"
                  >
                    <EditIcon class="w-4 h-4 mr-1" />
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                    @click="setDataSelect(tag)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                    ลบ
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div
        class="flex flex-wrap items-center col-span-12 mt-4 intro-y sm:flex-row sm:flex-nowrap"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" @click="paginationSelect(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a
              class="pagination__link"
              @click="paginationSelect(pageSelect == 1 ? 1 : pageSelect - 1)"
            >
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li v-for="page in pages" :key="page" @click="paginationSelect(page)">
            <a
              v-if="pageSelect == page"
              class="pagination__link pagination__link--active"
              >{{ page }}</a
            >
            <a v-else class="pagination__link">{{ page }}</a>
          </li>
          <li>
            <a
              class="pagination__link"
              @click="
                paginationSelect(
                  pageSelect == totalPages ? totalPages : pageSelect + 1
                )
              "
            >
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" @click="paginationSelect(totalPages)">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <select
          v-model="pageSizeSelect"
          class="w-20 mt-3 form-select box sm:mt-0"
          @change="onSearch()"
        >
          <option v-for="page in pageSize" :key="page" :value="page">
            {{ page }}
          </option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
    <div v-else class="mt-10 text-base text-center text-gray-600">
      ไม่พบข้อมูล
    </div>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <Trash2Icon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
              <div class="mt-5 text-2xl">ลบรายการ ?</div>
              <div class="mt-2 text-gray-700 text-md">
                คุณต้องการลบรายการนี้, ใช่หรือไม่ ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" class="mr-4 btn btn-danger w-28">
                <div v-if="!pageLoad" @click="deleteTag()">ใช่, ลบรายการ</div>
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 btn btn-outline-secondary"
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
    <!-- BEGIN: Add/Edit Content Modal -->
    <div id="edit-content-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="mr-auto text-base font-medium">
              <b v-if="isEdit">แก้ไข Tag</b>
              <b v-else>เพิ่ม Tag</b>
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3">
            <div class="col-span-12 sm:col-span-12">
              <label for="modal-form-1" class="form-label"
                ><b>ชื่อ Tag</b></label
              >
              <input
                id="modal-form-5"
                v-model="nameTag"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="text-left modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 mr-2 btn btn-primary"
              :disabled="isDisableSubmit()"
              :class="{ 'opacity-50 cursor-not-allowed': isDisableSubmit() }"
              @click="isEdit ? editTagName() : createTagName()"
            >
              {{ isEdit ? "บันทึก" : "เพิ่ม" }}
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
              @click="setDataEdit({})"
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
        <!-- BEGIN: Notification Content -->
        <div
          id="success-notification-content"
          class="flex hidden toastify-content"
        >
          <CheckCircleIcon class="text-theme-9" />
          <div class="ml-4 mr-4">
            <div class="font-medium">สำเร็จ !!</div>
            <div class="mt-1 text-gray-600">
              {{ successTxt }}
            </div>
          </div>
        </div>
        <div
          id="error-notification-content"
          class="flex hidden toastify-content"
        >
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">ผิดพลาด !!</div>
            <div class="mt-1 text-gray-600">
              {{ errorTxt }}
            </div>
          </div>
        </div>
        <!-- END: Notification Content -->
      </div>
    </div>
    <!-- END: Add/Edit Content Modal -->
  </div>
</template>

<script>
import { ref, defineComponent, onMounted, nextTick } from "vue";
import * as tagService from "/src/services/tag";
import moment from "moment";
import Toastify from "toastify-js";
import { helper as $h } from "@/utils/helper";
export default defineComponent({
  setup() {
    const pageSize = ref([10, 20, 30, 40]);
    const pageSizeSelect = ref(pageSize.value[0]);
    const pages = ref([]);
    const totalPages = ref(0);
    const pageSelect = ref(1);
    const filterList = ref([
      {
        id: 0,
        name: "เลือกดูตามสถานะ"
      }
    ]);
    const filterSelect = ref(filterList.value[0]);
    const selectFilter = filter => {
      filterSelect.value = filter;
      getTags();
    };
    const tags = ref([]);
    const searchContent = ref("");
    const isEdit = ref(false);
    const dataSelect = ref({});
    const nameTag = ref("");
    const tagId = ref(null);
    const params = {};
    const pageLoad = ref(false);
    const setDataSelect = data => {
      dataSelect.value = data;
    };
    const errorTxt = ref("");
    const successTxt = ref("");
    const setDataEdit = data => {
      const isEmpty = Object.keys(data).length === 0;
      isEdit.value = isEmpty ? false : true;
      nameTag.value = isEmpty ? "" : data.name;
      tagId.value = isEmpty ? null : data.id;
    };
    const onSearch = () => {
      pageSelect.value = 1;
      getTags();
    };
    const setDateTime = data => {
      return moment(data).format("DD/MM/YYYY, HH:mm");
    };

    // Pagination
    const paginationSelect = page => {
      pageSelect.value = page;
      getTags();
    };
    onMounted(() => {
      getTags();
    });
    const isDisableSubmit = () => {
      return isNullOrEmpty(nameTag);
    };

    const isNullOrEmpty = val => {
      return val.value === null || val.value === "" || val.value.length == 0;
    };
    const getTags = () => {
      params.search = searchContent.value ? searchContent.value : "";
      params.size = pageSizeSelect.value;
      params.page = pageSelect.value;
      tagService
        .getTagList(params)
        .then(res => {
          tags.value = res.data.data.tags;

          if (res.data.data.tags) {
            tags.value = res.data.data.tags.map((value, index) => {
              value.no =
                index + 1 + (pageSelect.value - 1) * pageSizeSelect.value;
              return value;
            });
          } else {
            tags.value = [];
          }

          const pageResult = $h.calculatePagination({
            totalItems: res.data.data.lastPage * pageSizeSelect.value,
            pageSize: pageSizeSelect.value,
            currentPage: pageSelect.value
          });
          pages.value = pageResult.pages;
          totalPages.value = pageResult.totalPages;
        })
        .catch(() => {
          errorTxt.value = "ไม่สามารถเรียกข้อมูล Tags ได้";
          errorNotificationToggle();
        });
    };
    const createTagName = () => {
      let value = {
        name: nameTag.value
      };
      tagService
        .createTag(value)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          pageLoad.value = false;
          cash("#header-footer-modal-preview").modal("hide");
          successTxt.value = "เพิ่มแท็กเรียบร้อย";
          successNotificationToggle();
          getTags();
        })
        .catch(() => {
          errorTxt.value = "เพิ่มแท็กไม่สำเร็จ";
          errorNotificationToggle();
        });
    };
    const editTagName = () => {
      let value = {
        name: nameTag.value
      };
      tagService
        .editTag(tagId.value, value)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          pageLoad.value = false;
          cash("#header-footer-modal-preview").modal("hide");
          successTxt.value = "แก้ไขแท็กเรียบร้อย";
          successNotificationToggle();
          getTags();
        })
        .catch(() => {
          errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
          errorNotificationToggle();
        });
    };
    const deleteTag = () => {
      tagService
        .deleteTag(dataSelect.value.id)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          pageLoad.value = false;
          successTxt.value = "ลบแท็กเรียบร้อย";
          cash("#delete-confirmation-modal").modal("hide");
          successNotificationToggle();
          pageSelect.value = 1;
          getTags();
        })
        .catch(() => {
          errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
          errorNotificationToggle();
          pageLoad.value = false;
        });
    };

    // Notification
    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };
    return {
      pageSize,
      pageSizeSelect,
      pageSelect,
      pages,
      totalPages,
      filterList,
      filterSelect,
      tags,
      selectFilter,
      searchContent,
      paginationSelect,
      onSearch,
      setDataSelect,
      setDataEdit,
      dataSelect,
      nameTag,
      isEdit,
      createTagName,
      setDateTime,
      errorTxt,
      deleteTag,
      editTagName,
      successTxt,
      pageLoad,
      isDisableSubmit
    };
  }
});
</script>
