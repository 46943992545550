<template>
  <div class="items-center mt-8 intro-y lg:flex-row ">
    <div class="my-2 text-lg font-bold">
      {{ formName }}
    </div>
    <div class="w-1/2 mt-8">
      <div class="flex w-full font-medium">
        <input
          id="tabulator-html-filter-value"
          v-model="searchContent"
          type="text"
          class="w-full form-control"
          placeholder="ค้นหา"
          @keyup.enter="onSearch()"
        />
        <button class="ml-4  btn btn-primary" @click="onSearch()">
          ค้นหา
        </button>
      </div>
    </div>
    <div class="col-span-12 mt-4 overflow-auto intro-y lg:overflow-visible">
      <table class="table -mt-2 table-report">
        <thead>
          <tr>
            <th class="whitespace-nowrap ">รหัสลงทะเบียน</th>
            <th class="whitespace-nowrap">ชื่อและนามสกุล</th>
            <th class="text-center whitespace-nowrap">เพศ</th>
            <th class="text-center whitespace-nowrap">วันเกิด</th>
            <th class="text-center whitespace-nowrap">ช่วงอายุ</th>
            <th class="text-center whitespace-nowrap">เบอร์โทรศัพท์</th>
            <th class="text-center whitespace-nowrap">จัดการ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in formRegisterList" :key="item" class="intro-x">
            <td class="">
              {{ item?.verify_code }}
            </td>
            <td class="">
              {{ item.full_name }}
            </td>
            <td class="text-center">
              {{ item.gender }}
            </td>
            <td class="text-center">
              {{ item.date_of_birth }}
            </td>
            <td class="text-center">{{ item.age }}</td>
            <td class="text-center">
              {{ item.phone_number }}
            </td>
            <td class="text-center">
              <EyeIcon
                class="w-4 "
                v-if="item.register_id"
                @click="onViewMoreDetail(item.register_id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="flex flex-wrap items-center col-span-12 mt-16 intro-y sm:flex-row sm:flex-nowrap"
    >
      <ul class="pagination">
        <li>
          <a class="pagination__link" @click="paginationSelect(1)">
            <ChevronsLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="paginationSelect(pageSelect == 1 ? 1 : pageSelect - 1)"
          >
            <ChevronLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li v-for="page in pages" :key="page" @click="paginationSelect(page)">
          <a
            v-if="pageSelect == page"
            class="pagination__link pagination__link--active"
            >{{ page }}</a
          >
          <a v-else class="pagination__link">{{ page }}</a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="
              paginationSelect(
                pageSelect == totalPages ? totalPages : pageSelect + 1
              )
            "
          >
            <ChevronRightIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a class="pagination__link" @click="paginationSelect(totalPages)">
            <ChevronsRightIcon class="w-4 h-4" />
          </a>
        </li>
      </ul>
      <select
        v-model="pageSizeSelect"
        class="w-20 mt-3 form-select box sm:mt-0"
        @change="onSearch()"
      >
        <option v-for="page in pageSize" :key="page" :value="page">
          {{ page }}
        </option>
      </select>
    </div>

    <div
      id="user-detail-registered-modal"
      class="modal font-kanit"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="mx-auto mt-8 w-1/2">
        <div class="modal-content">
          <div class="p-8 modal-body px-16">
            <div class="text-center mt-6">
              <p class="bold text-lg">รายละเอียดผู้ลงทะเบียน</p>
              <p>ข้อมูลในการกรอกของผู้ลงทะเบียน</p>
            </div>
            <div class="grid grid-rows-3 grid-flow-col gap-3 mt-4">
              <div>
                <p>ชื่อจริง</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.first_name }}
                </p>
              </div>
              <div>
                <p>ช่วงอายุ</p>
                <p class="font-semibold">{{ userRegisterDetail?.age_name }}</p>
              </div>
              <div>
                <p>อาชีพ</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.career_name }}
                </p>
              </div>
              <div>
                <p>นามสกุล</p>
                <p class="font-semibold">{{ userRegisterDetail?.last_name }}</p>
              </div>
              <div>
                <p>เพศ</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.gender_name }}
                </p>
              </div>
              <div>
                <p>วันที่ลงทะเบียน</p>
                <p class="font-semibold">
                  {{
                    $h.formatDate(
                      userRegisterDetail?.created_at,
                      "DD/MM/YYYY HH:mm"
                    )
                  }}
                </p>
              </div>
              <div>
                <p>วันเดือนปีเกิด</p>
                <p class="font-semibold">
                  {{
                    $h.formatDate(
                      userRegisterDetail?.date_of_birth,
                      "DD/MM/YYYY"
                    )
                  }}
                </p>
              </div>
              <div>
                <p>รายได้ครัวเรือน</p>
                <p class="font-semibold">
                  {{ userRegisterDetail?.income_name }}
                </p>
              </div>
            </div>
            <hr class="my-4" />
            <div>
              <p>ความสนใจ</p>
              <div
                v-if="userRegisterDetail?.attentions?.length === 0"
                class="font-semibold"
              >
                -
              </div>
              <p
                v-for="(interest, index) in userRegisterDetail?.attentions"
                :key="index"
                class="font-semibold"
              >
                {{ interest.value }}
              </p>
            </div>
            <hr class="my-4" />
            <div>
              <p>รู้จักชีวมิตรช่องทางไหน</p>
              <div
                v-if="userRegisterDetail?.social_chanels?.length === 0"
                class="font-semibold"
              >
                -
              </div>
              <p
                v-for="(socialChanel,
                index) in userRegisterDetail?.social_chanels"
                :key="index"
                class="font-semibold"
              >
                {{ socialChanel.value }}
              </p>
            </div>
            <hr class="my-4" />
            <div>
              <p>เมื่อติดตามชีวามิตรได้รับประโยชน์อะไรกับชีวิต</p>
              <div
                v-if="userRegisterDetail?.benefits?.length === 0"
                class="font-semibold"
              >
                -
              </div>
              <p
                v-for="(benefit, index) in userRegisterDetail?.benefits"
                :key="index"
                class="font-semibold"
              >
                {{ benefit.value }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import dayjs from "dayjs";
import * as getheringService from "/src/services/gethering";
import { helper as $h } from "@/utils/helper";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const formName = ref("");
    const formRegisterList = ref([]);
    const searchContent = ref("");
    const pageSize = ref([10, 20, 30, 40]);
    const pageSizeSelect = ref(10);
    const totalPages = ref(0);
    const pageSelect = ref(1);
    const pages = ref([1]);
    const userRegisterDetail = ref();
    const errorTxt = ref("");
    const successTxt = ref("");

    onMounted(() => {
      fetchActivityUsers();
    });

    const fetchActivityUsers = async () => {
      const res = await getheringService.getActivitiesFormUserList(
        route.params.id,
        {
          search: searchContent?.value || undefined,
          size: pageSizeSelect.value,
          page: pageSelect.value
        }
      );
      formRegisterList.value = res.data.data.registers;
      formName.value = res.data.data.activity_name;
      const pageResult = $h.calculatePagination({
        totalItems: res.data.data.lastPage * pageSizeSelect.value,
        pageSize: pageSizeSelect.value,
        currentPage: pageSelect.value
      });

      pages.value = pageResult.pages;
      totalPages.value = pageResult.totalPages;
    };
    const onSearch = () => {
      pageSelect.value = 1;
      fetchActivityUsers();
    };
    const paginationSelect = page => {
      pageSelect.value = page;
      fetchActivityUsers();
    };
    const getDateFormat = date =>
      date ? dayjs(date).format("DD/MM/YYYY") : "";

    const onViewMoreDetail = async registerId => {
      const res = await getheringService.getUserByRegisterId(registerId);
      userRegisterDetail.value = res.data.data;
      cash("#user-detail-registered-modal").modal("show");
    };

    const onModalClose = () => {
      cash("#user-detail-registered-modal").modal("hide");
    };

    return {
      formRegisterList,
      searchContent,
      pageSize,
      pageSizeSelect,
      totalPages,
      pageSelect,
      errorTxt,
      successTxt,
      onSearch,
      paginationSelect,
      getDateFormat,
      pages,
      fetchActivityUsers,
      formName,
      onViewMoreDetail,
      onModalClose,
      userRegisterDetail
    };
  }
});
</script>
<style lang="scss" scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
