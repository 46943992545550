import { apiApp } from "/src/config/api";
const endpoint = "admin/activities";

export const getActivityCert = activityId => {
  return apiApp.get(endpoint + `/${activityId}/certificate`);
};
export const updateActivityCert = (activityId, payload) => {
  return apiApp.patch(endpoint + `/${activityId}/certificate`, payload);
};
export const uploadReuqestCert = payload => {
  return apiApp.post("/public/storage/upload", payload);
};
