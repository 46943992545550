<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="mb-2">
    <div class="flex flex-col items-center mt-8 intro-y sm:flex-row">
      <div class="flex items-center mr-auto text-lg font-medium">
        {{ !isEdit ? "เพิ่มบทความ" : "แก้ไขบทความ" }}
      </div>
    </div>
    <div class="py-4 text-base font-bold intro-y">
      รายละเอียดบทความ
    </div>
    <div class="flex flex-col w-full intro-y">
      <div class="form-group">
        <label class="text-sm required">หัวข้อ</label>
        <input
          id="topic"
          v-model="topic"
          type="text"
          class="w-full form-control"
        />
      </div>
      <div class="form-group">
        <label class="text-sm required">คำอธิบายแบบสั้น</label>
        <input
          id="description"
          v-model="description"
          type="text"
          class="w-full form-control"
        />
      </div>
      <div class="form-group">
        <label class="text-sm required">หมวดหมู่บทความ</label>
        <ChipInput
          v-model="selectedCatagory"
          :select-list="catagoryOptions"
          :selected="selectedCatagory"
        ></ChipInput>
      </div>
      <div class="form-group">
        <label class="text-sm required">Tag บทความ</label>
        <ChipInput
          v-model="selectedTags"
          :select-list="tagsOptions"
          :selected="selectedTags"
        ></ChipInput>
      </div>
      <div class="flex w-full flex-row flex-wrap md:flex-nowrap md:gap-4">
        <div class="form-group w-full md:w-1/2">
          <label class="text-sm required">
            URL บทความ (ภาษาอังกฤษพิมพ์เล็ก/ภาษาไทย ตัวเลข และคั่นระหว่างคำด้วย
            - เท่านั้น)
          </label>
          <input
            id="urlSlug"
            v-model="urlSlug"
            :invalid="!isUrlFriendlySEO(urlSlug)"
            placeholder="กรอก URL เช่น cheevemitr-new"
            type="text"
            class="w-full form-control"
          />
          <p v-if="!isUrlFriendlySEO(urlSlug)" class="field-error-noti">
            <InfoIcon class="w-4 h-4 ml-auto sm:ml-2" />
            <span>
              รูปแบบ URL ไม่ถูกต้อง (กรุณาใช้ ภาษาอังกฤษพิมพ์เล็ก ตัวเลข
              และคั่นคำด้วยเครื่องหมาย - )
            </span>
          </p>
        </div>
        <div :class="`form-group w-full ${isEdit ? 'md:w-1/4' : 'md:w-2/4'}`">
          <label class="text-sm required">วันที่เผยแพร่</label>
          <div>
            <Datepicker
              v-model="selectedDate"
              :read-only="isPublished"
              :set-selected="selectedDate"
              class="w-full"
              :diabled-before-today="true"
            ></Datepicker>
          </div>
        </div>
        <div v-if="isEdit" class="form-group w-full md:w-1/4">
          <label class="text-sm">วันที่สร้าง</label>
          <div class="font-semibold flex items-center">
            {{ created_at }}
          </div>
        </div>
      </div>
      <div>
        <label class="required text-base font-bold mb-4 block">รูปปก</label>
        <div class="flex space-x-4 mb-6">
          <label class="flex items-center cursor-pointer">
            <input
              id="cover-image"
              v-model="coverMode"
              class="mr-2 form-check-input"
              type="radio"
              name="cover-mode"
              value="image"
            />
            รูปภาพ
          </label>
          <label class="flex items-center cursor-pointer">
            <input
              id="cover-video"
              v-model="coverMode"
              class="mr-2 form-check-input"
              type="radio"
              name="cover-mode"
              value="video"
            />
            วีดีโอ<small>*เฉพาะ Youtube เท่านั้น</small>
          </label>
        </div>
        <template v-if="coverMode === 'video'">
          <label class="text-xs text-[#5E5873] required"
            >URL Youtube (เช่น https://www.youtube.com/watch?v=n3XJAL4Ex6U)
          </label>
          <input
            id="coverVideoUrl"
            v-model="coverVideoUrl"
            placeholder="URL Youtube (เช่น https://www.youtube.com/watch?v=n3XJAL4Ex6U)"
            type="text"
            class="w-full form-control"
          />
          <template v-if="coverVideoUrl && !isValidYoutubeUrl(coverVideoUrl)">
            <div class="text-red-500 mt-1">
              * รูปแบบลิงก์วีดีโอไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง
            </div>
          </template>
        </template>

        <div class="my-4">
          <label class="required mr-2">รูปภาพหน้าปกการ์ดบทความ</label
          ><small>ขนาดไซต์แนะนำ 1920x1080</small>
        </div>

        <ImageCropper
          v-model="coverImageFile"
          :folder-name-image="folderNameImage"
          :data-image="coverImageFile"
        />
      </div>
      <div class="form-group">
        <div class="flex justify-between space-x-2 items-baseline mb-2">
          <label class="text-base font-bold required">เนื้อหาของบทความ</label>
          <button
            :disabled="isDisableSubmit()"
            :class="{ 'opacity-50': isDisableSubmit() }"
            class="btn btn-primary"
            @click="onSave(false)"
          >
            ดูตัวอย่าง
          </button>
        </div>
        <Editor
          v-if="!loadingContent"
          v-model="contentData"
          :data-content="contentData"
          class="editor font-sarabun"
        ></Editor>
      </div>
      <div class="form-group">
        <label class="text-base font-bold">เรื่องโดย</label>
        <div class="flex items-center space-x-4">
          <div class="provider">
            <div
              class="w-40 h-40 bg-white border-2 border-dashed flex justify-center items-center"
            >
              <img
                v-if="providerImageSrc"
                class="w-30 object-contain h-full bg-center"
                :src="providerImageSrc"
              />
              <span v-else></span>
            </div>
          </div>
          <div class="flex flex-col items-center space-y-2">
            <div class="flex space-x-2">
              <label
                class="flex items-center w-1/2 btn btn-primary custom-file-upload whitespace-nowrap"
              >
                <input
                  type="file"
                  accept="image/*"
                  @change="onSelectProvider"
                />
                อัปโหลด
              </label>
              <button class="w-1/2 btn" @click="resetProviderImage">
                รีเซ็ต
              </button>
            </div>
            <div class="text-dark">ไฟล์ที่แนะนำ PNG หรือ JPG</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="text-sm required">เรื่องโดย</label>
        <input
          id="writer"
          v-model="writer"
          type="text"
          class="w-1/2 form-control"
        />
      </div>
      <div class="form-group">
        <label class="text-sm required">คำอธิบายแบบสั้น</label>
        <textarea
          v-model="shortDescription"
          class="w-full rounded-md resize form-control"
        ></textarea>
      </div>
      <div class="form-group">
        <label class="text-sm">บทความโดยเครือข่าย</label>
        <div class="w-1/2 dropdown">
          <button
            class="flex justify-between w-full bg-white dropdown-toggle btn btn-outline-secondary"
            aria-expanded="false"
          >
            <div>
              {{ selectedNetwork.text }}
            </div>
            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
          </button>
          <div class="w-full dropdown-menu">
            <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
              <a
                key="no-selected"
                href="javascript:;"
                data-dismiss="dropdown"
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                @click="
                  selectNetwork({ value: null, text: 'ไม่เลือกเครือข่าย' })
                "
              >
                ไม่เลือกเครือข่าย
              </a>
              <a
                v-for="network in networkOption"
                :key="network.value"
                href="javascript:;"
                data-dismiss="dropdown"
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                @click="selectNetwork(network)"
              >
                {{ network.text }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="text-sm"
          ><span class="text-base font-bold">สนับสนุนบทความโดย </span></label
        >
        <DropAnImage
          v-model="sponsorImages"
          :multiple="true"
          desc-label="(อัพโหลดรูปสำหรับใช้เป็นรูปภาพสนับสนุนบทความ, ไฟล์รูปแนะนำ JPG , PNG ขนาด 1:1)"
          :folder-name-image="folderNameImage"
          :data-image="sponsorImages"
          :is-edit="isEdit"
        ></DropAnImage>
      </div>
      <div class="flex py-8 space-x-3">
        <button
          :disabled="isDisableSubmit()"
          :class="{ 'opacity-50': isDisableSubmit() }"
          class="btn btn-primary"
          @click="onSave(true)"
        >
          บันทึกบทความ
          <LoadingIcon
            v-if="loadPage"
            icon="oval"
            color="white"
            class="w-4 h-4 ml-2"
          />
        </button>
        <!-- <button
          class="bg-white btn btn-outline-secondary"
          @click="onSave(false)"
        >
          ดูตัวอย่าง
        </button> -->
        <router-link :to="{ path: '/article', query: route.query }">
          <button class=" btn border-dark-1">ยกเลิก</button>
        </router-link>
      </div>
    </div>
    <!-- BEGIN: Notification Content -->
    <div id="success-notification-content" class="flex hidden toastify-content">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">สำเร็จ !!</div>
        <div class="mt-1 text-gray-600">
          {{ successText }}
        </div>
      </div>
    </div>
    <div id="error-notification-content" class="flex hidden toastify-content">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          {{ errorTxt }}
        </div>
      </div>
    </div>
    <!-- END: Notification Content -->
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, nextTick } from "vue";
import ChipInput from "@/global-components/chips/ChipInput.vue";
import Datepicker from "@/global-components/date-picker/Main.vue";
import DropAnImage from "@/global-components/drop-image/Main.vue";
import { useRoute, useRouter } from "vue-router";
import * as articleService from "/src/services/article";
import dayjs from "dayjs";
import Toastify from "toastify-js";
import Editor from "@/global-components/quill-editor/Main.vue";
import * as imageService from "/src/services/upload";
import isUrlFriendlySEO from "@/composables/useCheckUrlSEO.js";
import { helper as $h } from "@/utils/helper";
import ImageCropper from "@/global-components/image-cropper/Main.vue";

export default defineComponent({
  components: {
    ChipInput,
    Datepicker,
    DropAnImage,
    Editor,
    ImageCropper
  },
  setup() {
    var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
    dayjs.extend(isSameOrAfter);
    const route = useRoute();
    const router = useRouter();
    const loadPage = ref(false);
    const selectedCatagory = ref(null);
    const selectedTags = ref(null);
    const selectedDate = ref(null);
    const coverImageFile = ref(null);
    const articleDetails = ref("");
    const providerImage = ref(null);
    const providerImageSrc = ref(null);
    const topic = ref(null);
    const description = ref(null);
    const writer = ref(null);
    const shortDescription = ref(null);
    const sponsorImages = ref(null);
    const articleId = ref(null);
    const isEdit = ref(false);
    const isPublished = ref(false);
    const errorTxt = ref("");
    const successText = ref("");
    const quill = ref(null);
    const folderNameImage = ref("article");
    const networkOption = ref([]);
    const selectedNetwork = ref({
      text: "ไม่เลือกเครือข่าย",
      value: null
    });
    const catagoryOptions = ref([]);
    const urlSlug = ref("");

    const tagsOptions = ref([]);
    const contentData = ref("");
    const published_date = ref("");
    const created_at = ref("");
    const loadingContent = ref(false);

    /**
     * using to change input form of cover, value is 'image' and 'video'
     * @default "image"
     */
    const coverMode = ref("image");

    /**
     * only avaliable when coverMode = "video"
     */
    const coverVideoUrl = ref(null);

    onMounted(() => {
      nextTick(async () => {
        await getTag();
        await getCategories();
        await getNetworks();
        articleId.value = parseInt(route.params.id)
          ? parseInt(route.params.id)
          : null;
        isEdit.value = articleId.value != null;
        if (articleId.value) {
          await getDetailArticle();
        }
      });
    });
    const onSelectProvider = e => {
      const file = e.target.files[0];
      const isImageFile = !file || file.type.indexOf("image/") >= 0;
      if (!isImageFile) {
        return;
      }

      const isFileSizeOver = file.size > 5000000;
      if (isFileSizeOver) {
        e.preventDefault();
        return;
      }
      var reader = new FileReader();
      reader.onload = f => {
        providerImageSrc.value = f.target.result;
      };
      reader.readAsDataURL(file);
      onCallUrl(file);
    };
    const onCallUrl = async file => {
      let setDataImage = {
        folder: folderNameImage.value,
        file_name: file.name
      };
      const res = await imageService.uploadImage(setDataImage);
      let result = res.data.data;
      await $h.uploadImageToStorage(result.urlUpload, file);
      setImage(result.urlPublic);
    };
    const setImage = url => {
      providerImageSrc.value = url;
      providerImageSrc.value = url;
    };
    const resetProviderImage = () => {
      providerImage.value = null;
      providerImageSrc.value = null;
    };

    const selectNetwork = network => {
      selectedNetwork.value = network;
    };

    const onSave = isPublishedPost => {
      !isPublishedPost ? (loadPage.value = false) : (loadPage.value = true);
      sponsorImages.value = JSON.parse(JSON.stringify(sponsorImages.value));
      let setImageArray = [];
      if (sponsorImages.value != null) {
        sponsorImages.value.map(val => {
          if (val.image_url) {
            setImageArray.push({ image_url: val.image_url });
          }
        });
      }
      let setTagIds = [];
      let setCatergoryIds = [];

      setTagIds = selectedTags.value.map(val => ({ id: val.value }));
      setCatergoryIds = selectedCatagory.value.map(val => ({ id: val.value }));

      const article = {
        title: topic.value,
        description: description.value,
        image_url: coverImageFile.value.image_url
          ? coverImageFile.value.image_url
          : coverImageFile.value,
        content: contentData.value,
        author_name: writer.value,
        author_image_url: providerImageSrc.value ? providerImageSrc.value : "",
        author_description: shortDescription.value,
        network_id: selectedNetwork.value.value,
        is_publish: true,
        tag_ids: setTagIds,
        category_ids: setCatergoryIds,
        publish_date: dayjs(selectedDate.value),
        post_sponsors: setImageArray,
        url_slug: urlSlug.value,
        video_url:
          coverMode.value === "video" ? coverVideoUrl.value.trim() : null
      };

      if (!isPublishedPost) {
        window.localStorage.setItem("article", JSON.stringify(article));
        let routeData = router.resolve({ name: "preview-article" });
        window.open(routeData.href, "_blank");
      } else {
        if (isEdit.value) {
          onEdit(article);
        } else {
          onCreate(article, isPublishedPost);
        }
      }
    };
    const onCreate = async (form, isPublishedPost) => {
      try {
        const res = await articleService.createArticle(form);
        if (res?.data?.data?.errors) {
          cash("#header-footer-modal-preview").modal("hide");
          errorTxt.value = res.data.data.errors.message;
          errorNotificationToggle();
          return;
        }
        successText.value = isPublishedPost
          ? "เพิ่มบทความเรียบร้อยแล้ว"
          : "เพิ่มบทความแบบร่างเรียบร้อยแล้ว";
        cash("#header-footer-modal-preview").modal("hide");
        successNotificationToggle();
        router.push({ path: "/article", query: route.query });
      } catch (err) {
        cash("#header-footer-modal-preview").modal("hide");
        if (err.response && err.response.status == 409) {
          errorTxt.value = "ไม่สามารถเพิ่มได้, เนื่องจากมีบทความอยู่ในระบบแล้ว";
          errorNotificationToggle();
        } else {
          errorTxt.value =
            "เพิ่มบทความไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
          errorNotificationToggle();
          loadPage.value = false;
        }
      } finally {
        loadPage.value = false;
      }
    };
    const onEdit = async form => {
      try {
        const res = await articleService.editArticle(form, articleId.value);
        if (res?.data?.data?.errors) {
          cash("#header-footer-modal-preview").modal("hide");
          errorTxt.value = res.data.data.errors.message;
          errorNotificationToggle();
          return;
        }
        successText.value = "แก้ไขบทความเรียบร้อยแล้ว";
        cash("#header-footer-modal-preview").modal("hide");
        successNotificationToggle();
        router.push({ path: "/article", query: route.query });
      } catch (err) {
        cash("#header-footer-modal-preview").modal("hide");
        errorTxt.value =
          "แก้ไขบทความไม่สำเร็จ, กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        loadPage.value = false;
      }
    };
    const getTag = async () => {
      try {
        const result = await articleService.getTag();
        result.data.data.tags.map(val => {
          (val.text = val.name), (val.value = val.id);
          tagsOptions.value.push({
            text: val.name,
            value: val.id
          });
        });
      } catch (error) {
        console.log("error", error);
      }
    };
    const getCategories = async () => {
      try {
        const result = await articleService.getCategories();
        result.data.data.map(val => {
          (val.text = val.name), (val.value = val.id);
          catagoryOptions.value.push({
            text: val.name,
            value: val.id
          });
        });
      } catch (error) {
        console.log("error", error);
      }
    };
    const getNetworks = async () => {
      // *********** Waiting API **************
      try {
        const result = await articleService.getNetworks();
        networkOption.value = await result.data.data.networks.map(val => {
          return { text: val.name, value: val.id };
        });
      } catch (error) {
        console.log("error", error);
      }
    };
    const setArrayData = array => {
      array.map(val => {
        val.value = val.id;
        val.text = val.name;
      });
      return array;
    };
    const getDetailArticle = () => {
      loadingContent.value = true;
      articleService
        .getDetailArticle(articleId.value)
        .then(res => {
          let result = res.data.data;
          let arrayNetwork = JSON.parse(JSON.stringify(networkOption));
          arrayNetwork._value.map(value => {
            if (result.network_id == value.value) {
              selectedNetwork.value = value;
            }
          });
          selectedTags.value = setArrayData(result.tag_ids);
          topic.value = result.title;
          description.value = result.description;
          selectedCatagory.value = setArrayData(result.category_ids);
          selectedDate.value = dayjs(result.publish_date).format("YYYY-MM-DD");
          published_date.value = result.publish_date;
          coverImageFile.value = result.image_url;
          contentData.value = result.content;
          providerImageSrc.value = result.author_image_url;
          writer.value = result.author_name;
          shortDescription.value = result.author_description;
          sponsorImages.value = result.post_sponsors;
          isPublished.value = result.is_publish;
          created_at.value = dayjs(result.created_at).format("YYYY-MM-DD");
          urlSlug.value = result.url_slug;
          loadingContent.value = false;
          coverMode.value = result.video_url ? "video" : "image";
          coverVideoUrl.value = result.video_url;
        })
        .catch(() => {
          loadingContent.value = false;
        });
    };

    const isDisableSubmit = () => {
      return (
        isNullOrEmpty(topic) ||
        isNullOrEmpty(description) ||
        isNullOrEmpty(selectedCatagory) ||
        isNullOrEmpty(selectedTags) ||
        isNullOrEmpty(writer) ||
        isNullOrEmpty(shortDescription) ||
        isNullOrEmpty(selectedNetwork) ||
        isNullOrEmpty(contentData) ||
        isNullOrEmpty(coverImageFile) ||
        loadPage.value ||
        isNullOrEmpty(urlSlug) ||
        !isUrlFriendlySEO(urlSlug.value) ||
        (coverMode.value === "video" &&
          (isNullOrEmpty(coverVideoUrl) ||
            !isValidYoutubeUrl(coverVideoUrl.value)))
      );
    };

    const isNullOrEmpty = val => {
      return val.value === null || val.value === "" || val.value.length == 0;
    };
    // Notification
    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const isValidYoutubeUrl = url => {
      const youtubePattern = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=[\w-]+(&\S*)?|^https?:\/\/youtu\.be\/[\w-]+$/;
      return youtubePattern.test(url.trim());
    };

    return {
      catagoryOptions,
      networkOption,
      tagsOptions,
      selectedCatagory,
      selectedTags,
      selectedDate,
      selectedNetwork,
      coverImageFile,
      articleDetails,
      providerImage,
      providerImageSrc,
      topic,
      description,
      shortDescription,
      writer,
      sponsorImages,
      onSelectProvider,
      resetProviderImage,
      selectNetwork,
      onSave,
      isDisableSubmit,
      isNullOrEmpty,
      getDetailArticle,
      onEdit,
      errorTxt,
      successText,
      successNotificationToggle,
      errorNotificationToggle,
      setArrayData,
      isEdit,
      folderNameImage,
      contentData,
      quill,
      onCallUrl,
      loadPage,
      getTag,
      published_date,
      created_at,
      isUrlFriendlySEO,
      urlSlug,
      loadingContent,
      isPublished,
      route,

      coverMode,
      coverVideoUrl,
      isValidYoutubeUrl
    };
  }
});
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";

.form-group {
  @apply flex flex-col space-y-1 mb-4;
}
label.required::after {
  content: " *";
  color: red;
}
.custom-file-upload {
  input {
    display: none;
  }
  border: 1px solid #ccc;
  cursor: pointer;
}
</style>
