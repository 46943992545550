<template>
  <div class="table intro-y">
    <div class="col-span-12 mt-4 overflow-auto intro-y lg:overflow-visible">
      <table class="table -mt-2 table-report">
        <tbody>
          <tr v-for="(item, index) in sponsorLists" :key="item" class="intro-x">
            <td v-if="isBanner">
              <div class="flex">
                <AlignJustifyIcon class="w-6 h-6" />
              </div>
            </td>
            <td class="w-20">
              <div class="flex justify-center">
                {{ index + 1 }}
              </div>
            </td>
            <td class="text-center w-38">
              <img class="w-32 " :src="item.image_url" alt="" />
            </td>
            <td class="">
              <div class="flex">
                {{ item.category }}
              </div>
            </td>
            <td class="">
              <div class="flex truncate">
                {{ item.title }}
              </div>
            </td>
            <td class="table-report__action">
              <div class="flex justify-center space-x-4 items-end">
                <router-link :to="`/banner/sponsor-edit/${item.id}`">
                  <button class="flex items-end">
                    <EditIcon class="w-4 h-4" />
                  </button>
                </router-link>
                <a
                  class="flex items-center"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-confirmation-modal"
                  @click="setDelete(item, index)"
                >
                  <Trash2Icon class="w-4 h-4" />
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    arrayData: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      sponsorLists: []
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    arrayData(value) {
      this.sponsorLists = JSON.parse(JSON.stringify(value));
    }
  },
  methods: {
    setDelete(element, index) {
      this.$emit("setDelete", element, index);
    }
  }
};
</script>
