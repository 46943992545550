<template>
  <div class="items-center mt-8 intro-y lg:flex-row ">
    <div class="my-2 text-lg font-bold">แบบทดสอบ</div>
    <div class="flex justify-between">
      <div class="w-1/2">
        <div class="flex w-full font-medium">
          <input
            id="tabulator-html-filter-value"
            v-model="searchContent"
            type="text"
            class="w-full form-control"
            placeholder="ค้นหากิจกรรม"
            @keyup.enter="onSearch()"
          />
          <button class="ml-4  btn btn-primary" @click="onSearch()">
            ค้นหา
          </button>
        </div>
      </div>
      <!-- <a
        class="ml-4  btn btn-primary"
        href="javascript:;"
        data-toggle="modal"
        data-target="#detail-registed-modal"
        @click="setSeeDetailEvent()"
      >
        นำเข้าข้อมูล
      </a> -->
    </div>
    <div class="col-span-12 mt-4 overflow-auto intro-y lg:overflow-visible">
      <table class="table -mt-2 table-report">
        <thead>
          <tr>
            <th class="whitespace-nowrap ">ชื่อกิจกรรม</th>
            <th class="w-32 whitespace-nowrap">วันที่อัพเดทข้อมูลล่าสุด</th>
            <th class="w-20 whitespace-nowrap">จัดการ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in formRegisterList" :key="item" class="intro-x">
            <td class="truncate max-w-xl">
              {{ item.title }}
            </td>
            <td class="text-center">
              {{ getDateFormat(item.uploaded_test_at) }}
            </td>
            <td>
              <div class="flex justify-center text-center">
                <router-link :to="`/document/example-form/lesson/${item.id}`">
                  <EyeIcon class="w-4 " />
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="flex flex-wrap items-center col-span-12 mt-16 intro-y sm:flex-row sm:flex-nowrap"
    >
      <ul class="pagination">
        <li>
          <a class="pagination__link" @click="paginationSelect(1)">
            <ChevronsLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="paginationSelect(pageSelect == 1 ? 1 : pageSelect - 1)"
          >
            <ChevronLeftIcon class="w-4 h-4" />
          </a>
        </li>
        <li v-for="page in pages" :key="page" @click="paginationSelect(page)">
          <a
            v-if="pageSelect == page"
            class="pagination__link pagination__link--active"
            >{{ page }}</a
          >
          <a v-else class="pagination__link">{{ page }}</a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="
              paginationSelect(
                pageSelect == totalPages ? totalPages : pageSelect + 1
              )
            "
          >
            <ChevronRightIcon class="w-4 h-4" />
          </a>
        </li>
        <li>
          <a class="pagination__link" @click="paginationSelect(totalPages)">
            <ChevronsRightIcon class="w-4 h-4" />
          </a>
        </li>
      </ul>
      <select
        v-model="pageSizeSelect"
        class="w-20 mt-3 form-select box sm:mt-0"
        @change="onSearch()"
      >
        <option v-for="page in pageSize" :key="page" :value="page">
          {{ page }}
        </option>
      </select>
    </div>

    <!-- BEGIN: Notification Content -->
    <div id="success-notification-content" class="flex hidden toastify-content">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">สำเร็จ !!</div>
        <div class="mt-1 text-gray-600">
          {{ notificationMessage }}
        </div>
      </div>
    </div>
    <div id="error-notification-content" class="flex hidden toastify-content">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">ผิดพลาด !!</div>
        <div class="mt-1 text-gray-600">
          {{ notificationMessage }}
        </div>
      </div>
    </div>
    <!-- END: Notification Content -->
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import * as getheringService from "/src/services/gethering";
import { helper as $h } from "@/utils/helper";
import dayjs from "dayjs";

export default defineComponent({
  setup() {
    const formRegisterList = ref([]);
    const searchContent = ref("");
    const pageSize = ref([10, 20, 30, 40]);
    const pageSizeSelect = ref(10);
    const totalPages = ref(0);
    const pageSelect = ref(1);
    const detailEventID = ref(null);
    const errorTxt = ref("");
    const successTxt = ref("");
    const file = ref(null);
    const pages = ref([1]);
    const params = {};
    const activities = ref([]);
    const selectedActivity = ref();
    const isLoading = ref(false);

    onMounted(() => {
      fetchActivities();
      fetchActivitiesForm();
    });

    const paginationSelect = page => {
      pageSelect.value = page;
      fetchActivitiesForm();
    };

    const getDateFormat = date =>
      date ? dayjs(date).format("DD/MM/YYYY, HH:mm") : "";

    const setSeeDetailEvent = id => {
      detailEventID.value = id;
    };
    const onBrowse = event => {
      file.value = event.target.files[0];
    };

    const onSearch = () => {
      pageSelect.value = 1;
      fetchActivitiesForm();
    };
    const selectMenu = status => {
      selectedActivity.value = status;
    };

    const fetchActivitiesForm = async () => {
      params.search = searchContent.value ? searchContent.value : undefined;
      params.size = pageSizeSelect.value;
      params.page = pageSelect.value;
      const res = await getheringService.getActivitiesForm(params);
      formRegisterList.value = res.data.data.forms;
      if (res.data.data.forms) {
        formRegisterList.value = res.data.data.forms.map((item, index) => {
          item.no = index + 1 + (pageSelect.value - 1) * pageSizeSelect.value;
          return item;
        });
      } else {
        formRegisterList.value = [];
      }
      const pageResult = $h.calculatePagination({
        totalItems: res.data.data.lastPage * pageSizeSelect.value,
        pageSize: pageSizeSelect.value,
        currentPage: pageSelect.value
      });
      pages.value = pageResult.pages;
      totalPages.value = pageResult.totalPages;
    };

    const fetchActivities = async () => {
      const result = await getheringService.getList();
      activities.value = result.data.data.activities;
    };

    const onModalClose = () => {
      cash("#detail-registed-modal").modal("hide");
    };

    return {
      formRegisterList,
      searchContent,
      pageSize,
      pageSizeSelect,
      totalPages,
      pageSelect,
      errorTxt,
      successTxt,
      onSearch,
      paginationSelect,
      getDateFormat,
      setSeeDetailEvent,
      pages,
      onBrowse,
      file,
      fetchActivities,
      params,
      activities,
      selectMenu,
      selectedActivity,
      onModalClose,
      isLoading
    };
  }
});
</script>
<style scoped lang="scss">
.form-group {
  @apply flex flex-col space-y-1 mb-4;
}
label.required::after {
  content: " *";
  color: red;
}
.dropdown-menu.show {
  z-index: 11111;
}
</style>
