import Chart from "./chart/Main.vue";
import GoogleMapLoader from "./google-map-loader/Main.vue";
import Highlight from "./highlight/Main.vue";
import Litepicker from "./litepicker/Main.vue";
import Tippy from "./tippy/Main.vue";
import TippyContent from "./tippy-content/Main.vue";
import TailSelect from "./tail-select/Main.vue";
import LoadingIcon from "./loading-icon/Main.vue";
import TinySlider from "./tiny-slider/Main.vue";
import CKEditor from "./ckeditor/Main.vue";
import Dropzone from "./dropzone/Main.vue";
import FullCalendar from "./calendar/Main.vue";
import FullCalendarDraggable from "./calendar/Draggable.vue";
import * as featherIcons from "@zhuowenli/vue-feather-icons";
import Chip from "./chips/Chip.vue";
import ChipInput from "./chips/ChipInput.vue";
import DatePicker from "./date-picker/Main.vue";
import DropImage from "./drop-image/Main.vue";
import Quill from "quill";
import { QuillEditor } from "@vueup/vue-quill";
import ImageUploader from "quill-image-uploader";
import ImageResize from "quill-image-resize-module";
import vSelect from "vue-select";
import Multiselect from "@vueform/multiselect";

import "@vueform/multiselect/themes/default.css";

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);

export default app => {
  app.component("Chart", Chart);
  app.component("GoogleMapLoader", GoogleMapLoader);
  app.component("Highlight", Highlight);
  app.component("Litepicker", Litepicker);
  app.component("Tippy", Tippy);
  app.component("TippyContent", TippyContent);
  app.component("TailSelect", TailSelect);
  app.component("LoadingIcon", LoadingIcon);
  app.component("TinySlider", TinySlider);
  app.component("Dropzone", Dropzone);
  app.component("CKEditor", CKEditor);
  app.component("FullCalendar", FullCalendar);
  app.component("FullCalendarDraggable", FullCalendarDraggable);
  app.component("Chip", Chip);
  app.component("ChipInput", ChipInput);
  app.component("DatePicker", DatePicker);
  app.component("DropImage", DropImage);
  app.component("QuillEditor", QuillEditor);
  app.component("VSelect", vSelect);
  app.component("Multiselect", Multiselect);

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = "24";
    app.component(key, icon);
  }
};
