<template>
  <div>
    <!-- Cover -->
    <div
      class="knowledge-cover w-full max-w-[840px] mx-auto relative lg:px-3"
      :style="{ aspectRatio: 16 / 9 }"
    >
      <template v-if="article.video_url">
        <YouTube
          ref="youtube"
          :src="article.video_url"
          width="100%"
          height="100%"
        />
      </template>
      <template v-else>
        <img
          class="absolute z-0 w-full h-full object-cover"
          :src="article.image_url"
          alt="knowledge  cover"
        />
      </template>
    </div>

    <div class="mx-auto font-sarabun">
      <div class="bg-white">
        <div class="content-container px-3">
          <div>
            <div class="article-header">
              <div>{{ dateBuddhist(new Date()) }}</div>
              <div>
                <img src="@/assets/images/heart.svg" />
                <span>0</span>
              </div>
              <div>
                <span class="hidden sm:block">Share</span>
                <img src="@/assets/images/facebook.svg" />
                <img src="@/assets/images/line.svg" />
              </div>
            </div>

            <!-- Breadcrumb -->
            <div class="relative flex flex-nowrap text-sm">
              <div
                class="items-center flex mr-2 whitespace-nowrap flex-shrink-0 cursor-pointer"
                :style="'color: #383838'"
              >
                <span>
                  คลังความรู้
                </span>
                <span class="ml-2">
                  <svg
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 10.5L5 6L1 1.5"
                      stroke="#383838"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>

              <div
                class="items-center truncate flex-grow flex"
                :style="'color: #909B99'"
              >
                {{ article.title }}
              </div>
            </div>

            <!-- Title -->
            <p
              class="text-theme-1 text-3xl lg:text-4xl text-primary mt-5 leading-1 font-kanit"
            >
              {{ article.title }}
            </p>

            <p class="mt-6 text-base text-black">{{ article.description }}</p>
            <div class="shot-line my-5"></div>
            <div class="mb-6 text-base">
              {{ `เรื่องโดย  ${article.author_name}` }}
            </div>
            <div class="ql-snow mb-12">
              <div class="ql-editor p-0" v-html="article.content"></div>
            </div>

            <!-- Author -->
            <div class="flex flex-col md:flex-row ">
              <div class="h-28 w-28 md:h-48 md:w-48 rounded-full mr-10">
                <img
                  :src="article.author_image_url"
                  :style="{
                    width: 150,
                    height: 150
                  }"
                  class="rounded-full object-cover"
                  alt="ชีวามิตร"
                />
              </div>

              <div class="mt-5 md:mt-0 md:max-w-lg">
                <div>เรื่องโดย</div>
                <div class="text-2xl font-semibold my-3 font-kanit">
                  {{ article.author_name }}
                </div>
                <div>{{ article.author_description }}</div>
              </div>
            </div>

            <!-- Sponsor -->
            <div>
              <hr class="my-10 border-black" />
              <div>
                <div class="text-2xl font-kanit">
                  สนับสนุนกิจกรรมโดย
                </div>
                <div class="flex space-x-5 lg:space-x-10 my-5">
                  <div
                    v-for="sponsor in article.post_sponsors"
                    :key="sponsor.image_url"
                    class="relative rounded-full h-24 w-24 md:h-36 md:w-36"
                  >
                    <img
                      :src="sponsor.image_url"
                      class="rounded-full object-cover"
                      alt="ชีวามิตร"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import noImage from "@/assets/images/no-image.jpg";
import DOMPurify from "dompurify";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import YouTube from "vue3-youtube";

export default defineComponent({
  components: {
    YouTube
  },
  setup() {
    const article = ref({});
    onMounted(() => {
      article.value = JSON.parse(localStorage.getItem("article") || "{}");
      console.log("article", article.value);
    });

    const imgUrlAlt = event => {
      event.target.src = noImage;
    };

    function dateBuddhist() {
      return new Date().toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    }

    const content = computed(() => DOMPurify.sanitize(article.content));

    return {
      article,
      imgUrlAlt,
      dateBuddhist,
      content
    };
  },
  mounted() {
    // Override global backgound color & gap spacing
    document.querySelector("html").style.backgroundColor = "white";
    document.querySelector("body").style.padding = "0px";
  }
});
</script>
<style lang="scss" scoped>
@import "@/assets/sass/main.scss";

.shot-line {
  width: 50px;
  height: 2px;
  @apply bg-theme-1;
}

.content-container {
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
}

.cover-image {
  max-height: 667px;
}

.article-ref-box {
  padding-top: 3em;
  padding-bottom: 5em;
  border-bottom: 1px solid #000;
  margin-bottom: 5em;
}
.article-ref-title {
  font-size: 18px;
}

.article-ref-description {
  font-size: 1.2em;
  line-height: 1.5em;
}

%sub-box-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > img {
    width: 20px;
    height: 20px;
    margin: 8px;
  }
  span {
    padding: 8px 0px;
    font-family: Kanit;
    font-size: 18px;
  }
}

.article-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 2em;
  @media screen and(max-width: 650px) {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  > div {
    flex: 1;
    &:nth-child(1) {
      font-size: 1.2em;
    }
    &:nth-child(2) {
      @extend %sub-box-header;
      justify-content: center;
    }
    &:nth-child(3) {
      @extend %sub-box-header;
      justify-content: flex-end;
    }
  }
}

.ql-editor {
  height: unset;
}

.knowledge-cover {
  max-width: 840px;
}

:deep(.knowledge-cover) {
  > div {
    width: 100%;
    height: 100%;
  }
}
</style>
