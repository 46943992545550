import { apiApp } from "/src/config/api";
const endpoint = "admin/posts";
const endpoint2 = "admin/post";
const endpoint3 = "admin/categories";
const endpoint4 = "admin";
const endpoint5 = "public";

const getList = params => {
  return apiApp.get(endpoint, { params });
};
const getTag = () => {
  return apiApp.get(endpoint4 + "/tags");
};
const getCategories = () => {
  return apiApp.get(endpoint4 + "/categories");
};
const getNetworks = (typeId = 2) => {
  return apiApp.get(endpoint5 + `/networks?typeID=${typeId}`);
};
const getListByOrder = () => {
  return apiApp.get(endpoint2 + "/pins");
};
const createArticle = form => {
  return apiApp.post(endpoint2, form);
};
const getListCatergory = () => {
  return apiApp.get(endpoint3);
};
const editBanner = (form, id) => {
  return apiApp.post(endpoint2 + "/" + id, form);
};
const editArticle = (form, id) => {
  return apiApp.put(endpoint2 + `/${id}`, form);
};
const deleteArticle = id => {
  return apiApp.delete(endpoint2 + `/${id}`);
};
const getDetailArticle = id => {
  return apiApp.get(endpoint2 + `/${id}`);
};
const updatePin = id => {
  return apiApp.patch(endpoint2 + `/${id}/pin`);
};
const orderArticle = order => {
  return apiApp.patch(endpoint2 + "/order-pin", { id: order });
};
const updateStatusIsPublished = id => {
  return apiApp.patch(endpoint2 + `/${id}/status`);
};

export {
  getList,
  createArticle,
  deleteArticle,
  getDetailArticle,
  editArticle,
  updatePin,
  orderArticle,
  getListByOrder,
  editBanner,
  updateStatusIsPublished,
  getListCatergory,
  getTag,
  getCategories,
  getNetworks
};
