import { apiApp } from "/src/config/api";

const endpoint = "admin/dashboard";

const getDashboadProvinces = () => apiApp.get(`${endpoint}/provinces`);
const getDashboadUsers = () => apiApp.get(`${endpoint}/users`);
const getDashboadAttentions = () => apiApp.get(`${endpoint}/attentions`);
const getDashboadOccupations = () => apiApp.get(`${endpoint}/occupations`);

export {
  getDashboadProvinces,
  getDashboadUsers,
  getDashboadAttentions,
  getDashboadOccupations
};
