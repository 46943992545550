<template>
  <div class="items-center mt-8 intro-y lg:flex-row ">
    <div class="my-2 text-xl font-bold">
      {{ activityName }}
    </div>
    <div class="flex justify-between mt-8">
      <div class="my-2  text-lg font-bold">
        รายการบทเรียน
      </div>
      <a
        class="flex items-center mr-3 "
        href="javascript:;"
        data-toggle="modal"
        data-target="#edit-content-modal"
      >
        <button class="ml-4 shadow-md btn btn-primary">
          เพิ่มบทเรียน
        </button>
      </a>
    </div>
    <div class="col-span-12 mt-4 overflow-auto intro-y lg:overflow-visible">
      <table class="table -mt-2 table-report">
        <thead>
          <tr>
            <th class="w-10 whitespace-nowrap">บทเรียนที่</th>
            <th class="whitespace-nowrap ">ชื่อบทเรียน</th>
            <th class="w-32 whitespace-nowrap">วันที่อัพเดทข้อมูลล่าสุด</th>
            <th class="w-20 whitespace-nowrap">จัดการ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in lessonList" :key="item" class="intro-x">
            <td class="text-center">
              {{ index + 1 }}
            </td>
            <td class="max-w-xl truncate">
              {{ item.name }}
            </td>
            <td class="text-center">
              {{ getDateFormat(item.uploaded_at) }}
            </td>
            <td class="flex justify-end text-center">
              <div
                class="flex items-center mr-3 cursor-pointer"
                @click="onEditLesson(item)"
              >
                <EditIcon class="w-4 h-4 mr-1" />
              </div>
              <router-link
                :to="
                  `/document/example-form/lesson/${item.activity_id}/example/${item.id}`
                "
                class="mr-3"
              >
                <EyeIcon class="w-4 " />
              </router-link>
              <a
                class="flex items-center "
                href="javascript:;"
                data-toggle="modal"
                data-target="#delete-confirmation-modal"
                @click="onConfirmDeleteLesson(item)"
              >
                <Trash2Icon class="w-4 h-4 mr-1" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      id="detail-registed-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="w-1/3 mx-auto mt-8">
        <div class="modal-content">
          <div class="flex items-center justify-between p-2 px-4 bg-gray-200">
            <div>นำเข้าข้อมูล</div>
            <!-- <div>
              <XIcon />
            </div> -->
          </div>
          <div class="p-4 modal-body intro-x">
            <label class="required"
              >ข้อมูล <span class="text-red-500">*</span></label
            >

            <div class="flex">
              <div class="mt-2 mr-6 form-check">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="vertical_radio_button"
                  value="vertical-radio-chris-evans"
                />
                <label class="form-check-label" for="radio-switch-1"
                  >อัพเดทไฟล์เดิม</label
                >
              </div>
              <div class="mt-2 form-check">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="vertical_radio_button"
                  value="vertical-radio-liam-neeson"
                />
                <label class="form-check-label" for="radio-switch-2"
                  >สร้างใหม่</label
                >
              </div>
            </div>
            <div class="mt-4 dropdown">
              <button
                class="flex justify-between w-full dropdown-toggle btn btn-outline-secondary"
                aria-expanded="false"
              >
                {{ "เลือกหัวข้อ" }}
                <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
              </button>
              <!-- <div class="w-40 dropdown-menu">
                <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                  <a
                    v-for="status in eventStatus"
                    :key="status.key"
                    href="javascript:;"
                    data-dismiss="dropdown"
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                    @click="selectFilterByStaus(status)"
                  >
                    {{ status.name }}
                  </a>
                </div>
              </div> -->
            </div>
            <div class="mt-4">
              <label class="required "
                >เลือกไฟล์ <span class="text-red-500">*</span></label
              >
              <div class="w-full">
                <div class="input-file-upload-wrapper">
                  <input
                    name="file-upload-field"
                    type="file"
                    class="file-upload-field"
                    value=""
                  />
                </div>
              </div>
            </div>
          </div>
          <hr class="my-2" />
          <div class="p-4">
            <button class="btn btn-primary w-28">
              ตกลง
            </button>
            <button class="ml-4 btn w-28">
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <Trash2Icon class="w-16 h-16 mx-auto mt-3 text-red-500" />
              <div class="mt-5 text-xl">ลบบทเรียน ?</div>
              <div class="mt-2 text-gray-700 text-md">
                คุณต้องการลบบทเรียน, ใช่หรือไม่ ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" class="mr-4 btn btn-danger w-28">
                <div v-if="!pageLoad" @click="onDeleteLesson()">
                  ใช่, ลบรายการ
                </div>
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 btn btn-outline-secondary"
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
    <!-- BEGIN: Add/Edit Content Modal -->
    <div
      id="edit-content-modal"
      class="modal font-kanit"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="mr-auto text-base font-medium">
              <b v-if="isEdit">แก้ไขบทเรียน</b>
              <b v-else>เพิ่มบทเรียน</b>
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="grid grid-cols-12 gap-4 modal-body gap-y-3">
            <div class="col-span-12 sm:col-span-12">
              <label for="modal-form-1" class="form-label"
                ><b>ชื่อบทเรียน <span class="text-red-500">*</span></b></label
              >
              <input
                id="modal-form-5"
                v-model="lessonName"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="text-left modal-footer">
            <button
              type="button"
              class="w-20 mr-2 btn btn-primary"
              @click="handleName()"
            >
              ตกลง
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="w-20 btn btn-outline-secondary "
              @click="onModalClose()"
            >
              ยกเลิก
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
        <!-- BEGIN: Notification Content -->
        <div
          id="success-notification-content"
          class="flex hidden toastify-content"
        >
          <CheckCircleIcon class="text-theme-9" />
          <div class="ml-4 mr-4">
            <div class="font-medium">สำเร็จ !!</div>
            <div class="mt-1 text-gray-600">
              {{ successTxt }}
            </div>
          </div>
        </div>
        <div
          id="error-notification-content"
          class="flex hidden toastify-content"
        >
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">ผิดพลาด !!</div>
            <div class="mt-1 text-gray-600">
              {{ errorTxt }}
            </div>
          </div>
        </div>
        <!-- END: Notification Content -->
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, nextTick } from "vue";
import Toastify from "toastify-js";
import dayjs from "dayjs";
import * as getheringService from "/src/services/gethering";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();

    const activityName = ref("");
    const lessonList = ref([]);
    const searchContent = ref("");
    const lessonName = ref("");
    const pageSize = ref([10, 20, 30, 40]);
    const pageSizeSelect = ref(10);
    const totalPages = ref(0);
    const pageSelect = ref(1);
    const errorTxt = ref("");
    const successTxt = ref("");
    const deleteEvent = ref(null);
    const pages = ref([1]);
    const isEdit = ref(false);
    const lesson = ref();

    onMounted(() => {
      fetchLessons();
    });

    const fetchLessons = async () => {
      const res = await getheringService.getActivityLessons(route.params.id);
      activityName.value = res.data.data.activity_name;
      lessonList.value = res.data.data.lessons;
    };

    const onSearch = () => {
      pageSelect.value = 1;
      fetchLessons();
    };
    const paginationSelect = page => {
      pageSelect.value = page;
      fetchLessons();
    };
    const getDateFormat = date => {
      return date ? dayjs(date).format("DD/MM/YYYY, HH:mm") : "";
    };

    const successNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const errorNotificationToggle = () => {
      nextTick(() => {
        Toastify({
          node: cash("#error-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const handleName = async () => {
      if (!lessonName.value) {
        errorTxt.value = "กรุณากรอกชื่อบทเรียน";
        errorNotificationToggle();
        return;
      }

      const data = { name: lessonName.value };
      try {
        if (isEdit.value) {
          await getheringService.updateActivityLesson(lesson.value.id, data);
        } else {
          await getheringService.createActivityLesson(route.params.id, data);
        }
        fetchLessons();
        onModalClose();
      } catch {
        errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
        errorNotificationToggle();
      }
    };

    const onModalClose = () => {
      cash("#edit-content-modal").modal("hide");
      lessonName.value = "";
    };

    const onEditLesson = item => {
      isEdit.value = true;
      lesson.value = item;
      lessonName.value = item.name;
      cash("#edit-content-modal").modal("show");
    };

    const onConfirmDeleteLesson = item => {
      lesson.value = item;
    };

    const onDeleteLesson = async () => {
      try {
        await getheringService.deleteActivityLesson(lesson.value.id);
        successTxt.value = "ลบบทเรียนสำเร็จ";
        successNotificationToggle();
        fetchLessons();
      } catch {
        errorTxt.value = "ผิดพลาด, กรุณาตรวจสอบใหม่อีกครั้ง";
        errorNotificationToggle();
      } finally {
        cash("#delete-confirmation-modal").modal("hide");
      }
    };

    return {
      lessonList,
      searchContent,
      pageSize,
      pageSizeSelect,
      totalPages,
      pageSelect,
      errorTxt,
      successTxt,
      onSearch,
      paginationSelect,
      getDateFormat,
      pages,
      deleteEvent,
      activityName,
      lessonName,
      handleName,
      onModalClose,
      onEditLesson,
      isEdit,
      onDeleteLesson,
      onConfirmDeleteLesson
    };
  }
});
</script>
<style lang="scss" scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
