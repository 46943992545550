import { apiApp } from "/src/config/api";
const endpointPage = "admin/pages";
const endpointBanner = "admin/banners";
const endpointBanner2 = "admin/banner";
const endpointSponsor = "admin/sponsors";
const endpointSponsor2 = "admin/sponsor";

const getBannerList = params => {
  return apiApp.get(endpointBanner, { params });
};
const getPageList = () => {
  return apiApp.get(endpointPage);
};
const getSponsorList = params => {
  return apiApp.get(endpointSponsor, { params });
};
const createBanner = value => {
  return apiApp.post(endpointBanner2, value);
};
const editBanner = (form, id) => {
  return apiApp.put(endpointBanner2 + `/${id}`, form);
};
const detailBanner = id => {
  return apiApp.get(endpointBanner2 + `/${id}`);
};
const deleteBanner = id => {
  return apiApp.delete(endpointBanner2 + `/${id}`);
};
const deleteSponsor = id => {
  return apiApp.delete(endpointSponsor2 + `/${id}`);
};
const updateStatusIsPublished = id => {
  return apiApp.patch(endpointBanner2 + `/${id}/status`);
};
const orderBanner = order => {
  return apiApp.patch(endpointBanner2 + "/order-pin", { id: order });
};
const createSponsor = value => {
  return apiApp.post(endpointSponsor2, value);
};
const editSponsor = (form, id) => {
  return apiApp.put(endpointSponsor2 + `/${id}`, form);
};
const detailSponsor = id => {
  return apiApp.get(endpointSponsor2 + `/${id}`);
};
export {
  getBannerList,
  getSponsorList,
  createBanner,
  detailBanner,
  deleteBanner,
  deleteSponsor,
  updateStatusIsPublished,
  orderBanner,
  editBanner,
  createSponsor,
  getPageList,
  editSponsor,
  detailSponsor
};
