<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <router-link
        :to="{ name: 'side-menu-dashboard-overview-1' }"
        class="text-lg font-medium mr-auto flex items-center"
      >
        <ChevronLeftIcon />Dashboard (อาชีพ)
      </router-link>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
      <table class="table table-report -mt-2">
        <thead>
          <tr>
            <th class="text-center whitespace-nowrap">#</th>
            <th class="whitespace-nowrap">อาชีพ</th>
            <th class="text-center whitespace-nowrap">จำนวน</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="occupation in occupations.value"
            :key="occupation"
            class="intro-x"
          >
            <td class="w-10">
              <div class="text-center">
                {{ occupation.no }}
              </div>
            </td>
            <td>
              <div class="flex">
                {{ occupation.occupationName }}
              </div>
            </td>
            <td>
              <div class="text-center">
                <b>{{ occupation.numberOfUser }}</b>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted } from "vue";
import * as dashboardService from "/src/services/dashboard";

export default defineComponent({
  setup() {
    const occupations = reactive([]);

    const getOccupationList = () => {
      dashboardService
        .getDashboadOccupations()
        .then(res => {
          console.log(res);
          if (res.data.data) {
            occupations.value = res.data.data.map((x, index) => {
              x.no = index + 1;
              return x;
            });
          } else {
            occupations.value = [];
          }
        })
        .catch(err => {
          console.log(err);
        });
    };

    onMounted(() => {
      getOccupationList();
    });

    return {
      occupations
    };
  }
});
</script>
